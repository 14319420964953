import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PortalTheme from 'src/themes/portal'

export const ClosedLoanMessage = () => {
  const { t } = useTranslation()
  return (
    <Typography variant="body3" color={PortalTheme.color.grey9}>
      {t('Portal.Home.label.summary.loanClosedMessage')}
    </Typography>
  )
}

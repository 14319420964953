import { Alert, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PortalTheme from 'src/themes/portal'

export const OverdueMessage = () => {
  const { t } = useTranslation()
  return (
    <Alert
      sx={{
        border: `1px solid ${PortalTheme.color.error}`,
        borderRadius: '10px',
        color: PortalTheme.color.error,
        my: '16px',
      }}
      severity="error"
    >
      <Typography variant="body2" color={PortalTheme.color.error}>
        {t('Portal.Payments.label.overdueMessage')}
      </Typography>
    </Alert>
  )
}

import { useTranslation, Trans } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { genericErrorImg } from 'src/images'
import {
  bankErrorConnectBankAccountClicked,
  bankErrorContactFigClicked,
  bankErrorLiveChatClicked,
} from 'src/utils'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { ConnectionStepEnum } from 'src/portal/pages/flinks/types'

interface Props {
  setStep: (value: string) => void
}

export default function BankConnectError({ setStep }: Props) {
  const { t } = useTranslation()
  const screen = ConnectionStepEnum.BANK_CONNECT_ERROR as any

  const content = (
    <Trans i18nKey="BankConnectError.content">
      We weren’t able to connect to your bank account. Click the ‘Try again’ button, or{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={bankErrorLiveChatClicked}
        contactFigAnalytics={bankErrorContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        bankErrorConnectBankAccountClicked(t('common.retryLabel'), screen)
        setStep(ConnectionStepEnum.BANK_AUTO_CONNECT)
      }}
      buttonText={t('common.retryLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('BankConnectError.alt') }}
      title={t('BankConnectError.title')}
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}

import { Box, GlobalStyles, Grid, Typography, styled } from '@mui/material'
import { TFunction } from 'i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import DisclaimerCross from 'src/components/common/DisclaimerCross'
import { DivRoot } from 'src/components/common/DivRoot'
import RootLayout from 'src/components/common/RootLayout'
import { StyledDivider } from 'src/components/common/StyledDivider'
import { PrequalificationContext } from 'src/contexts'
import { useConfiguration } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { logoImgSvg } from 'src/images'
import theme from 'src/themes'
import {
  FeatureFlags,
  GetPartnerConfigResponseType,
  HeaderType,
  IconType,
  PartnersEnum,
  StepsEnum,
} from 'src/types'
import { isPartnerPromoActive } from 'src/utils/core'

const ConsentTypography = styled(Typography)({
  fontWeight: 400,
  color: theme.color.grey6,
  fontSize: '12px',
  lineHeight: '18px',
})

export const LandingTemplateDivider = () => (
  <Grid item xs={12}>
    <StyledDivider sx={{ margin: '10px 0', borderColor: theme.color.grey2 }} />
  </Grid>
)

/**
 * Landing page template that takes in a header and a body, pre-populating the wrapping styles, CTA, and consents.
 */
export function LandingTemplate({
  headerConfig,
  iconConfig,
  body,
}: {
  headerConfig?: HeaderType
  iconConfig?: IconType
  body: JSX.Element
}) {
  const { t } = useTranslation()
  const { percentFormat, currencyFormat } = useLocalizedFormatters()
  const { setStepE2E, partnerConfig } = useContext(PrequalificationContext)
  const { loanLimits } = useConfiguration()
  const { isFigLandingPromo } = useFlags<FeatureFlags>()
  const handleClick = () => {
    setStepE2E(StepsEnum.CONSENTS)
  }

  /**
   * Retrieves the disclaimer text based on the partner name.
   * Used for separating the disclaimer text for different partners.
   * @param partnerName - The name of the partner.
   * @param t - The translation function.
   * @param disclaimerValues - The values used in the disclaimer text.
   * @returns The disclaimer text for the specified partner.
   */
  const getDisclaimerText = (
    partnerConfig: GetPartnerConfigResponseType,
    t: TFunction<'translation', undefined>,
    disclaimerValues: { min_apr: string; max_apr: string; min_amt: string; max_amt: string },
  ) => {
    const partnerName = partnerConfig?.name

    const isPromoActive = isPartnerPromoActive(isFigLandingPromo, partnerName)

    if (partnerConfig?.is_referral) {
      return t('e2e.Landing.figFinancialReferral.disclaimer', disclaimerValues)
        .split('\n')
        .map((line: string) => line)
    }

    //feature flag is on for contest and is in the list of partners
    if (isPromoActive) {
      return (
        <>
          <Typography
            variant="body3"
            color={theme.color.grey9}
            sx={{
              padding: '24px 0px 8px 0px',
              textAlign: 'left',
              fontWeight: 'medium',
              display: 'block',
            }}
          >
            {t('e2e.Landing.cashContestLandingPromo.disclaimer_title')}
          </Typography>
          <Trans
            i18nKey="e2e.Landing.cashContestLandingPromo.disclaimer_part1"
            values={{
              max_amt: disclaimerValues.max_amt,
              min_amt: disclaimerValues.min_amt,
              min_apr: disclaimerValues.min_apr,
              max_apr: disclaimerValues.max_apr,
            }}
          ></Trans>
          <Trans i18nKey="e2e.Landing.cashContestLandingPromo.disclaimer_part2"></Trans>
          <Trans
            i18nKey="e2e.Landing.cashContestLandingPromo.disclaimer_part3"
            components={{
              disclaimerCross: <DisclaimerCross />,
              pdfLink: (
                <a
                  href={t('e2e.Landing.cashContestLandingPromo.cash_contest_1K_rules_pdf')}
                  target="_blank"
                  rel="noreferrer"
                >
                  See full contest rules and regulations.
                </a>
              ),
            }}
          ></Trans>
        </>
      )
    }
    if (partnerName === PartnersEnum.CREDIT_KARMA_PROMO) {
      return (
        <>
          <Trans
            i18nKey="e2e.Landing.creditKarmaPromo.disclaimer_gift_card"
            values={{
              max_amt: disclaimerValues.max_amt,
              min_amt: disclaimerValues.min_amt,
              min_apr: disclaimerValues.min_apr,
              max_apr: disclaimerValues.max_apr,
            }}
          ></Trans>
          <Trans
            i18nKey="e2e.Landing.creditKarmaPromo.disclaimer_part1"
            values={{
              max_amt: disclaimerValues.max_amt,
              min_amt: disclaimerValues.min_amt,
              min_apr: disclaimerValues.min_apr,
              max_apr: disclaimerValues.max_apr,
            }}
          ></Trans>
        </>
      )
    }
    switch (partnerName) {
      case PartnersEnum.LFLGROUP:
        return t('e2e.Landing.LFLGroup.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.PERKOPOLIS:
        return t('e2e.Landing.perkopolis.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.CREDIT_KARMA_PROMO:
        return t('e2e.Landing.creditKarmaPromo.disclaimer_part1', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.CREDIT_KARMA:
        return t('e2e.Landing.creditKarma.disclaimer', disclaimerValues)
      case PartnersEnum.THE_BRICK:
        return t('e2e.Landing.theBrick.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.NEO:
        return t('e2e.Landing.neo.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      case PartnersEnum.FAIRSTONE:
        return t('e2e.Landing.fairstone.disclaimer', disclaimerValues)
          .split('\n')
          .map((line: string) => line)
      default:
        return t('e2e.Landing.disclaimer', disclaimerValues)
    }
  }

  const DisclaimerSection = ({
    partnerConfig,
  }: {
    partnerConfig: GetPartnerConfigResponseType
  }) => {
    const { t } = useTranslation()

    const disclaimerValues = {
      min_apr: percentFormat(loanLimits?.minApr, 2),
      max_apr: percentFormat(loanLimits?.maxApr, 2),
      min_amt: currencyFormat(loanLimits?.minLoanAmount, 0),
      max_amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
    }

    const disclaimerText = getDisclaimerText(partnerConfig, t, disclaimerValues)

    return (
      <Grid item xs={12}>
        <ConsentTypography>
          {Array.isArray(disclaimerText)
            ? disclaimerText.map(line => (
                <>
                  {line}
                  <br />
                </>
              ))
            : disclaimerText}
        </ConsentTypography>
      </Grid>
    )
  }
  const partnerName = partnerConfig?.name as string
  const isPromoActive = isPartnerPromoActive(isFigLandingPromo, partnerName)

  return (
    <>
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '75px !important' },
          },
        }}
      />
      <RootLayout
        logo={{ src: logoImgSvg, alt: 'Fig logo' }}
        header={headerConfig}
        iconConfig={iconConfig}
        showProgressBar={false}
        contentStyles={{
          [theme.breakpoints.up('md')]: { marginTop: '48px' },
          [theme.breakpoints.down('md')]: { marginBottom: '96px' },
        }}
      >
        <DivRoot
          sx={{
            [theme.breakpoints.up('md')]: { paddingTop: '48px', paddingBottom: '48px' },
            [theme.breakpoints.down('md')]: { padding: '16px 16px 48px 16px' },
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Grid
              container
              style={{
                textAlign: 'center',
              }}
              direction="column"
              spacing={'16px'}
            >
              <Grid
                item
                sx={{
                  [theme.breakpoints.up('md')]: { display: 'none' },
                }}
              >
                <LandingTemplateDivider />
              </Grid>
              {body}
              <Grid
                item
                xs={12}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    position: 'fixed',
                    bottom: '0',
                    background: theme.color.white,
                    padding: '16px',
                    width: '100%',
                  },
                }}
              >
                {isPromoActive ? (
                  <PrimaryCTA
                    buttonText={t('LandingPromoBanner.applyNowLabelCashContest')}
                    onClick={handleClick}
                  />
                ) : (
                  <PrimaryCTA buttonText={t('e2e.Landing.buttonLabel')} onClick={handleClick} />
                )}
              </Grid>
              <DisclaimerSection partnerConfig={partnerConfig!} />
            </Grid>
          </Box>
        </DivRoot>
      </RootLayout>
    </>
  )
}

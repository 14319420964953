import { Dialog, Drawer, useMediaQuery } from '@mui/material'
import { useState } from 'react'

import { ActionModalContainer } from 'src/portal/components/modals/ActionModal/ActionModalContainer'
import { ActionModalProps } from 'src/portal/components/types'
import useModalContext from 'src/portal/hooks/useModalContext'
import { slideLeft, slideRight, zIndexAboveFreshChat } from 'src/portal/styles'
import PortalTheme from 'src/themes/portal'

/**
 * ActionModal component.
 *
 * Renders a modal that adapts to the viewport size:
 * - **Mobile**: Displays as a fullscreen `Dialog` with slide-up and slide-down animations.
 * - **Desktop**: Displays as a right-anchored `Drawer` with slide-left and slide-right animations.
 *
 * Props:
 * @param {string} icon - The URL of the icon displayed at the top of the modal.
 * @param {string} iconAlt - Alternate text for the icon (for accessibility).
 * @param {string} description - Description text displayed below the icon.
 * @param {React.ReactNode} content - The main content of the modal.
 * @param {() => void} backButtonHandler - Callback for the back button (optional).
 * @param {() => void} closeButtonHandler - Callback for the close button.
 * @param {() => void} nextButtonHandler - Callback for the next button (optional).
 *
 * Behavior:
 * - Opens with animations based on the viewport (`slideUp` or `slideLeft`).
 * - Closes with animations based on the viewport (`slideDown` or `slideRight`).
 * - Prevents immediate unmounting for smooth closing animations.
 *
 * Example Usage:
 * ```tsx
 * <ActionModal
 *   icon="/path/to/icon.png"
 *   iconAlt="Modal Icon"
 *   description="Example description text."
 *   content={<div>Your content goes here.</div>}
 *   backButtonHandler={() => console.log('Back button clicked')}
 *   closeButtonHandler={() => console.log('Close button clicked')}
 *   nextButtonHandler={() => console.log('Next button clicked')}
 * />
 * ```
 *
 * @returns {JSX.Element} The rendered modal component.
 */
export const ActionModal = ({
  icon,
  iconAlt,
  title,
  description,
  content,
  buttonText,
  backButtonHandler,
  closeButtonHandler,
  nextButtonHandler,
  nextButtonEnabled,
}: ActionModalProps) => {
  const isMobile = useMediaQuery(PortalTheme.breakpoints.down('sm'))
  const [open, setOpen] = useState(true)
  const [closing, setClosing] = useState(false)
  const { closeAllModals } = useModalContext()

  const handleClose = () => {
    setClosing(true) // Trigger the closing animation
    setTimeout(() => {
      setOpen(false)
      closeButtonHandler?.()
      closeAllModals()
    }, 300) // Match animation duration (300ms)
  }

  // Do not render the component if it is fully closed
  if (!open) {
    return null
  }

  // Mobile View: Fullscreen Dialog
  if (isMobile) {
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: zIndexAboveFreshChat,
          '& .MuiPaper-root': {
            animation: `${closing ? slideRight : slideLeft} 0.3s ease-out`,
          },
        }}
      >
        <ActionModalContainer
          {...{
            icon,
            iconAlt,
            title,
            description,
            content,
            buttonText,
            backButtonHandler,
            closeButtonHandler: handleClose,
            nextButtonHandler,
            nextButtonEnabled,
          }}
        />
      </Dialog>
    )
  }

  // Desktop View: Right-anchored Drawer
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      ModalProps={{ keepMounted: true }} // Prevent unmount during animation
      sx={{
        zIndex: zIndexAboveFreshChat,
        '& .MuiDrawer-paper': {
          width: '35vw',
          maxWidth: '100%',
          animation: `${closing ? slideRight : slideLeft} 0.3s ease-out`,
        },
      }}
    >
      <ActionModalContainer
        {...{
          icon,
          iconAlt,
          title,
          description,
          content,
          buttonText,
          backButtonHandler,
          closeButtonHandler: handleClose,
          nextButtonHandler,
          nextButtonEnabled,
        }}
      />
    </Drawer>
  )
}

/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * NinjaAPI
 * OpenAPI spec version: 1.0.0
 */
export type PrequalificationAffliateResponseData =
  | PrequalificationSuccess
  | PrequalificationAffliateDecline

export interface PrequalificationAffliateResponse {
  data: PrequalificationAffliateResponseData
}

export interface PrequalificationAffliateDeclineMessage {
  affiliate_msg_en: string
  affiliate_msg_fr: string
}

export type PrequalificationAffliateDeclineSecondLookUrl = string | null

export interface PrequalificationAffliateDecline {
  decline_code: string
  decline_message: PrequalificationAffliateDeclineMessage
  is_homeowner: boolean
  is_second_look_approved: boolean
  second_look_url?: PrequalificationAffliateDeclineSecondLookUrl
  status: string
}

export type PrequalificationRequestQueryParams = string | null

export type PrequalificationRequestPublisherId = string | null

export type PrequalificationRequestPartnerSessionId = string | null

export type PrequalificationRequestLocale = string | null

export type PrequalificationRequestIsEmailConsent = boolean | null

export type PrequalificationRequestCampaignId = string | null

export interface PrequalificationRequest {
  borrower: Borrower
  campaign_id?: PrequalificationRequestCampaignId
  is_email_consent?: PrequalificationRequestIsEmailConsent
  is_test_form_used?: boolean
  locale?: PrequalificationRequestLocale
  partner_session_id?: PrequalificationRequestPartnerSessionId
  publisher_id?: PrequalificationRequestPublisherId
  query_params?: PrequalificationRequestQueryParams
}

export type PersonalInfoSocialInsuranceNumber = string | null

export type PersonalInfoMobileNumber = string | null

export type PersonalInfoLegalMiddleName = string | null

export interface PersonalInfo {
  date_of_birth: string
  email_address: string
  legal_first_name: string
  legal_last_name: string
  legal_middle_name?: PersonalInfoLegalMiddleName
  mobile_number?: PersonalInfoMobileNumber
  social_insurance_number?: PersonalInfoSocialInsuranceNumber
}

export type HomeAddressAddress2 = string | null

export interface HomeAddress {
  address_1: string
  address_2?: HomeAddressAddress2
  city: string
  country_code: string
  postal_code: string
  province_code: string
}

export type BorrowerYearsAtAddress = number | string

export type BorrowerUserAgent = string | null

export type BorrowerPartnerCustomerId = string | null

export type BorrowerIpAddress = string | null

export interface Borrower {
  annual_gross_income: number
  home_address: HomeAddress
  ip_address?: BorrowerIpAddress
  is_homeowner: boolean
  monthly_home_payment: number
  partner_customer_id?: BorrowerPartnerCustomerId
  personal_info: PersonalInfo
  user_agent?: BorrowerUserAgent
  years_at_address: BorrowerYearsAtAddress
}

/**
 * Contains the title and detail of the error
 */
export interface ErrorItemType {
  detail: string
  title: string
}

/**
 * This encodes the error generated by the API response

Attributes::
---
status: string
errors: list
 */
export interface ErrorApiType {
  errors: ErrorItemType[]
  status: string
}

export type PrequalificationSuccessPartnerCustomerId = string | null

export type PrequalificationSuccessMinLoanAmount = number | string

export type PrequalificationSuccessMaxLoanAmount = number | string

export type PrequalificationOfferTotalCostOfBorrowing = number | string

export type PrequalificationOfferPaymentAmount = number | string

export type PrequalificationOfferLoanAmount = number | string

export type PrequalificationOfferFees = number | string

export type PrequalificationOfferApr = number | string

export interface PrequalificationOffer {
  apr: PrequalificationOfferApr
  fees: PrequalificationOfferFees
  loan_amount: PrequalificationOfferLoanAmount
  payment_amount: PrequalificationOfferPaymentAmount
  payment_frequency: string
  term_duration_months: number
  total_cost_of_borrowing: PrequalificationOfferTotalCostOfBorrowing
}

export interface PrequalificationSuccess {
  application_id: string
  application_url: string
  borrower_id: string
  expires_at: string
  max_loan_amount: PrequalificationSuccessMaxLoanAmount
  min_loan_amount: PrequalificationSuccessMinLoanAmount
  offers: PrequalificationOffer[]
  partner_customer_id?: PrequalificationSuccessPartnerCustomerId
  status: string
}

export interface PrequalificationEmbeddedDeclineMessage {
  en_ca: string
  fr_ca: string
}

export interface PrequalificationEmbeddedDecline {
  decline_reason: PrequalificationEmbeddedDeclineMessage
  status: string
}

export type PrequalificationEmbeddedResponseData =
  | PrequalificationSuccess
  | PrequalificationEmbeddedDecline

export interface PrequalificationEmbeddedResponse {
  data: PrequalificationEmbeddedResponseData
}

export interface PaymentDeferralGetDeferralFormResponseSchema {
  form_url: string
}

export interface PaymentDeferralCompleteRequestSchema {
  selected_payment_date: string
}

export interface PaymentDeferralCompleteResponseSchema {
  status: string
}

export interface PaymentDeferralProcessSelectedDateRequestSchema {
  repayment_start_date: string
}

export type PaymentDeferralProcessSelectedDateResponseSchemaFinalPaymentDate = string | null

export interface PaymentDeferralProcessSelectedDateResponseSchema {
  final_payment_date?: PaymentDeferralProcessSelectedDateResponseSchemaFinalPaymentDate
  is_in_cycle_or_outcycle: string
  loan_payment_mode: string
  next_payment_amount: number
  next_working_payment_date: string
  payment_schedule_amount_one: number
  payment_schedule_amount_three: number
  payment_schedule_amount_two: number
  payment_schedule_date_one: string
  payment_schedule_date_three: string
  payment_schedule_date_two: string
}

export type PaymentDeferralValidationResponseSchemaPaymentSchedule = string | null

export type PaymentDeferralValidationResponseSchemaCurrentPaymentDate = string | null

export interface PaymentDeferralValidationResponseSchema {
  current_payment_date?: PaymentDeferralValidationResponseSchemaCurrentPaymentDate
  end_date: string
  holiday_list: string[]
  incycle_upper_date: string
  monthly_payment_amount: number
  payment_schedule?: PaymentDeferralValidationResponseSchemaPaymentSchedule
  repayment_frequency: string
  start_date: string
}

export interface PaymentScheduleChangeCompleteRequestSchema {
  selected_frequency: string
  selected_payment_date: string
}

export interface SuccessSchema {
  success?: boolean
}

export interface PaymentScheduleChangeProcessSelectedDateRequestSchema {
  is_next?: boolean
  monthly_choice?: string
  payment_frequency: string
  repayment_start_date: string
}

export type PaymentScheduleChangeProcessSelectedDateResponseSchemaUpcomingPaymentCycleRemainings =
  | PaymentScheduleCurrentPaymentCycleRemainingsSchema[]
  | null

export type PaymentScheduleChangeProcessSelectedDateResponseSchemaRegularPaymentAmount =
  | number
  | string

export type PaymentScheduleChangeProcessSelectedDateResponseSchemaPaymentScheduleStatement =
  | string
  | null

export type PaymentScheduleChangeProcessSelectedDateResponseSchemaIsDateOnSameSchedule =
  | boolean
  | null

export type PaymentScheduleChangeProcessSelectedDateResponseSchemaCurrentPaymentCycleRemainings =
  | PaymentScheduleCurrentPaymentCycleRemainingsSchema[]
  | null

export interface PaymentScheduleChangeProcessSelectedDateResponseSchema {
  current_payment_cycle_remainings?: PaymentScheduleChangeProcessSelectedDateResponseSchemaCurrentPaymentCycleRemainings
  is_date_on_same_schedule?: PaymentScheduleChangeProcessSelectedDateResponseSchemaIsDateOnSameSchedule
  next_day_of_week: string
  next_working_payment_date: string
  payment_frequency: string
  payment_schedule: string
  payment_schedule_statement?: PaymentScheduleChangeProcessSelectedDateResponseSchemaPaymentScheduleStatement
  regular_payment_amount: PaymentScheduleChangeProcessSelectedDateResponseSchemaRegularPaymentAmount
  upcoming_payment_cycle_remainings?: PaymentScheduleChangeProcessSelectedDateResponseSchemaUpcomingPaymentCycleRemainings
}

export type PaymentScheduleValidationRequestSchemaSelectedFrequency = string | null

export type PaymentScheduleValidationRequestSchemaFrequency = string | null

export interface PaymentScheduleValidationRequestSchema {
  frequency?: PaymentScheduleValidationRequestSchemaFrequency
  selected_frequency?: PaymentScheduleValidationRequestSchemaSelectedFrequency
}

export type PaymentScheduleCurrentPaymentCycleRemainingsSchemaAmount = string | number

export interface PaymentScheduleCurrentPaymentCycleRemainingsSchema {
  amount: PaymentScheduleCurrentPaymentCycleRemainingsSchemaAmount
  date: string
}

export type PaymentScheduleChangeValidationResponseSchemaTwiceAMonth = number | string | null

export type PaymentScheduleChangeValidationResponseSchemaStartDate = string | null

export type PaymentScheduleChangeValidationResponseSchemaSelectedFrequency = string | null

export type PaymentScheduleChangeValidationResponseSchemaOnceAMonth = number | string | null

export type PaymentScheduleChangeValidationResponseSchemaEveryWeeks = number | string | null

export type PaymentScheduleChangeValidationResponseSchemaEveryTwoWeeks = number | string | null

export type PaymentScheduleChangeValidationResponseSchemaEndDate = string | null

export type PaymentScheduleChangeValidationResponseSchemaCurrentPaymentDate = string | null

export type PaymentScheduleChangeValidationResponseSchemaCurrentPaymentCycleRemainings =
  | PaymentScheduleCurrentPaymentCycleRemainingsSchema[]
  | null

export type PaymentScheduleChangeValidationResponseSchemaCurrentBillDate = string | null

/**
 * "current_payment_cycle_remainings": [
    {
        "date": "2024-11-22",
        "amount": 102.83
    }
],
"current_bill_date": "2024-11-25",
"end_date": "2024-12-25",
"start_date": "2024-11-26",
"selected_frequency": "ONCE_A_MONTH",
"every_two_weeks": 102.83,
"every_week": 51.415,
"twice_a_month": 102.83,
"once_a_month": 205.66,
"current_payment_date": "2024-11-26"
 */
export interface PaymentScheduleChangeValidationResponseSchema {
  current_bill_date?: PaymentScheduleChangeValidationResponseSchemaCurrentBillDate
  current_payment_cycle_remainings?: PaymentScheduleChangeValidationResponseSchemaCurrentPaymentCycleRemainings
  current_payment_date?: PaymentScheduleChangeValidationResponseSchemaCurrentPaymentDate
  end_date?: PaymentScheduleChangeValidationResponseSchemaEndDate
  every_two_weeks?: PaymentScheduleChangeValidationResponseSchemaEveryTwoWeeks
  every_weeks?: PaymentScheduleChangeValidationResponseSchemaEveryWeeks
  once_a_month?: PaymentScheduleChangeValidationResponseSchemaOnceAMonth
  selected_frequency?: PaymentScheduleChangeValidationResponseSchemaSelectedFrequency
  start_date?: PaymentScheduleChangeValidationResponseSchemaStartDate
  twice_a_month?: PaymentScheduleChangeValidationResponseSchemaTwiceAMonth
}

/**
 * The flinks payment check request.

Attributes:
---
login_id: The flinks login_id
 */
export interface FlinksPaymentCheckRequestSchema {
  application_id: string
  login_id: string
}

export interface FlinksPaymentCheckResponseSchema {
  is_bank_account_valid: boolean
  is_complete: boolean
  is_declined: boolean
  is_failed: boolean
  is_pii_match: boolean
}

export interface FlinksTrackEventRequestSchema {
  application_id: string
  borrower_id: string
  event_name: string
}

export interface FlinksSetAccountRequestSchema {
  account_id: string
  application_id: string
  borrower_id: string
  login_id: string
}

export interface DefaultSuccessResponseSchema {
  success?: boolean
}

export interface PaymentBreakdownRequestSchema {
  payment_amount: number
}

export interface PaymentBreakdownResponseSchema {
  interest_amount: number
  nsf_fees: number
  payment_protection_amount: number
  principal_amount: number
  total_amount: number
}

export type PaymentDetailResponseSchemaPaymentMethodDetails = string | null

export interface PaymentDetailResponseSchema {
  borrower_id: string
  id: string
  name: string
  next_payment: PaymentDetailResponseSchemaNextPayment
  outstanding_amount: number
  pay_off_amount: number
  payment_method_details: PaymentDetailResponseSchemaPaymentMethodDetails
}

export type NextPaymentSchemaType = string | null

export type NextPaymentSchemaDateFrench = string | null

export type NextPaymentSchemaDate = string | null

export type NextPaymentSchemaAmount = number | null

export interface NextPaymentSchema {
  amount: NextPaymentSchemaAmount
  date: NextPaymentSchemaDate
  date_french: NextPaymentSchemaDateFrench
  type: NextPaymentSchemaType
}

export type PaymentDetailResponseSchemaNextPayment = NextPaymentSchema | null

export interface DocumentDownloadResponseSchema {
  content: string
}

export type DocumentTypeEnum = (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum]

export const DocumentTypeEnum = {
  LOAN_AGREEMENT: 'LOAN_AGREEMENT',
  PAD_AGREEMENT: 'PAD_AGREEMENT',
  UNKNOWN: 'UNKNOWN',
} as const

export type DocumentHistoryResponseSchemaDocumentType = DocumentTypeEnum | null

export interface DocumentHistoryResponseSchema {
  content_document_id: string
  date: string
  document_type?: DocumentHistoryResponseSchemaDocumentType
  id: string
  title: string
}

export interface MakePaymentRequestSchema {
  amount: number
  payment_type: string
}

export interface MakePaymentResponseSchema {
  borrower_id: string
  loan_id: string
  status: string
}

export type DisbursalHistoryResponseSchemaDisbursalSuccessTimestamp = string | null

export interface DisbursalHistoryResponseSchema {
  borrower_id: string
  disbursal_amount: number
  disbursal_date: string
  disbursal_status: boolean
  disbursal_success_timestamp?: DisbursalHistoryResponseSchemaDisbursalSuccessTimestamp
  id: string
  name: string
}

export type TransactionHistoryResponseSchemaTransactionTime = string | null

export type TransactionHistoryResponseSchemaChequeNumber = string | null

export interface TransactionHistoryResponseSchema {
  borrower_id: string
  cheque_number?: TransactionHistoryResponseSchemaChequeNumber
  fee_payment: TransactionHistoryFeePaymentSchema[]
  fees: number
  id: string
  interest: number
  is_manual_payment: boolean
  name: string
  payment_mode: string
  payment_type: string
  principal: number
  repayment_transaction_adjustment: TransactionHistoryRepaymentTransactionAdjustmentSchema[]
  transaction_amount: number
  transaction_creation_date: string
  transaction_reversed: boolean
  transaction_status: string
  transaction_time?: TransactionHistoryResponseSchemaTransactionTime
}

export type TransactionHistoryRepaymentTransactionAdjustmentSchemaTransactionTime = string | null

export interface TransactionHistoryRepaymentTransactionAdjustmentSchema {
  adjusted_amount: number
  id: string
  interest_rate: number
  loan_payment_transaction: string
  name: string
  payment_type: string
  transaction_amount: number
  transaction_time?: TransactionHistoryRepaymentTransactionAdjustmentSchemaTransactionTime
}

export interface TransactionHistoryFeePaymentChargeSchema {
  id: string
  name: string
  original_amount: number
  paid_amount: number
  waive: boolean
}

export type TransactionHistoryFeePaymentSchemaCharge =
  TransactionHistoryFeePaymentChargeSchema | null

export interface TransactionHistoryFeePaymentSchema {
  charge: TransactionHistoryFeePaymentSchemaCharge
  id: string
  transaction_amount: number
}

export type RepaymentScheduleResponseSchemaPaymentProtectionStatus = string | null

export type RepaymentScheduleResponseSchemaPaymentProtectionPremiumTaxAmount = number | null

export type RepaymentScheduleResponseSchemaPaymentProtectionPremiumAmount = number | null

export type RepaymentScheduleResponseSchemaPaymentProtectionApplied = boolean | null

export type RepaymentScheduleResponseSchemaNsfFeesAmount = number | null

export interface RepaymentScheduleResponseSchema {
  balance_amount: number
  due_date: string
  fees_due: number
  id: string
  interest_due: number
  nsf_fees_amount?: RepaymentScheduleResponseSchemaNsfFeesAmount
  payment_amount: number
  payment_protection_applied: RepaymentScheduleResponseSchemaPaymentProtectionApplied
  payment_protection_premium_amount: RepaymentScheduleResponseSchemaPaymentProtectionPremiumAmount
  payment_protection_premium_tax_amount: RepaymentScheduleResponseSchemaPaymentProtectionPremiumTaxAmount
  payment_protection_status: RepaymentScheduleResponseSchemaPaymentProtectionStatus
  principal_due: number
}

export type LoanQueryResponseSchemaLoanClosedDate = string | null

export type LoanQueryResponseSchemaCreditRenewalUrl = string | null

export interface LoanQueryResponseSchema {
  application_id: string
  automated_payment_setup?: LoanQueryResponseSchemaAutomatedPaymentSetup
  borrower_id: string
  credit_renewal_url?: LoanQueryResponseSchemaCreditRenewalUrl
  due_amount: number
  due_date: string
  fees_accrued_since_last_payment: number
  id: string
  interest_accrued_since_last_payment: number
  interest_rate: number
  loan_amount: number
  loan_closed_date?: LoanQueryResponseSchemaLoanClosedDate
  loan_disbursal_date: string
  loan_pay_off_amount: number
  loan_payment_frequency: string
  loan_status: string
  loan_term: number
  manual_pay: boolean
  name: string
  next_payment_due_date: string
  partner_id: string
  partner_name: string
  payment_method: string
  payment_method_french: string
  payment_protection_applied?: boolean
  payment_schedule: string
  payment_schedule_french: string
}

export type LoanQueryAutomatedPaymentSchemaManualDebitDate = string | null

export type LoanQueryAutomatedPaymentSchemaLoanFrequency = string | null

export interface LoanQueryAutomatedPaymentSchema {
  cl_contract: string
  debit_date: string
  debit_date_french: string
  due_amount: number
  id: string
  is_manual_payment: boolean
  loan_active: boolean
  loan_debit_date: string
  loan_frequency?: LoanQueryAutomatedPaymentSchemaLoanFrequency
  loan_type: string
  manual_debit_date?: LoanQueryAutomatedPaymentSchemaManualDebitDate
  next_payment_due: string
  record_type_id: string
}

export type LoanQueryResponseSchemaAutomatedPaymentSetup = LoanQueryAutomatedPaymentSchema | null

export interface BorrowerDetailSchema {
  billing_city: string
  billing_country: string
  billing_postal_code: string
  billing_state: string
  billing_street: string
  borrower_id: string
  complete_address: string
  email: string
  first_name: string
  language_preference: string
  last_name: string
  phone: string
}

import { Grid2 } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SecondaryCTA } from 'src/components/common/Buttons'
import { chevronDownicon, chevronUpicon } from 'src/images'
import { useLocale } from 'src/portal/hooks/useLocale'
import PortalTheme from 'src/themes/portal'

interface ShowDetailsButtonProps {
  onClickHandler: () => void
}
/**
 * ShowDetailsButton component renders a button that displays "Show details" with a chevron icon.
 * The button is styled to be displayed at the bottom of a container.
 *
 * @param {function} onClickHandler - The function to be called when the button is clicked.
 * @returns {JSX.Element} The rendered ShowDetailsButton component.
 */
export const ShowDetailsButton = ({ onClickHandler }: ShowDetailsButtonProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { isFrenchLocale } = useLocale()

  const buttonText = isOpen
    ? t('Portal.Components.label.hideDetails')
    : t('Portal.Components.label.showDetails')

  const imgSrc = isOpen ? chevronUpicon : chevronDownicon

  return (
    <Grid2
      sx={{
        transform: 'translateY(122%)',
        maxWidth: isFrenchLocale ? '248px' : '178px',
        margin: '0 auto',
      }}
      onClick={() => {
        setIsOpen(!isOpen)
        onClickHandler()
      }}
    >
      <SecondaryCTA
        buttonText={buttonText}
        sx={{
          display: 'inline-block',
          px: '24px',
          py: '8px',
          background: PortalTheme.color.white,
          maxHeight: '36px',
        }}
        endIcon={
          <img
            src={imgSrc}
            alt={t('Portal.Components.alt.chevronIcon')}
            style={{ verticalAlign: 'middle' }}
          />
        }
      />
    </Grid2>
  )
}

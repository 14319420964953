import { Grid2, Typography } from '@mui/material'
import { t } from 'i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { LabelText } from 'src/portal/components/LabelText'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { UpcomingPaymentAmountContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import PortalTheme from 'src/themes/portal'

export const UpcomingPaymentAmountContainer = ({
  upcomingPaymentAmount,
}: {
  upcomingPaymentAmount: number
}) => {
  const { currencyFormat } = useLocalizedFormatters()

  return (
    <SpacedRow>
      <Grid2>
        <LabelText text={t('Portal.Home.label.summary.upcomingPaymentAmount')} />
        <TooltipContainer
          title={t('Portal.Components.tooltip.title.upcomingPaymentAmount')}
          content={<UpcomingPaymentAmountContent />}
        />
      </Grid2>
      <Grid2>
        <Typography variant="body2" color={PortalTheme.color.grey9} py="6px">
          {currencyFormat(upcomingPaymentAmount)}
        </Typography>
      </Grid2>
    </SpacedRow>
  )
}

import { Grid2 } from '@mui/material'
import { useState } from 'react'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { TabNavigation } from 'src/portal/components/TabNavigation'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { Greeting } from 'src/portal/pages/home/components/Greeting'
import Summary from 'src/portal/pages/home/summary/Summary'
import { Transactions } from 'src/portal/pages/home/transactions/Transactions'
import { UpcomingPayments } from 'src/portal/pages/home/upcomingPayments/UpcomingPayments'
import { sectionGap } from 'src/portal/styles'

const Home = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { loanDetailData, borrowerDetailData } = usePortalContext()

  const homeTabList = [
    { label: 'Portal.Home.label.tab.summary' },
    { label: 'Portal.Home.label.tab.transactions' },
    { label: 'Portal.Home.label.tab.upcomingPayments' },
  ]

  const renderTabContent = () => {
    if (!loanDetailData?.id) {
      return <LoadingAnimation />
    }

    switch (selectedTab) {
      case 0:
        return <Summary />
      case 1:
        return <Transactions />
      case 2:
        return <UpcomingPayments />
      default:
        return null
    }
  }

  return (
    <Grid2 flexDirection={'column'}>
      <Grid2 sx={sectionGap}>
        <Greeting name={borrowerDetailData?.first_name || ''} />
      </Grid2>
      <Grid2 sx={sectionGap}>
        <TabNavigation
          tabList={homeTabList}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
      </Grid2>
      <Grid2 sx={sectionGap}>{renderTabContent()}</Grid2>
    </Grid2>
  )
}

export default Home

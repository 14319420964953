import i18n, { FrenchLanguageKey } from 'src/locales'

/**
 * Custom hook to determine if the current locale is French.
 * Usage:
 * const { isFrenchLocale } = useLocale()
 * if(isFrenchLocale) { ... }
 * @returns An object containing a boolean indicating if the current locale is French.
 * @property {boolean} isFrenchLocale - True if the current locale is French, otherwise false.
 */
export const useLocale = () => {
  return {
    isFrenchLocale: i18n.resolvedLanguage === FrenchLanguageKey,
  }
}

import { styled } from '@mui/material/styles'

export const DivRoot = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '32px',
  paddingLeft: '16px',
  paddingRight: '16px',
  [theme.breakpoints.up('sm')]: {
    paddingTop: '32px',
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
}))

import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import PortalTheme from 'src/themes/portal'
const PaymentTypography = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" color={PortalTheme.color.grey7}>
    {children}
  </Typography>
)

interface PaymentBreakdownTableProps {
  principalAmount: number
  creditChargeAmount: number
  paymentProtectionAmount: number
  feesAmount: number
  totalAmount: number
}

export const PaymentBreakdownTable = ({
  principalAmount,
  creditChargeAmount,
  paymentProtectionAmount,
  feesAmount,
  totalAmount,
}: PaymentBreakdownTableProps) => {
  const { t } = useTranslation()
  const { currencyFormat } = useLocalizedFormatters()

  const lineItems = [
    {
      label: t('Portal.Components.modal.confirmPayment.label.principal'),
      amount: principalAmount,
    },
    {
      label: t('Portal.Components.modal.confirmPayment.label.creditCharges'),
      amount: creditChargeAmount,
    },
    {
      label: t('Portal.Components.modal.confirmPayment.label.paymentProtection'),
      amount: paymentProtectionAmount,
    },
    {
      label: t('Portal.Components.modal.confirmPayment.label.fees'),
      amount: feesAmount,
    },
  ]

  return (
    <Box p="16px">
      {lineItems.map(({ label, amount }) => (
        <SpacedRow key={label}>
          <PaymentTypography>{label}</PaymentTypography>
          <PaymentTypography>{currencyFormat(amount)}</PaymentTypography>
        </SpacedRow>
      ))}
      <StretchDivider />
      <SpacedRow>
        <PaymentTypography>
          {t('Portal.Components.modal.confirmPayment.label.totalAmount')}
        </PaymentTypography>
        <PaymentTypography>{currencyFormat(totalAmount)}</PaymentTypography>
      </SpacedRow>
    </Box>
  )
}

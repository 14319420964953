import { useEffect, useMemo } from 'react'
import { createBrowserHistory } from 'history'
import { useLocation, useNavigate, useParams, useSearchParams, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ApplicationStatus, FeatureFlags, LogoType, StepsEnum } from 'src/types'
import {
  EnvEnum,
  ENVIRONMENT,
  FRESHCHAT_HOST,
  FRESHCHAT_SCRIPT_URL,
  FRESHCHAT_TOKEN,
  FRESHCHAT_WIDGET_UUID,
} from 'src/utils/constants'
import { useOriginationContext } from 'src/hooks'
import useFreshChatWidget from 'src/hooks/useFreshChatWidget'
import RootLayout from 'src/components/common/RootLayout'
import { copy, decodeBase64, parseUrl } from 'src/utils'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'

interface LayoutInputType {
  bootstrapIsLoading: boolean
  bootstrapIsSuccess: boolean
  logo: LogoType
}

export default Layout
/**
 *
 */
function Layout({ bootstrapIsLoading, bootstrapIsSuccess, logo }: LayoutInputType) {
  const {
    borrowerId,
    setBorrowerId,
    applicationId,
    setApplicationId,
    setPartnerSessionId,
    cachedApplications,
    setCachedApplications,
    jwtApiKey,
    setJwtApiKey,
    isApplicationError,
    setApplicationError,
    setApplicationSettled,
    isApplicationSettled,
    isApplicationExpired,
    isApplicationDeclined,
    setStep,
    bootstrapInfo,
    setGenericErrorPageError,
  } = useOriginationContext()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const history = createBrowserHistory()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isMaintenance } = useFlags<FeatureFlags>()

  // This useEffect is used to listen to browser back and forward buttons
  // To prevent the user from navigating the app
  // after a loan has been approved
  useEffect(() => {
    const unlisten = history.listen(({ action }) => {
      if (
        bootstrapInfo?.application?.status === ApplicationStatus.SETTLED ||
        bootstrapInfo?.application?.status === ApplicationStatus.AUTHORIZED
      ) {
        if (action === 'POP' || action === 'PUSH') {
          setApplicationSettled(true)
        }
      }
    })
    return unlisten
  }, [bootstrapInfo?.application?.status])

  // Trigger a warning on refresh (refreshing on page is not currently supported).
  useEffect(() => {
    window.onbeforeunload = function (event) {
      event.returnValue = 'Your application will restart if you leave. Are you sure?'
    }
  }, [])

  const { currentStep } = useMemo(parseUrl, [location.pathname])

  useEffect(() => {
    // Restart flow if the user refreshed.
    if (!applicationId && currentStep !== StepsEnum.LOAN_AMOUNT) {
      navigate(`/${params.jwtApiKey}/${StepsEnum.LOAN_AMOUNT}`)
    }

    if (params.jwtApiKey && jwtApiKey !== params.jwtApiKey) {
      setJwtApiKey(params.jwtApiKey)
      const data = decodeBase64<{ application_id?: string; borrower_id?: string }>(params.jwtApiKey)
      if (data?.application_id) {
        setApplicationId(data.application_id)
        setBorrowerId(data.borrower_id ?? '')

        const updatedCachedApplications = copy(cachedApplications)
        updatedCachedApplications[data.application_id] =
          updatedCachedApplications[data.application_id] || {}
        setCachedApplications(updatedCachedApplications)
      } else {
        setApplicationError(true)
      }
    }

    const partnerSessionId = searchParams.get('partner_session_id')
    if (partnerSessionId) {
      setPartnerSessionId(partnerSessionId)
    }
  }, [
    params.jwtApiKey,
    jwtApiKey,
    bootstrapIsLoading,
    bootstrapIsSuccess,
    isApplicationError,
    isApplicationSettled,
    isApplicationExpired,
    isApplicationDeclined,
  ])

  useFreshChatWidget({
    scriptSrc: FRESHCHAT_SCRIPT_URL,
    shouldShow: !!applicationId && !bootstrapIsLoading,
    freshChatCustomParams: {
      cf_application_id: applicationId,
      cf_borrower_id: borrowerId,
      cf_environment: ENVIRONMENT === EnvEnum.local ? 'development' : ENVIRONMENT,
      cf_partner_name: bootstrapInfo?.partner?.name ?? '',
    },
    freshChatInitParams: {
      token: FRESHCHAT_TOKEN,
      externalId: borrowerId,
      host: FRESHCHAT_HOST,
      widgetUuid: FRESHCHAT_WIDGET_UUID,
    },
    freshChatContactParams: {
      email: bootstrapInfo?.borrower?.borrower_email ?? '',
      phone: bootstrapInfo?.borrower?.borrower_mobile ?? '',
      firstName: bootstrapInfo?.borrower?.borrower_first_name ?? '',
      lastName: bootstrapInfo?.borrower?.borrower_last_name ?? '',
    },
  })

  useEffect(() => {
    if (params.jwtApiKey && jwtApiKey) {
      if (bootstrapIsLoading) {
        return
      }
      if (isApplicationExpired) {
        setStep(StepsEnum.EXPIRED)
        return
      }
      if (isApplicationError || !bootstrapIsSuccess) {
        setGenericErrorPageError(
          new Error(
            `isApplicationError: ${isApplicationError} bootstrapIsSuccess: ${bootstrapIsSuccess}`,
          ),
        )
        setStep(StepsEnum.ERROR)
        return
      }
    }
  }, [
    location.pathname,
    params.jwtApiKey,
    jwtApiKey,
    bootstrapIsLoading,
    bootstrapIsSuccess,
    isApplicationError,
    isApplicationExpired,
    isApplicationDeclined,
  ])

  if (isMaintenance) {
    return (
      <RootLayout logo={logo}>
        <MaintenanceMode />
      </RootLayout>
    )
  }

  if (bootstrapIsLoading) {
    return (
      <RootLayout logo={logo}>
        <LoadingAnimation subtitle={t('init.loading')} />
      </RootLayout>
    )
  }

  return (
    <RootLayout logo={logo}>
      <Outlet />
    </RootLayout>
  )
}

import { useTranslation } from 'react-i18next'

import { connectionSuccessImg } from 'src/images'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'

export const DelayNextPaymentSuccessModal = () => {
  const { t } = useTranslation()
  const { closeAllModals } = useModalContext()
  return (
    <ActionModal
      title={t('Portal.Components.modal.paymentDeferralSuccess.title')}
      icon={connectionSuccessImg}
      content={<></>}
      buttonText={t('Portal.Components.modal.paymentDeferralSuccess.buttonText')}
      closeButtonHandler={closeAllModals}
      nextButtonHandler={closeAllModals}
    />
  )
}

import { Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { LoanDetailsTitle } from 'src/portal/pages/home/summary/content/Titles'
import {
  LoanIdContent,
  LoanTermContent,
} from 'src/portal/pages/home/summary/content/TooltipContent'
import PortalTheme from 'src/themes/portal'

interface LoanDetailsContainerProps {
  loanName: string
  loanAmount: number
  interestRate: number
  loanTerm: number
  loanStartDate: string
  loanClosedDate?: string | null
}
export const LoanDetailsContainer = ({
  loanName,
  loanAmount,
  interestRate,
  loanTerm,
  loanStartDate,
  loanClosedDate,
}: LoanDetailsContainerProps) => {
  const { t } = useTranslation()
  const { currencyFormat, percentFormat, dateFormatCustom } = useLocalizedFormatters()

  return (
    <>
      <SpacedRow>
        <LoanDetailsTitle />
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanId')}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.loanId')}
            content={<LoanIdContent />}
          />
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {loanName}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanAmount')}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {currencyFormat(loanAmount)}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.interestRate')}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {percentFormat(interestRate)}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanTerm')}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.loanTerm')}
            content={<LoanTermContent />}
          />
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanTermInMonths', { count: loanTerm })}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanStartDate')}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {dateFormatCustom(new Date(loanStartDate), t('Portal.common.dateFormat'))}
          </Typography>
        </Grid2>
      </SpacedRow>
      {loanClosedDate && (
        <SpacedRow>
          <Grid2>
            <Typography variant="body3" color={PortalTheme.color.grey9}>
              {t('Portal.Home.label.summary.loanClosedDate')}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant="body3" color={PortalTheme.color.grey9}>
              {dateFormatCustom(new Date(loanClosedDate), t('Portal.common.dateFormat'))}
            </Typography>
          </Grid2>
        </SpacedRow>
      )}
    </>
  )
}

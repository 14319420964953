import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Grid2, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { captureMessage } from '@sentry/react'
import { addDays, eachDayOfInterval, parseISO, isWeekend } from 'date-fns'
import { Dispatch, useEffect, useReducer, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

// App
import {
  PADHeaderContainer,
  PADHeadingText,
  PADScheduleContainer,
} from 'src/components/PaymentSchedule/PadSchedule'
import { EveryTwoWeeksScheduleOptions } from 'src/components/PaymentSchedule/scheduleOptions/EveryTwoWeeksScheduleOptions'
import { EveryWeekScheduleOptions } from 'src/components/PaymentSchedule/scheduleOptions/EveryWeekScheduleOptions'
import PaymentProtectionInfo from 'src/components/PaymentSchedule/shared/PaymentProtectionInfo'
import {
  CalendarLoader,
  Disclaimer,
} from 'src/components/PaymentSchedule/shared/PaymentScheduleCalendar'
import { RepaymentScheduleList } from 'src/components/PaymentSchedule/shared/RepaymentScheduleList'
import { RepaymentStartHeading } from 'src/components/PaymentSchedule/shared/RepaymentStartHeading'
import { FigDatePickerDay, getIsInvalidDateFromRange } from 'src/components/common/FigDatePicker'
import Notification, {
  InformationIconStyled,
  NotificationBold,
} from 'src/components/common/Notification'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import usePaymentScheduleText from 'src/hooks/usePaymentScheduleText'
import {
  PaymentScheduleChangeProcessSelectedDateResponseSchema,
  PaymentScheduleValidationRequestSchema,
} from 'src/portal/api/api.schemas'
import { PortalModalsEnum } from 'src/portal/common'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { FigAlertError } from 'src/portal/pages/reschedule/components/common'
import { DivRoot } from 'src/portal/pages/reschedule/styles'
import theme from 'src/themes'
import { MonthlyScheduleEnum, PaymentScheduleEnum } from 'src/types'
import { captureExceptionHelper } from 'src/utils'
import {
  MAX_DAYS_BEFORE_PAYMENT,
  MAX_DAYS_BEFORE_PAYMENT_QC,
  MIN_DAYS_BEFORE_PAYMENT,
} from 'src/utils/constants'

export interface RepaymentScheduleResponseType {
  withdraw_amount: string
  first_payment_date: string
  payment_cycle_due_date: string
  monthly_choice?: string
  schedule: string
  isLoading?: boolean
}

const PADScheduleHeader = styled(Grid2)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '10px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderBottom: 'none',
  borderRadius: '4px',
  borderBottomWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.color.brand5,
}))

function BiMonthlyScheduleOptions({
  earliestDate,
  selectedFirstPaymentDate,
  setSelectedFirstPaymentDate,
  setScheduleReady,
}: {
  earliestDate: Date
  selectedFirstPaymentDate: Date | null
  setSelectedFirstPaymentDate: (d: Date) => void
  setScheduleReady: () => void
}) {
  const validDates: Date[] = eachDayOfInterval({
    start: earliestDate,
    end: addDays(earliestDate, 13),
  }).filter(day => !isWeekend(day))

  const setCalendarDate = (date: Date) => {
    setSelectedFirstPaymentDate(date)
    setScheduleReady()
  }

  return (
    <Grid2 container marginTop="24px" id="startTwoWeekWrapper">
      <RepaymentStartHeading />

      <Grid2 size={12} id="datePickerTwoWeekWrapper">
        <FigDatePickerDay
          getIsInvalidDate={getIsInvalidDateFromRange(validDates)}
          calendarDate={selectedFirstPaymentDate}
          setCalendarDate={setCalendarDate}
          disableWeekends={true}
        />
      </Grid2>
    </Grid2>
  )
}

/**
 *
 */
function MonthlyScheduleOptions({
  earliestDate,
  latestDate,
  selectedFirstPaymentDate,
  setSelectedFirstPaymentDate,
  setScheduleReady,
}: {
  currentDate: Date
  earliestDate: Date
  latestDate: Date
  selectedMonthlyChoice?: MonthlyScheduleEnum
  setSelectedMonthlyChoice: (choice: MonthlyScheduleEnum) => void
  selectedFirstPaymentDate: Date | null
  setSelectedFirstPaymentDate: (d: Date) => void
  setScheduleReady: () => void
}) {
  const { t } = useTranslation()
  const validDates: Date[] = eachDayOfInterval({
    start: earliestDate,
    end: latestDate,
  })

  const setCalendarDate = (date: Date) => {
    setSelectedFirstPaymentDate(date)
    setScheduleReady()
  }

  return (
    <Grid2 container marginTop="24px" id="startMonthlyWrapper">
      <RepaymentStartHeading />

      <Grid2 size={12} marginBottom="4px">
        <Typography variant="body2" color={theme.color.black}>
          {t('PaymentSchedule.MonthlyScheduleOptions.firstPaymentDate')}
        </Typography>
      </Grid2>
      <Grid2 size={12} marginBottom="16px" id="datePickerMonthlyWrapper">
        <FigDatePickerDay
          getIsInvalidDate={getIsInvalidDateFromRange(validDates)}
          calendarDate={selectedFirstPaymentDate}
          setCalendarDate={setCalendarDate}
          disableWeekends={true}
        />
      </Grid2>
    </Grid2>
  )
}

function DateRolloverNotification({ firstPaymentDate }: { firstPaymentDate: string }) {
  const { dateFormatCustom } = useLocalizedFormatters()
  const { t } = useTranslation()
  const firstPaymentDateFormatted = firstPaymentDate
    ? dateFormatCustom(
        parseISO(firstPaymentDate),
        t('PaymentSchedule.EditPaymentSchedule.rolloverDateFormat'),
      )
    : ''

  return (
    <Grid2 size={12} marginBottom="24px" marginTop="8px">
      <Notification
        content={
          <Trans
            i18nKey="PaymentSchedule.EditPaymentSchedule.rolloverNotification"
            values={{ firstPaymentDateFormatted }}
          >
            We will process your first payment on{' '}
            <NotificationBold>{firstPaymentDateFormatted}</NotificationBold>, as your previous
            selection falls on a weekend or a holiday.
          </Trans>
        }
        icon={<InformationIconStyled />}
      />
    </Grid2>
  )
}

function FrequencyOptions({
  selectedFrequency,
  updateSelectedFrequency,
}: {
  selectedFrequency: PaymentScheduleEnum
  updateSelectedFrequency: (frequency: PaymentScheduleEnum) => void
}) {
  const { t } = useTranslation()
  const frequencyOptions = [
    {
      frequency: PaymentScheduleEnum.EVERY_WEEK,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.everyWeek'),
    },
    {
      frequency: PaymentScheduleEnum.EVERY_TWO_WEEKS,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.everyTwoWeeks'),
    },
    {
      frequency: PaymentScheduleEnum.TWICE_A_MONTH,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.twiceAMonth.primary'),
      secondaryText: t(
        'PaymentSchedule.EditPaymentSchedule.frequencyOptions.twiceAMonth.secondary',
      ),
    },
    {
      frequency: PaymentScheduleEnum.ONCE_A_MONTH,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.onceAMonth'),
    },
  ].map(({ frequency, primaryText, secondaryText }) => ({
    onClick: () => updateSelectedFrequency(frequency),
    isSelected: selectedFrequency === frequency,
    primaryText,
    secondaryText,
  }))
  return <RepaymentScheduleList options={frequencyOptions} />
}

function FrequencySubOptions({
  selectedSchedule,
  dispatch,
  isQCResident,
  systemDate,
}: {
  selectedSchedule: ScheduleSelection
  dispatch: Dispatch<ScheduleAction>
  systemDate: string
  isQCResident: boolean
}) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedSchedule.selectedFrequency])
  const earliestDate = addDays(parseISO(systemDate as string), MIN_DAYS_BEFORE_PAYMENT)
  const monthlyLatestDate = addDays(
    parseISO(systemDate as string),
    isQCResident ? MAX_DAYS_BEFORE_PAYMENT_QC : MAX_DAYS_BEFORE_PAYMENT,
  )

  const setScheduleReady = () => dispatch({ type: ScheduleActionType.MADE_SELECTION })

  const setSelectedFirstPaymentDate = (newFirstPaymentDate: Date | null) =>
    dispatch({ type: ScheduleActionType.UPDATE_FIRST_PAYMENT_DATE, newFirstPaymentDate })

  const setSelectedMonthlyChoice = (newMonthlyChoice: MonthlyScheduleEnum) =>
    dispatch({ type: ScheduleActionType.UPDATE_MONTHLY_CHOICE, newMonthlyChoice })

  const { selectedFrequency, selectedMonthlyChoice, selectedFirstPaymentDate } = selectedSchedule

  const getSubOptions = () => {
    switch (selectedFrequency) {
      case PaymentScheduleEnum.EVERY_WEEK:
        return (
          <EveryWeekScheduleOptions
            {...{
              earliestDate,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      case PaymentScheduleEnum.EVERY_TWO_WEEKS:
        return (
          <EveryTwoWeeksScheduleOptions
            {...{
              earliestDate,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      case PaymentScheduleEnum.TWICE_A_MONTH:
        return (
          <BiMonthlyScheduleOptions
            {...{
              earliestDate,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      case PaymentScheduleEnum.ONCE_A_MONTH:
        return (
          <MonthlyScheduleOptions
            {...{
              currentDate: parseISO(systemDate as string),
              earliestDate,
              latestDate: monthlyLatestDate,
              selectedMonthlyChoice,
              setSelectedMonthlyChoice,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      default:
        captureMessage(`Error unhandled payment frequency`, {
          level: 'warning',
          extra: { selectedFrequency },
        })
        return <></>
    }
  }
  return <div ref={containerRef}>{getSubOptions()}</div>
}

function PaymentScheduleCalendar({
  isLoading,
  showDisclaimer,
  first_payment_date,
  payment_cycle_due_date,
  schedule,
  monthly_choice,
  withdraw_amount,
  disableScroll,
}: {
  isLoading: boolean
  showDisclaimer: boolean
  first_payment_date?: string
  payment_cycle_due_date?: string
  schedule?: string
  monthly_choice?: string
  withdraw_amount?: string
  nextStep?: PortalModalsEnum
  disableScroll?: boolean
  setStep: (step: PortalModalsEnum) => void
}) {
  const { t } = useTranslation()
  const getPaymentScheduleText = usePaymentScheduleText()
  const { dateFormatCustom, currencyFormatFromStr } = useLocalizedFormatters()

  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (disableScroll) {
      return
    }

    const container = containerRef.current
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isLoading])

  const calendarContent = () => {
    if (isLoading) {
      return <CalendarLoader />
    }

    const displayWithdrawAmount = currencyFormatFromStr(withdraw_amount)
    const paymentScheduleText = getPaymentScheduleText({
      frequency: schedule! as PaymentScheduleEnum,
      monthlyChoice: monthly_choice! as MonthlyScheduleEnum,
      paymentCycleDueDate: payment_cycle_due_date ? parseISO(payment_cycle_due_date!) : null,
    })

    const displayDate = first_payment_date
      ? dateFormatCustom(
          parseISO(first_payment_date),
          t('PaymentSchedule.PaymentScheduleCalendar.firstPaymentDateFormat'),
        )
      : ''

    return (
      <Grid2 container padding="16px">
        <Grid2 size={12}>
          <Typography
            data-testid="withdrawalMessage"
            textAlign="center"
            variant="h3"
            color={theme.color.grey9}
          >
            <Trans
              i18nKey="PaymentSchedule.PaymentScheduleCalendar.withdrawalText"
              values={{
                displayWithdrawAmount,
                paymentScheduleText,
              }}
            >
              We will withdraw <PaymentProtectionInfo amount={displayWithdrawAmount} />
              {paymentScheduleText}
            </Trans>
          </Typography>
          {displayDate && (
            <Typography
              textAlign="center"
              variant="body2"
              marginTop="4px"
              color={theme.color.grey6}
            >
              {t('PaymentSchedule.PaymentScheduleCalendar.startText', { displayDate })}
            </Typography>
          )}
        </Grid2>
      </Grid2>
    )
  }

  return (
    <Grid2 container marginBottom="16px" ref={containerRef}>
      <PADScheduleHeader size={12}>
        <PADHeaderContainer>
          <CalendarMonthOutlinedIcon
            sx={{ color: theme.color.grey9 }}
            aria-label={t('PaymentSchedule.PaymentScheduleCalendar.alt')}
          />
          <PADHeadingText>{t('PaymentSchedule.PaymentScheduleCalendar.title')}</PADHeadingText>
        </PADHeaderContainer>
      </PADScheduleHeader>
      <PADScheduleContainer>{calendarContent()}</PADScheduleContainer>
      {showDisclaimer && <Disclaimer />}
    </Grid2>
  )
}

export type ScheduleSelection = {
  selectedFrequency: PaymentScheduleEnum
  selectedMonthlyChoice?: MonthlyScheduleEnum
  selectedFirstPaymentDate: Date | null
}

type ScheduleState = {
  madeSelection: boolean
  generatedPaymentSchedule: boolean
  selection: ScheduleSelection
  generated?: RepaymentScheduleResponseType
}

enum ScheduleActionType {
  UPDATE_FREQUENCY,
  UPDATE_MONTHLY_CHOICE,
  UPDATE_FIRST_PAYMENT_DATE,
  MADE_SELECTION,
  GENERATED_PAYMENT_SCHEDULE,
}

type ScheduleAction =
  | { type: ScheduleActionType.UPDATE_FREQUENCY; newFrequency: PaymentScheduleEnum }
  | { type: ScheduleActionType.UPDATE_MONTHLY_CHOICE; newMonthlyChoice: MonthlyScheduleEnum }
  | { type: ScheduleActionType.UPDATE_FIRST_PAYMENT_DATE; newFirstPaymentDate: Date | null }
  | { type: ScheduleActionType.MADE_SELECTION }
  | {
      type: ScheduleActionType.GENERATED_PAYMENT_SCHEDULE
      generated: RepaymentScheduleResponseType
    }

const reducer = (prevState: ScheduleState, action: ScheduleAction): ScheduleState => {
  switch (action.type) {
    case ScheduleActionType.UPDATE_FREQUENCY:
      return {
        madeSelection: false,
        generatedPaymentSchedule: false,
        selection: {
          selectedFrequency: action.newFrequency,
          selectedFirstPaymentDate: null,
        },
      }
    case ScheduleActionType.UPDATE_MONTHLY_CHOICE:
      return {
        madeSelection: false,
        generatedPaymentSchedule: false,
        selection: {
          selectedFrequency: prevState.selection.selectedFrequency,
          selectedMonthlyChoice: action.newMonthlyChoice,
          selectedFirstPaymentDate: null,
        },
      }
    case ScheduleActionType.UPDATE_FIRST_PAYMENT_DATE:
      return {
        madeSelection: false,
        generatedPaymentSchedule: false,
        selection: {
          ...prevState.selection,
          selectedFirstPaymentDate: action.newFirstPaymentDate,
        },
      }
    case ScheduleActionType.MADE_SELECTION:
      return {
        ...prevState,
        madeSelection: true,
        generatedPaymentSchedule: false,
      } // ^ madeSelection: true & generatedPaymentSchedule: false should trigger useEffect
    case ScheduleActionType.GENERATED_PAYMENT_SCHEDULE:
      if (!prevState.madeSelection) {
        // Don't update generatedPaymentSchedule if the selection has changed
        return prevState
      }

      return {
        ...prevState,
        generated: action.generated,
        generatedPaymentSchedule: true,
      }
  }
}

/**
 *
 */

interface EditPaymentScheduleProps {
  applicationId: string
  loanId: string
  cachedSchedule: RepaymentScheduleResponseType
  setStep: (step: PortalModalsEnum | any) => void
  systemDate: string
  isQCResident: boolean
  scheduleState: ScheduleState
  dispatch: Dispatch<ScheduleAction>
}

export function EditPaymentSchedule({
  loanId,
  cachedSchedule,
  setStep,
  systemDate,
  isQCResident,
  scheduleState,
  dispatch,
}: EditPaymentScheduleProps) {
  const { t } = useTranslation()
  const { dateFormatCustom, dateFormat } = useLocalizedFormatters()
  const {
    portalApiPaymentScheduleChangeValidateSchedule,
    portalApiPaymentScheduleChangeProcessSelectedDate,
  } = usePortalApi()

  const { refetch, isError } = useQuery<PaymentScheduleChangeProcessSelectedDateResponseSchema>(
    [
      'generatePaymentSchedule',
      scheduleState.selection.selectedFirstPaymentDate,
      scheduleState.selection.selectedMonthlyChoice,
      scheduleState.selection.selectedFirstPaymentDate,
    ],
    () => {
      const { selectedFrequency, selectedFirstPaymentDate, selectedMonthlyChoice } =
        scheduleState.selection!
      return portalApiPaymentScheduleChangeProcessSelectedDate(loanId, {
        payment_frequency: selectedFrequency,
        monthly_choice: selectedMonthlyChoice, // TODO: This was used in the old edit payment schedule but the new API doesn't support it. Follow up on it.
        repayment_start_date: dateFormat(selectedFirstPaymentDate),
      })
    },
    {
      enabled: false,
      useErrorBoundary: false,
      onSuccess: result => {
        dispatch({
          type: ScheduleActionType.GENERATED_PAYMENT_SCHEDULE,
          generated: {
            withdraw_amount: (result.regular_payment_amount || '').toString(),
            first_payment_date: result.next_working_payment_date,
            payment_cycle_due_date: result.next_working_payment_date,
            // monthly_choice: result.monthly_choice, // TODO: The api should return the monthly choice or find an alternative
            schedule: result.payment_frequency, // TODO: The api should return the selected frequency
          },
        })
      },
      onError: err => {
        captureExceptionHelper('Error refetching from Portal.EditPaymentSchedule', err)
      },
    },
  )

  const { mutate } = useMutation(
    ['portalApiPaymentScheduleChangeValidateSchedule', loanId],
    (payload: PaymentScheduleValidationRequestSchema) => {
      return portalApiPaymentScheduleChangeValidateSchedule(loanId, payload)
    },
    {
      useErrorBoundary: false,
    },
  )

  useEffect(() => {
    if (scheduleState.madeSelection && !scheduleState.generatedPaymentSchedule) {
      try {
        refetch()
      } catch (err: any) {
        captureExceptionHelper('Error refetching from Portal.EditPaymentSchedule', err)
      }
    }
  }, [
    scheduleState.selection.selectedFirstPaymentDate,
    scheduleState.selection.selectedMonthlyChoice,
    scheduleState.selection.selectedFirstPaymentDate,
    scheduleState.madeSelection,
    scheduleState.generatedPaymentSchedule,
  ])

  const isFirstPaymentDateMismatch =
    scheduleState.generatedPaymentSchedule &&
    scheduleState.generated?.first_payment_date !== scheduleState.generated?.payment_cycle_due_date

  const firstPaymentDate =
    scheduleState.generated?.first_payment_date || cachedSchedule?.first_payment_date

  return (
    <DivRoot>
      <Grid2 container>
        <Grid2 size={12} paddingBottom="8px" marginBottom="8px">
          <Typography variant="h2" textAlign="center">
            {t('Portal.Reschedule.EditPaymentSchedule.title')}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 container>
        <Grid2 size={12}>
          <Typography variant="body2" color="black">
            {t('Portal.Reschedule.EditPaymentSchedule.frequencyTitle', {
              startDate: firstPaymentDate
                ? dateFormatCustom(
                    parseISO(firstPaymentDate as string),
                    t('Portal.common.dateFormat'),
                  )
                : '',
            })}
          </Typography>
        </Grid2>
        <Grid2 id="frequency-options" container spacing="0px" width="100%">
          <Grid2 size={12}>
            <FrequencyOptions
              selectedFrequency={scheduleState.selection.selectedFrequency}
              updateSelectedFrequency={newFrequency => {
                dispatch({ type: ScheduleActionType.UPDATE_FREQUENCY, newFrequency })
                mutate({ selected_frequency: newFrequency })
              }}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing="0px" id="frequency-suboptions" width="100%">
          <Grid2 size={12}>
            {
              <FrequencySubOptions
                selectedSchedule={scheduleState.selection}
                dispatch={dispatch}
                systemDate={systemDate}
                isQCResident={isQCResident}
              />
            }
            {isFirstPaymentDateMismatch && (
              <DateRolloverNotification
                firstPaymentDate={scheduleState.generated!.first_payment_date}
              />
            )}
            {scheduleState.madeSelection && (
              <PaymentScheduleCalendar
                isLoading={!scheduleState.generatedPaymentSchedule}
                showDisclaimer={false}
                first_payment_date={
                  scheduleState.generated?.first_payment_date || cachedSchedule?.first_payment_date
                }
                payment_cycle_due_date={
                  scheduleState.generated?.payment_cycle_due_date ||
                  cachedSchedule?.payment_cycle_due_date
                }
                schedule={scheduleState.generated?.schedule || cachedSchedule?.schedule}
                monthly_choice={
                  scheduleState.generated?.monthly_choice || cachedSchedule?.monthly_choice
                }
                withdraw_amount={
                  scheduleState.generated?.withdraw_amount || cachedSchedule?.withdraw_amount
                }
                setStep={setStep}
              />
            )}
          </Grid2>
        </Grid2>
      </Grid2>

      {isError && (
        <Grid2 container>
          <Grid2 size={12}>
            <FigAlertError />
          </Grid2>
        </Grid2>
      )}
    </DivRoot>
  )
}

export const ChangePaymentEditModal = () => {
  const { openModal, closeAllModals } = useModalContext()
  const { t } = useTranslation()
  const {
    loanDetailData,
    portalApiPaymentScheduleChange: { validateMutation, processSelectedDateMutation },
  } = usePortalContext()
  const [initialized, setInitialized] = useState(false)
  const applicationId = loanDetailData?.application_id || ''
  const loanId = loanDetailData?.id || ''
  const [cachedSchedule, setCachedSchedule] = useState<RepaymentScheduleResponseType>(
    {} as RepaymentScheduleResponseType,
  )
  const frequency = cachedSchedule?.schedule as PaymentScheduleEnum | undefined
  const monthlyChoice = cachedSchedule?.monthly_choice as MonthlyScheduleEnum | undefined
  const paymentCycleDueDateStr = cachedSchedule?.payment_cycle_due_date
  const paymentCycleDueDate = paymentCycleDueDateStr ? parseISO(paymentCycleDueDateStr) : null

  const initialScheduleState: ScheduleState = {
    madeSelection: false,
    generatedPaymentSchedule: false,
    selection: {
      selectedFrequency: frequency ?? PaymentScheduleEnum.ONCE_A_MONTH,
      selectedMonthlyChoice: frequency ? monthlyChoice : MonthlyScheduleEnum.OTHER,
      selectedFirstPaymentDate: paymentCycleDueDate,
    },
  }
  const [scheduleState, dispatch] = useReducer<typeof reducer>(reducer, initialScheduleState)

  const saveSchedule = ({
    schedule,
    monthly_choice,
    withdraw_amount,
    first_payment_date,
    payment_cycle_due_date,
  }: RepaymentScheduleResponseType) => {
    setCachedSchedule({
      withdraw_amount,
      first_payment_date,
      monthly_choice,
      schedule,
      payment_cycle_due_date,
    })
  }

  const saveScheduleLabel = t('Portal.Reschedule.EditPaymentSchedule.buttonNext')

  const handleContinue = async () => {
    if (scheduleState?.generated) {
      // TODO: Remove this when the backend API has been implemented
      const {
        withdraw_amount,
        first_payment_date,
        monthly_choice,
        schedule,
        payment_cycle_due_date,
      } = scheduleState.generated!
      saveSchedule({
        withdraw_amount,
        first_payment_date,
        monthly_choice,
        schedule,
        payment_cycle_due_date,
      })

      try {
        await processSelectedDateMutation.mutateAsync({
          is_next: true,
          payment_frequency: schedule,
          repayment_start_date: first_payment_date,
        })
        openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_CONFIRM)
      } catch (err: any) {
        captureExceptionHelper('Error continuing from Portal.EditPaymentSchedule', err)
      }
    } else {
      captureExceptionHelper(
        'Error continuing from Portal.ConfirmSchedule',
        new Error('scheduleState?.generated is undefined'),
      )
    }
  }

  useEffect(() => {
    if (validateMutation.data && !initialized) {
      setInitialized(true)
    }
  }, [validateMutation.data])

  return (
    <ActionModal
      content={
        processSelectedDateMutation.isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <EditPaymentSchedule
              applicationId={applicationId || ''}
              loanId={loanId}
              cachedSchedule={cachedSchedule}
              setStep={() => openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_CONFIRM)}
              systemDate={new Date().toISOString()}
              isQCResident={false}
              dispatch={dispatch}
              scheduleState={scheduleState}
            />
            {validateMutation.isError && <FigAlertError />}
          </>
        )
      }
      nextButtonHandler={handleContinue}
      buttonText={saveScheduleLabel}
      closeButtonHandler={closeAllModals}
    />
  )
}

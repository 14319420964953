import { Box, Grid2, styled, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { DocumentHistoryResponseSchema, DocumentTypeEnum } from 'src/portal/api/api.schemas'
import { PortalLink } from 'src/portal/components/PortalLink'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import PortalTheme from 'src/themes/portal'
import { pdfFileDownload } from 'src/utils/files'

const BoxSection = styled(Box)(() => ({
  ':not(:first-child)': {
    paddingTop: '24px',
  },

  ':not(:last-child)': {
    paddingBottom: '24px',
  },

  ':not(:first-child):not(:last-child)': {
    borderBottom: `1px solid ${PortalTheme.color.grey4}`,
  },
}))

interface TransactionRowProps {
  title: string
  items: DocumentHistoryResponseSchema[]
}

const DateItems = ({ items }: { items: DocumentHistoryResponseSchema[] }) => {
  const { t } = useTranslation()
  const { portalApiDownloadDocument } = usePortalApi()
  const { loanDetailData } = usePortalContext()

  const downloadDocument = async (documentId: string, documentType: string) => {
    const documentName =
      documentType === DocumentTypeEnum.PAD_AGREEMENT
        ? t('Portal.Documents.padAgreement')
        : t('Portal.Documents.loanAgreement')
    const resp = await portalApiDownloadDocument(loanDetailData?.id || '', documentId)
    pdfFileDownload(resp?.content, `${documentName}.pdf`)
  }

  return items.map(item => {
    return (
      <SpacedRow key={item.id}>
        <Grid2>
          <Typography variant="body2" color={PortalTheme.color.grey10}>
            {item.document_type === DocumentTypeEnum.PAD_AGREEMENT
              ? t('Portal.Documents.padAgreement')
              : t('Portal.Documents.loanAgreement')}
          </Typography>
        </Grid2>
        <Grid2 alignItems="flex-start">
          <PortalLink
            text={t('Portal.Documents.download')}
            onClickHandler={() => {
              downloadDocument(item.content_document_id, item.document_type || '')
            }}
          />
        </Grid2>
      </SpacedRow>
    )
  })
}

const DateSection = ({ title, items }: TransactionRowProps) => {
  return (
    <BoxSection>
      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey7}>
            {title}
          </Typography>
        </Grid2>
      </SpacedRow>

      <DateItems items={items} />
    </BoxSection>
  )
}

export default function Documents() {
  const { loanDetailData } = usePortalContext()
  const { portalApiGetDocumentHistory } = usePortalApi()
  const { dateFormatCustom } = useLocalizedFormatters()

  const { refetch, data } = useQuery<DocumentHistoryResponseSchema[]>(
    ['portalApiGetDocumentHistory', loanDetailData?.application_id, loanDetailData?.id],
    () => {
      if (loanDetailData?.application_id && loanDetailData?.id) {
        return portalApiGetDocumentHistory(
          loanDetailData?.application_id || '',
          loanDetailData?.id || '',
        )
      }
      return []
    },
    {
      enabled: !!(loanDetailData?.application_id && loanDetailData?.id),
      useErrorBoundary: false,
    },
  )

  const { t } = useTranslation()

  useEffect(() => {
    refetch()
  }, [])

  if (!data) {
    return (
      <div>
        <LoadingAnimation />
      </div>
    )
  }

  // group data by date where the date format is 2024-09-04T08:14:28Z where the date key is a date object
  const groupedData = data.reduce(
    (acc, item) => {
      const date = new Date(item.date.split('T')[0])
      const dateString = date.toISOString().split('T')[0]
      if (!acc[dateString]) {
        acc[dateString] = []
      }
      acc[dateString].push(item)
      return acc
    },
    {} as Record<string, DocumentHistoryResponseSchema[]>,
  )

  return (
    <Grid2 container flexDirection="column">
      <Grid2 pb="24px">
        <Typography variant="h2" color={PortalTheme.color.black}>
          {t('Portal.Documents.title')}
        </Typography>
        <Typography variant="body2" color={PortalTheme.color.grey7} pt="24px">
          {t('Portal.Documents.subtitle', { loanName: loanDetailData?.name })}
        </Typography>
      </Grid2>

      {Object.keys(groupedData)
        .sort((a: string, b: string) => new Date(b).valueOf() - new Date(a).valueOf())
        .map(key => {
          return (
            <DateSection
              key={key}
              title={dateFormatCustom(new Date(key), t('Portal.common.dateFormat'))}
              items={groupedData[key]}
            />
          )
        })}
    </Grid2>
  )
}

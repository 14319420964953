import { Grid, Typography, useMediaQuery } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import DisclaimerCross from 'src/components/common/DisclaimerCross'
import { PrequalificationContext } from 'src/contexts'
import {
  CreditKarmaPromo50Banner,
  CreditKarmaPromoBanner,
  FairstoneBanner,
  FigFinancialReferralBanner,
  LFLGroupPromoBanner,
  PerkopolisPromoBanner,
  TheBrickPromoBanner,
  CashContest1K,
} from 'src/images'
import theme from 'src/themes'
import { FeatureFlags, GetPartnerConfigResponseType, PartnersEnum } from 'src/types'
import { isPartnerPromoActive } from 'src/utils/core'

const getPartnerContent = (
  partnerConfig: GetPartnerConfigResponseType | null,
  t: any,
  isFigLandingPromo: boolean,
) => {
  const partner = partnerConfig?.name as string
  const isPromoActive = isPartnerPromoActive(isFigLandingPromo, partner)

  if (partnerConfig?.is_referral) {
    return {
      img: FigFinancialReferralBanner,
      title: t(`LandingPromoBanner.FigFinancialReferral.title`),
      content: t(`LandingPromoBanner.FigFinancialReferral.content`),
      applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
      altText: t(`LandingPromoBanner.FigFinancialReferral.altText`),
    }
  }

  //feature flag is on for contest and is in the list of partners
  if (isPromoActive) {
    return {
      img: CashContest1K,
      title: t(`LandingPromoBanner.cashContestLandingPromo.title`),
      content: (
        <Trans
          i18nKey="LandingPromoBanner.cashContestLandingPromo.content"
          components={{ disclaimerCross: <DisclaimerCross /> }}
        ></Trans>
      ),
      applyNowLabel: t(`LandingPromoBanner.applyNowGetOfferLabel`),
      altText: t(`LandingPromoBanner.cashContestLandingPromo.altText`),
    }
  }
  switch (partner) {
    case PartnersEnum.LFLGROUP:
      return {
        img: LFLGroupPromoBanner,
        title: t(`LandingPromoBanner.LFLGroup.title`),
        content: t(`LandingPromoBanner.LFLGroup.content`),
        applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
        altText: t(`LandingPromoBanner.LFLGroup.altText`),
      }
    case PartnersEnum.PERKOPOLIS:
      return {
        img: PerkopolisPromoBanner,
        title: t(`LandingPromoBanner.Perkopolis.title`),
        content: t(`LandingPromoBanner.Perkopolis.content`),
        applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
        altText: t(`LandingPromoBanner.Perkopolis.altText`),
      }
    case PartnersEnum.CREDIT_KARMA_PROMO:
      return {
        img: CreditKarmaPromo50Banner,
        title: t(`LandingPromoBanner.creditKarmaPromo.title`),
        content: t(`LandingPromoBanner.creditKarmaPromo.content`),
        applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
        altText: t(`LandingPromoBanner.creditKarmaPromo.altText`),
      }
    case PartnersEnum.CREDIT_KARMA:
      return {
        img: CreditKarmaPromoBanner,
        title: t(`LandingPromoBanner.CreditKarma.title`),
        content: t(`LandingPromoBanner.CreditKarma.content`),
        applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
        altText: t(`LandingPromoBanner.CreditKarma.altText`),
      }

    case PartnersEnum.THE_BRICK:
      return {
        img: TheBrickPromoBanner,
        title: t(`LandingPromoBanner.theBrick.title`),
        content: t(`LandingPromoBanner.theBrick.content`),
        applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
        altText: t(`LandingPromoBanner.theBrick.altText`),
      }
    case PartnersEnum.FAIRSTONE:
      return {
        img: FairstoneBanner,
        title: t(`LandingPromoBanner.fairstone.title`),
        content: t(`LandingPromoBanner.fairstone.content`),
        applyNowLabel: t(`LandingPromoBanner.applyNowLabel`),
        altText: t(`LandingPromoBanner.fairstone.altText`),
      }
    default:
      return {
        img: '',
        title: '',
        content: '',
        applyNowLabel: '',
        altText: '',
      }
  }
}

type LandingPromoBannerProps = {
  partner: string
  handleClick: () => void
}

export const LandingPromoBanner = ({ partner, handleClick }: LandingPromoBannerProps) => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { partnerConfig } = useContext(PrequalificationContext)
  const { isFigLandingPromo } = useFlags<FeatureFlags>()
  const { img, title, content, applyNowLabel, altText } = getPartnerContent(
    partnerConfig,
    t,
    isFigLandingPromo,
  )
  const isPromoActive = isPartnerPromoActive(isFigLandingPromo, partner)
  const imgStyle = isDesktop
    ? { width: '100%', padding: '0 0 0 16px' }
    : {
        width: '50%',
        height: 'auto',
      }

  if (isPromoActive) {
    return (
      <Grid
        container
        sx={{
          borderRadius: '8px',
          border: 'solid 1px',
          borderColor: theme.color.grey4,
          bgcolor: theme.color.white,
          marginTop: '48px',
          flexDirection: 'row-reverse',
          textAlign: isDesktop ? 'left' : 'center',
          px: '24px',
          py: '16px',
        }}
      >
        <Grid xs={12} md={4} item>
          <img src={img} alt={altText} style={imgStyle} />
        </Grid>
        <Grid xs={12} md={8} item>
          <Typography variant="h2" color={theme.color.grey9} py="4px">
            {title}
          </Typography>
          <Typography variant="body2" color={theme.color.grey9} py="4px">
            {content}
          </Typography>
          <PrimaryCTA
            sx={{ width: 2 / 3, marginTop: '8px' }}
            buttonText={applyNowLabel}
            onClick={handleClick}
          />
        </Grid>
      </Grid>
    )
  }
  if (partner === PartnersEnum.FAIRSTONE) {
    return (
      <Grid
        container
        sx={{
          borderRadius: '8px',
          border: 'solid 1px',
          borderColor: theme.color.grey4,
          bgcolor: theme.color.white,
          marginTop: '64px',
          flexDirection: 'row-reverse',
          textAlign: isDesktop ? 'left' : 'center',
          px: '14px',
          py: '16px',
        }}
      >
        <Grid xs={12} item display="flex" justifyContent="center" paddingY="20px">
          <img src={img} alt={altText} style={{ width: '180px' }} />
        </Grid>

        <Grid xs={12} item>
          <Typography variant="h2" color={theme.color.grey9} py="4px" textAlign="center">
            {title}
          </Typography>
        </Grid>

        <Grid xs={12} item>
          <Typography variant="body2" color={theme.color.grey9} py="4px" textAlign="center">
            {content}
          </Typography>
        </Grid>

        <Grid xs={12} item display="flex" justifyContent="center">
          <PrimaryCTA
            sx={{ width: 190, marginTop: '8px' }}
            buttonText={applyNowLabel}
            onClick={handleClick}
          />
        </Grid>
      </Grid>
    )
  }

  if (partnerConfig?.is_referral) {
    return (
      <>
        <Grid
          container
          sx={{
            borderRadius: '8px',
            border: 'solid 1px',
            borderColor: theme.color.grey4,
            bgcolor: theme.color.white,
            marginTop: '36px',
            flexDirection: 'row-reverse',
            textAlign: isDesktop ? 'left' : 'center',
            px: '24px',
            py: '16px',
          }}
        >
          <Grid xs={12} item>
            <Typography variant="h2" color={theme.color.grey9} py="4px" textAlign="center">
              {title}
            </Typography>
          </Grid>
          <Grid xs={12} item display="flex" justifyContent="center" marginBottom="8px">
            <img src={img} alt={altText} style={{ width: 262, height: 157 }} />
          </Grid>
          <Grid xs={12} item>
            <Typography variant="body2" color={theme.color.grey10} py="4px">
              {t(`LandingPromoBanner.FigFinancialReferral.content1`)}
            </Typography>
            <Typography variant="body4" py="4px">
              {t(`LandingPromoBanner.FigFinancialReferral.content2`)}
            </Typography>
          </Grid>
          <Grid xs={12} item display="flex" justifyContent="center" marginTop="16px">
            <PrimaryCTA sx={{ width: '134px' }} buttonText={applyNowLabel} onClick={handleClick} />
          </Grid>
        </Grid>
      </>
    )
  }
  return (
    <Grid
      container
      sx={{
        borderRadius: '8px',
        border: 'solid 1px',
        borderColor: theme.color.grey4,
        bgcolor: theme.color.white,
        marginTop: '48px',
        flexDirection: 'row-reverse',
        textAlign: isDesktop ? 'left' : 'center',
        px: '24px',
        py: '16px',
      }}
    >
      <Grid xs={12} md={4} item>
        <img src={img} alt={altText} style={imgStyle} />
      </Grid>
      <Grid xs={12} md={8} item>
        <Typography variant="h2" color={theme.color.grey9} py="4px">
          {title}
        </Typography>
        <Typography variant="body2" color={theme.color.grey9} py="4px">
          {content}
        </Typography>
        <PrimaryCTA
          sx={{ width: 2 / 3, marginTop: '8px' }}
          buttonText={applyNowLabel}
          onClick={handleClick}
        />
      </Grid>
    </Grid>
  )
}

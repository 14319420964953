import { FormControl, RadioGroup } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PaymentDetailResponseSchema } from 'src/portal/api/api.schemas'
import { LoanStatusEnum, PortalModalsEnum } from 'src/portal/common'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import { ExtraAmountInput } from 'src/portal/components/modals/ManualPayments/ExtraAmountInput'
import { PaymentOptionSelect } from 'src/portal/components/modals/ManualPayments/PaymentOptionSelect'
import { PaymentTypeEnum } from 'src/portal/contexts/modal'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { OverdueMessage } from 'src/portal/pages/payments/components/OverdueMessage'
import { FigAlertError } from 'src/portal/pages/reschedule/components/common'

enum PaymentOptionsEnum {
  outstandingAmount = `outstandingAmount`,
  nextPayment = 'nextPayment',
  extraAmount = 'extraAmount',
  remainingBalance = 'remainingBalance',
}

interface ManualPaymentModalContentProps {
  selectedOption: string
  setSelectedOption: (value: string) => void
  setExtraAmount: (value: number) => void
  paymentDetailData: PaymentDetailResponseSchema[] | undefined
  loanDetailData: any
}

const ManualPaymentModalContent = ({
  selectedOption,
  setSelectedOption,
  setExtraAmount,
  paymentDetailData,
  loanDetailData,
}: ManualPaymentModalContentProps) => {
  const { dateFormatCustom } = useLocalizedFormatters()
  const { t } = useTranslation()

  const nextPaymentDate = paymentDetailData?.[0]?.next_payment?.date || ''
  const nextPaymentAmount = paymentDetailData?.[0]?.next_payment?.amount || 0
  const outstandingAmount = paymentDetailData?.[0]?.outstanding_amount || 0
  const remainingBalanceAmount = paymentDetailData?.[0]?.pay_off_amount || 0
  const nextPaymentDateText = dateFormatCustom(
    new Date(nextPaymentDate),
    t('Portal.common.dateFormat'),
  )

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
  }

  const paymentOptionsList = [
    loanDetailData?.loan_status === LoanStatusEnum.OVERDUE
      ? {
          title: t('Portal.Components.modal.manualPayments.paymentOptions.outstandingAmount.title'),
          description: t(
            'Portal.Components.modal.manualPayments.paymentOptions.outstandingAmount.description',
          ),
          value: PaymentOptionsEnum.outstandingAmount,
          amount: outstandingAmount,
        }
      : {
          title: t('Portal.Components.modal.manualPayments.paymentOptions.nextPayment.title'),
          description: t(
            'Portal.Components.modal.manualPayments.paymentOptions.nextPayment.description',
          ),
          value: PaymentOptionsEnum.nextPayment,
          amount: nextPaymentAmount,
        },
    {
      title: t('Portal.Components.modal.manualPayments.paymentOptions.extraAmount.title'),
      description: t(
        'Portal.Components.modal.manualPayments.paymentOptions.extraAmount.description',
      ),
      value: PaymentOptionsEnum.extraAmount,
    },
    {
      title: t('Portal.Components.modal.manualPayments.paymentOptions.remainingBalance.title'),
      description: t(
        'Portal.Components.modal.manualPayments.paymentOptions.remainingBalance.description',
      ),
      value: PaymentOptionsEnum.remainingBalance,
      amount: remainingBalanceAmount,
    },
  ]

  return (
    <>
      {loanDetailData.loan_status === LoanStatusEnum.OVERDUE && <OverdueMessage />}

      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="payment-options" value={selectedOption} onChange={handleOptionChange}>
          {paymentOptionsList.map((option, index) => (
            <PaymentOptionSelect
              key={index}
              title={option.title}
              description={option.description}
              value={option.value}
              amount={option.amount}
            />
          ))}
        </RadioGroup>

        {selectedOption === PaymentOptionsEnum.nextPayment && (
          <>
            <InfoMessage
              content={t('Portal.Components.modal.manualPayments.infoMessage.nextPayment', {
                nextPaymentDate: nextPaymentDateText,
              })}
            />
          </>
        )}
        {selectedOption === PaymentOptionsEnum.extraAmount && (
          <>
            <ExtraAmountInput setExtraAmount={setExtraAmount} />
            <InfoMessage
              content={t('Portal.Components.modal.manualPayments.infoMessage.extraAmount')}
            />
          </>
        )}
      </FormControl>
    </>
  )
}

export const ManualPaymentModal = () => {
  const { t } = useTranslation()
  const { closeAllModals, openModal, setPaymentAmount, setPaymentType } = useModalContext()
  const [extraAmount, setExtraAmount] = useState<number>(0)
  const { loanDetailData, paymentDetailData } = usePortalContext()
  const [selectedOption, setSelectedOption] = useState<string>(
    paymentDetailData?.[0]?.outstanding_amount
      ? PaymentOptionsEnum.outstandingAmount
      : PaymentOptionsEnum.nextPayment,
  )

  const MINIMUM_PAYMENT_AMOUNT = 0.01 // current SSP minimum payment amount is 0.01

  const isExtraAmountValid =
    extraAmount >= MINIMUM_PAYMENT_AMOUNT &&
    extraAmount <= (paymentDetailData?.[0]?.pay_off_amount ?? 0)

  const onSubmit = () => {
    openModal(PortalModalsEnum.CONFIRM_PAYMENT_MODAL)
    if (selectedOption === PaymentOptionsEnum.outstandingAmount) {
      setPaymentAmount(paymentDetailData?.[0].outstanding_amount || 0)
      setPaymentType(PaymentTypeEnum.nextPayment)
    }
    if (selectedOption === PaymentOptionsEnum.nextPayment) {
      setPaymentAmount(paymentDetailData?.[0].next_payment?.amount || 0)
      setPaymentType(PaymentTypeEnum.nextPayment)
    }

    if (selectedOption === PaymentOptionsEnum.remainingBalance) {
      setPaymentAmount(paymentDetailData?.[0]?.pay_off_amount || 0)
      setPaymentType(PaymentTypeEnum.remainingBalance)
    }

    if (selectedOption === PaymentOptionsEnum.extraAmount) {
      setPaymentAmount(extraAmount)
      setPaymentType(PaymentTypeEnum.extraPayment)
    }
  }

  const manualPaymentModalContentProps = {
    selectedOption,
    setSelectedOption,
    setExtraAmount,
    loanDetailData,
    paymentDetailData,
  }

  return (
    <ActionModal
      title={t('Portal.Components.modal.manualPayments.title')}
      description={t('Portal.Components.modal.manualPayments.description')}
      content={
        Object.keys(paymentDetailData || {}).length === 0 || !loanDetailData?.id ? (
          <>
            <FigAlertError />
            <LoadingAnimation />
          </>
        ) : (
          <ManualPaymentModalContent {...manualPaymentModalContentProps} />
        )
      }
      buttonText={t('Portal.Components.modal.manualPayments.buttonText')}
      closeButtonHandler={closeAllModals}
      nextButtonHandler={onSubmit}
      nextButtonEnabled={
        selectedOption !== PaymentOptionsEnum.extraAmount ||
        (selectedOption === PaymentOptionsEnum.extraAmount && isExtraAmountValid)
      }
    />
  )
}

import enPortalTranslation from 'src/locales/en/portalTranslation'
const portalTranslation: typeof enPortalTranslation = {
  Portal: {
    common: {
      dateFormat: 'EEEE dd MMMM yyyy',
    },
    Header: {
      alt: {
        figLogo: 'Logo Fig',
        userIcon: 'Icône utilisateur',
        languageDropdown: 'Basculer le menu déroulant',
      },
      label: {
        languageDropdown: 'Language',
        profileSettings: 'Paramètres du profil',
        logout: 'Se déconnecter',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/fr/referral/`,
        terms: `https://fig.ca/fr/legal/terms-of-service/`,
        privacy: `https://fig.ca/fr/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/fr/support/home`,
      },
      alt: {
        homeIcon: 'Icône d’accueil',
        paymentsIcon: 'Icône de paiements',
        giftIcon: 'Icône de cadeau',
        documentsIcon: 'Icône de documents',
        moreIcon: 'Icône de plus',
        paymentProtectionIcon: 'Icône de protection des paiements',
        loanIncreaseIcon: 'Icône d’augmentation de prêt',
        helpCenterIcon: 'Icône du centre d’aide',
        termsIcon: 'Icône des conditions d’utilisation',
        notificationIcon: 'Icône de notification',
        dividerIcon: 'Séparateur de menu',
      },
      label: {
        home: 'Accueil',
        payments: 'Paiements',
        documents: 'Gérer les paiements',
        managePayments: 'Gérer les paiements',
        viewDocuments: 'Voir les documents',
        more: 'Plus',
        paymentProtection: 'Protection de paiement',
        loanIncrease: 'Demander une augmentation du montant du prêt',
        helpCenter: `Centre d'aide`,
        terms: `Conditions d'utilisation`,
      },
    },
    Home: {
      label: {
        tab: {
          summary: 'Résumé',
          transactions: 'Transactions',
          upcomingPayments: 'Paiements à venir',
        },
        summary: {
          title: 'Résumé',
          loanStatus: {
            good: 'Bon statut',
            overdue: 'Paiement en retard',
            closed: 'Clôturé',
          },
          loanClosedMessage: `Votre prêt a été remboursé.`,
          remainingBalance: 'Solde restant',
          upcomingPaymentAmount: 'Montant du paiement à venir',
          upcomingPaymentDate: 'Date du paiement à venir',
          automaticPaymentsOn: 'Paiements automatiques : Activés',
          automaticPaymentsOff: 'Paiements automatiques : Désactivés',
          paymentProtectionActive: 'Protection de paiement : Active',
          loanDetails: 'Voir les détails',
          loanId: 'Identifiant du prêt',
          loanAmount: 'Montant du prêt',
          interestRate: `Taux d'intérêt`,
          loanTerm: `Durée du prêt`,
          loanTermInMonths: 'Durée du prêt',
          loanStartDate: 'Date de début du prêt',
          outstandingAmount: '',
          outstandingAmountDueDate: '',
          payNow: '',
          payOutstandingAmount: '',
          loanClosedDate: `Date de clôture du prêt`,
        },
        transactions: {
          title: `Transactions`,
          description:
            'Voici ci-dessous tous les paiements que vous avez effectués, y compris les paiements programmés et les paiements uniques, ainsi que les frais supplémentaires sur votre compte.',
          status: {
            processing: 'Traitement',
            failed: 'Échoué',
          },
          message: {
            processing: `Veuillez vous assurer qu'il y a suffisamment de fonds sur votre compte jusqu'à ce que le paiement soit traité dans dans 2 jours ouvrables`,
          },
        },
        upcomingPayments: {
          title: `Paiements à venir`,
          description: `Si vous avez effectué un paiement forfaitaire, le montant de votre paiement mensuel ne sera pas réduit, mais la durée de votre prêt sera raccourcie.`,
          descriptionWithPaymentProtection: `Des frais de protection de paiement seront appliqués après votre précédent`,
          remainingBalance: `Solde restant`,
        },
        greeting: {
          morning: 'Bonjour',
          afternoon: 'Bon après-midi',
          evening: 'Bonsoir',
        },
      },
    },
    Payments: {
      label: {
        title: `Gérer vos paiements`,
        description: `Choisissez une option ci-dessous`,
        options: {
          manualPayment: {
            title: `Effectuer un paiement manuel`,
            description:
              'Vous souhaitez rembourser votre prêt plus rapidement ? Vous pouvez effectuer un paiement supplémentaire ou rembourser votre prêt sans pénalités.',
          },
          overduePaymentDescription: `Choisissez de payer le montant en souffrance, de payer un montant différent ou de rembourser votre prêt sans pénalités.`,
          manualPaymentUserDescription: `Choisissez de payer le prochain paiement, d'effectuer un paiement supplémentaire ou de rembourser votre prêt sans pénalités.`,
          delayNextPayment: {
            title: `Retarder le prochain paiement`,
            description: `Vous avez besoin de plus de temps pour effectuer votre prochain paiement ? Vous pouvez le retarder d'un mois maximum, une fois tous les 12 mois.`,
          },
          changePaymentSchedule: {
            title: `Modification de l'échéancier de paiement`,
            description: `Sélectionnez la fréquence et la date de paiement qui vous conviennent le mieux.`,
          },
          changePaymentMethod: {
            title: `Modifier le mode de paiement`,
            description: `Mise à jour du compte bancaire utilisé pour les paiements futurs`,
          },
        },
        overdueMessage: `Votre paiement est en retard. Veuillez payer le montant dû dès que possible afin d'éviter des intérêts supplémentaires et un impact négatif sur votre cote de crédit.`,
      },
    },
    Documents: {
      title: 'Documents',
      subtitle: 'Ci-dessous, vous pouvez voir tous vos documents pour {{loanName}}',
      download: 'Télécharger',
      loanAgreement: 'Contrat de prêt',
      padAgreement: 'Entente de prélèvement automatique',
    },
    Reschedule: {
      title: `Modification de l'échéancier de paiement`,
      subtitle: '',
      change: {
        sectionTitle: `Votre changement interviendra à partir de votre prochain cycle de paiement, à compter du {{currentDate}}`,
        headerTitle: `Date de début du changement d'horaire`,
        buttonText: 'Confirmer et continuer',
        sectionContent: `Date de début du prochain cycle de facturation`,
      },
      delay: {
        sectionTitle: `Nous avons remarqué que vous avez programmé des paiements dans le cycle de facturation actuel :`,
        headerTitle: 'Paiements prévus',
        buttonText: 'Retarder le prochain paiement',
        sectionContent: 'Date de fin du cycle de facturation',
        footerText:
          'Si vous devez reporter le(s) paiement(s) de votre cycle de facturation actuel, veuillez cliquer sur « Retarder le prochain paiement ».',
      },
      EditPaymentSchedule: {
        title: `Modification de l'échéancier de paiement`,
        frequencyTitle: `Votre changement interviendra à partir de votre prochain cycle de paiement, à compter du {{startDate}}`,
        buttonNext: 'Prochain',
      },
      ConfirmSchedule: {
        title: 'Confirmer le nouvel échéancier de paiement',
        frequencyTitle: `Nous retirerons {{withdrawAmount}} toutes les {{duration}} à compter du {{startDate}} du compte {{paymentMethod}}.`,
        currentPayment: 'Paiement à venir dans ce cycle de paiement',
        upcomingPayment: 'Paiement à venir à partir du prochain cycle de paiement',
        buttonConfirm: 'Confirmer',
        notification:
          'Si votre date de paiement tombe un week-end ou un jour férié, il sera traité le jour ouvrable suivant.',
        padAgreement: {
          text: `J'accepte la mise à jour de`,
          link: `l'accord DPA.`,
        },
      },
      Success: {
        title: `Le mode de paiement a été mis à jour avec succès !`,
        buttonConfirm: 'Terminé',
      },
    },
    CreditRenewal: {
      title: 'Demande d’augmentation du montant du prêt',
      subtitle: 'Application sécurisée',
      eligibility: {
        title: 'Admissibilité',
        content: 'Découvrez si vous êtes admissible au renouvellement de crédit.',
      },
      howItWorks: {
        title: 'Comment ça fonctionne',
        content:
          'Apprenez-en davantage sur le processus de renouvellement de crédit et ses avantages.',
      },
      button: 'Renouveler le crédit',
    },
    Components: {
      alt: {
        infoCircleIcon: 'Icône d’information',
        questionCircleIcon: 'Icône de question',
        statusIcon: 'Icône de statut',
        chevronIcon: 'Icône de chevron',
        checkmarkIcon: 'Icône de coche',
        errorIcon: 'Icône d’erreur',
      },
      label: {
        viewDetails: 'Voir les détails',
        manage: 'Gérer',
        learnMore: 'En savoir plus',
        showDetails: 'Afficher les détails',
        hideDetails: 'Masquer les détails',
      },
      tooltip: {
        title: {
          accountStatus: 'Statut du compte',
          remainingBalance: 'Solde restant',
          loanId: 'Identifiant du prêt',
          loanTerm: 'Durée du prêt',
          paymentDetails: 'Détail du paiement',
          upcomingPaymentAmount: `Montant du paiement à venir`,
          upcomingPaymentDate: 'Date du paiement à venir',
        },
        content: {
          accountStatus: `<typography>Votre statut de compte sera l'un des suivants :<br/><br/><strong>En règle</strong> - Tous les paiements jusqu'à votre dernière date d'échéance ont été reçus.<br/><br/><strong>Paiement en retard</strong> - Un ou plusieurs paiements attendus ont été manqués.<br/><br/><strong>Clôturé</strong> - Votre prêt a été remboursé.<br/><br/>Si vous voyez le message "Votre paiement est en retard", veuillez payer votre montant impayé dès que possible ou contacter notre équipe de support pour planifier un paiement afin de remettre votre compte en règle.</typography>`,
          remainingBalance: `<typography>Le solde de votre prêt augmentera avec l'accumulation des intérêts quotidiens et diminuera à mesure que les paiements seront effectués. Si vous souhaitez rembourser votre prêt, voici le montant dû à ce jour.</typography>`,
          loanId: `<typography>Utilisez ce numéro comme votre numéro de compte lorsque vous essayez d'effectuer un paiement de facture via votre banque.</typography>`,
          loanTerm: `<typography>Si vous effectuez un paiement forfaitaire ou si vous avez un calendrier de remboursement plus fréquent qu'une fois par mois, la durée réelle de votre remboursement peut être plus courte que prévu initialement. Cependant, si vous manquez ou retardez des paiements, cela peut prolonger la durée de remboursement au-delà de la date de fin initialement prévue.</typography>`,
          upcomingPaymentAmount: `<typography>Il s'agit de votre montant de paiement régulier et récurrent, basé sur la fréquence de vos paiements et les détails de votre prêt.
<br/><br/>Ce montant inclura également les frais résultant des paiements manqués.</typography>`,
          upcomingPaymentDate: `<typography>Pour retarder votre prochain paiement, veuillez vous rendre sur la page des Paiements et sélectionner l'option <linkComponent>Retarder le prochain paiement</linkComponent>.</typography>`,
        },
        label: {
          principalRemaining: 'Principal restant',
          interestAccrued: 'Intérêts accumulés depuis le dernier paiement',
          feesAccrued: 'Frais accumulés depuis le dernier paiement',
          total: 'Total',
          principal: 'Principal',
          creditCharges: 'Frais de crédit',
          paymentProtectionPremium: 'Prime de protection de paiement',
          paymentProtectionPremiumTax: 'Taxe sur la prime de protection de paiement',
          nsfFees: 'Frais NSF',
          totalAmount: 'Total',
        },
        paymentDeferral: {
          title: `Report de paiement`,
          content: `<typography>Lorsque votre prêt est établi, nous déterminons ce que l'on appelle la date de fin du cycle de facturation. C'est la date à laquelle vous devez effectuer votre paiement mensuel en totalité.<br/><br/>Si le montant du paiement mensuel ne peut pas être réglé avant la fin du cycle de facturation, un report de paiement sera appliqué.<br/><br/>Vous pouvez reporter votre paiement une fois tous les 12 mois. Lorsque vous reportez votre paiement, le statut de votre prêt et votre rapport de crédit resteront en règle. Cependant, votre date d'échéance sera prolongée et les intérêts globaux augmenteront.</typography>`,
          label: {
            monthlyPaymentDetails: `Détails de votre paiement mensuel`,
            contractualDueDate: `Date d'échéance contractuelle`,
            monthlyPaymentAmount: `Montant du paiement mensuel`,
          },
        },
      },
      modal: {
        automaticPayments: {
          on: {
            description: 'Votre prochain paiement automatique est prévu pour {{date}}',
            status: 'Activé',
            linkText: null,
          },
          off: {
            description:
              'Vous ne voulez pas manquer votre paiement ? Activez les paiements automatiques maintenant !',
            status: 'Inactive',
            linkText: 'Activer',
          },
          title: {
            paymentAccount: 'Compte de paiement',
            paymentSchedule: 'Calendrier de paiement',
          },
          linkText: {
            delay: 'Reporter',
            change: 'Modifier',
          },
        },
        manualPayments: {
          title: `Effectuer un paiement manuel`,
          description: `Choisir une option de paiement`,
          buttonText: `Prochain`,
          paymentOptions: {
            outstandingAmount: {
              title: `Payer le montant impayé`,
              description: `Effectuez un paiement pour éviter des intérêts et frais supplémentaires`,
            },
            nextPayment: {
              title: `Effectuer mon prochain paiement`,
              description: `Payez votre prochain paiement aujourd'hui`,
            },
            extraAmount: {
              title: `Payer un montant supplémentaire`,
              description: `Remboursez votre prêt plus rapidement en effectuant des paiements supplémentaires`,
            },
            remainingBalance: {
              title: `Payer le solde de mon prêt`,
              description: `Remboursez votre prêt sans pénalités`,
            },
          },
          infoMessage: {
            extraAmount: `Veuillez noter que si vous avez opté pour des paiements automatiques, votre prochain paiement sera toujours traité comme prévu.`,
            nextPayment: `Votre prochain paiement prévu le {{nextPaymentDate}} sera traité aujourd'hui.`,
          },
        },
        confirmPayment: {
          title: `Confirmer le paiement`,
          buttonText: `Effectuer un paiement`,
          label: {
            paymentAmount: `Montant du paiement`,
            viewPaymentBreakdown: `Voir la répartition du paiement`,
            paymentDate: `Date de paiement`,
            transactionTime: `Cette transaction peut prendre jusqu’à 2 jours ouvrables avant d'apparaître dans votre compte bancaire.`,
            paymentAccount: `Compte de paiement`,
            consentCheckbox: `Je comprends que mon compte sera débité du montant choisi dans un délai d'un jour ouvrable.`,
            infoMessage: `Effectuer des paiements supplémentaires ne réduit pas le montant de vos paiements réguliers programmés. En revanche, les paiements supplémentaires vous aident à rembourser votre prêt plus rapidement et à payer moins d'intérêts au total.`,
            principal: `Capital`,
            creditCharges: `Frais de crédit`,
            paymentProtection: `Protection des paiements`,
            fees: `Frais`,
            totalAmount: `Montant total`,
          },
        },
        paymentSuccess: {
          title: `Paiement Réussi !`,
          description: `Merci. Votre paiement de {{amount}} a été effectué.`,
          infoMessage: `Le traitement du paiement peut prendre jusqu'à 2 jours ouvrables. Veuillez vous assurez que votre compte soit suffisamment approvisionné pendant cette période.`,
          buttonText: `Terminé`,
        },
        paymentDeferralSuccess: {
          title: `Votre prochain paiement a été mis à jour avec succès !`,
          buttonText: `Terminé`,
        },
        errorModal: {
          title: `Oups, quelque chose s'est mal passé`,
          description: `Nous travaillons à résoudre ce problème dès que possible. Cliquez sur le bouton « Réessayer » ou contactez-nous via le <liveChatLink>chat en direct Fig</liveChatLink> ou par <1>email au support Fig.</1>`,
          buttonText: `Réessayer`,
        },
        delayNextPayment: {
          title: `Modifier la date du prochain paiement`,
          description: `Votre prochain paiement est actuellement prévu pour le {{nextPaymentDate}}`,
          infoMessage: `Le choix d'une date après le {{nextPaymentDate}} entraînera <underline>un report de paiement</underline>, limité à une fois tous les 12 mois.`,
          paymentDeferral: `Report de paiement`,
          datePicker: {
            label: `Quand souhaitez-vous effectuer votre prochain paiement ?`,
          },
          buttonText: `Prochain`,
        },
        confirmNewPaymentDate: {
          title: `Confirmer la nouvelle date de paiement`,
          description: `Votre prochain paiement de {{nextPaymentAmount}} sera traité le {{newPaymentDate}} à partir de {{bankAccountName}}.`,
          warningMessage: `La date choisie entraîne un report de paiement, limité à une fois tous les 12 mois.`,
          infoMessage: `Si la date de votre paiement tombe une fin de semaine ou un jour férié, il sera traité le jour ouvrable suivant.`,
          checkboxLabel: `J'accepte le <linkComponent>formulaire de report de paiement</linkComponent> mis à jour.`,
          paymentDeferral: `Report de paiement`,
          paymentsOverview: {
            title: `Aperçu des 3 prochains paiements`,
            finalPaymentLabel: `Paiement final`,
          },
          buttonText: `Prochain`,
        },
      },
    },
  },
}

export default portalTranslation

import { Grid2 } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  menuDocumentsIcon,
  menuDocumentsIconActive,
  menuHomeIcon,
  menuHomeIconActive,
  menuMoreIcon,
  menuMoreIconActive,
  menuPaymentsIcon,
  menuPaymentsIconActive,
} from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import { MenuButtonItem } from 'src/portal/layout/menu/common'
import { MobileMenuButton } from 'src/portal/layout/menu/mobile/MobileMenuButton'
import { MobileMenuContainer } from 'src/portal/layout/menu/mobile/MobileMenuContainer'
import { MobileMenuModal } from 'src/portal/layout/menu/mobile/MobileMenuModal'

interface MobileMenuProps {
  optionalItemList: MenuButtonItem[]
}
const MobileMenu = ({ optionalItemList }: MobileMenuProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false)

  const menuButtonList = [
    {
      icon: menuHomeIcon,
      iconActive: menuHomeIconActive,
      label: t('Portal.Menu.label.home'),
      iconAlt: t('Portal.Menu.alt.homeIcon'),
      route: PortalRoutesEnum.PORTAL_INDEX,
    },
    {
      icon: menuPaymentsIcon,
      iconActive: menuPaymentsIconActive,
      label: t('Portal.Menu.label.payments'),
      iconAlt: t('Portal.Menu.alt.paymentsIcon'),
      route: PortalRoutesEnum.PORTAL_PAYMENTS,
    },
    {
      icon: menuDocumentsIcon,
      iconActive: menuDocumentsIconActive,
      label: t('Portal.Menu.label.documents'),
      iconAlt: t('Portal.Menu.alt.documentsIcon'),
      route: PortalRoutesEnum.PORTAL_DOCUMENTS,
    },
    {
      icon: menuMoreIcon,
      iconActive: menuMoreIconActive,
      label: t('Portal.Menu.label.more'),
      iconAlt: t('Portal.Menu.alt.moreIcon'),
      onClick: () => setIsMoreModalOpen(!isMoreModalOpen),
    },
  ]

  return (
    <div
      style={{ position: 'sticky', bottom: 0, width: '100%', backgroundColor: '#fff', zIndex: 10 }}
    >
      <MobileMenuContainer>
        {menuButtonList.map((menuButton, index) => {
          const isModalOpen = menuButton.label === t('Portal.Menu.label.more') && isMoreModalOpen

          return (
            <Grid2 key={index}>
              <MobileMenuButton
                icon={menuButton.icon}
                iconActive={menuButton.iconActive}
                iconAlt={menuButton.iconAlt}
                label={menuButton.label}
                isActive={
                  location.pathname.split('/').filter(Boolean).pop() === menuButton.route ||
                  isModalOpen
                }
                route={menuButton.route}
                onClick={menuButton.onClick}
              />
            </Grid2>
          )
        })}
      </MobileMenuContainer>
      <MobileMenuModal
        isOpen={isMoreModalOpen}
        optionalItemList={optionalItemList}
        setIsOpen={setIsMoreModalOpen}
      />
    </div>
  )
}

export default MobileMenu

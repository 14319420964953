import { Link } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { openChat } from 'src/components/Error/shared/PleaseReachOut'
import { genericErrorImg } from 'src/images'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'

export const ErrorModal = () => {
  const { t } = useTranslation()
  const { closeModal, closeAllModals } = useModalContext()

  return (
    <ActionModal
      icon={genericErrorImg}
      iconAlt={t('Portal.Components.alt.errorIcon')}
      title={t('Portal.Components.modal.errorModal.title')}
      description={
        <Trans
          i18nKey="Portal.Components.modal.errorModal.description"
          components={{
            liveChatLink: (
              <Link
                whiteSpace="nowrap"
                onClick={() => {
                  openChat()
                  closeAllModals()
                }}
              >
                {t('PleaseReachOut.liveChatLabel')}
              </Link>
            ),
            1: (
              <Link
                whiteSpace="nowrap"
                onClick={() => {
                  openChat()
                  closeAllModals()
                }}
              >
                {t('PleaseReachOut.contactFigLabel')}
              </Link>
            ),
          }}
        />
      }
      content={<></>}
      closeButtonHandler={closeAllModals}
      backButtonHandler={closeModal}
      nextButtonHandler={closeModal}
      buttonText={t('Portal.Components.modal.errorModal.buttonText')}
    />
  )
}

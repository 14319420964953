import { Grid2, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'

import { AlertColor, FigAlert } from 'src/components/common/FigAlert'

export const TypographyDetail = styled(Typography)({
  marginTop: '4px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  verticalAlign: 'middle',
  display: 'inline-flex',
})

/**
 * A functional component that renders a content column with two columns.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.left - The content to be displayed in the left section.
 * @param {string} props.right - The content to be displayed in the right section.
 *
 * @returns {JSX.Element} A JSX element containing a grid layout with two columns.
 *
 * @example
 * <ContentColumn left="Left Content" right="Right Content" />
 *
 * This will render a grid with "Left Content" aligned to the left and "Right Content" aligned to the right.
 */
export function ContentColumn({ left, right }: { left: string | number; right: string }) {
  return (
    <Grid2 container marginBottom="8px">
      <Grid2 size={8}>
        <TypographyDetail>{left}</TypographyDetail>
      </Grid2>
      <Grid2 size={4} display="flex" justifyContent="flex-end">
        <TypographyDetail textAlign="right">{right}</TypographyDetail>
      </Grid2>
    </Grid2>
  )
}

export function FigAlertError() {
  return (
    <FigAlert
      color={AlertColor.ERROR}
      icon={<ReportOutlinedIcon />}
      title="Oops! Something went wrong"
      content="Please try again later."
      alertSx={{ position: 'static', width: '100%' }}
      contentSx={{ maxWidth: 'inherit' }}
    />
  )
}

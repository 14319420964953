import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PortalRoutesEnum } from 'src/portal/common'
import { PortalLink } from 'src/portal/components/PortalLink'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StatusIconEnum } from 'src/portal/components/types'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'

interface PaymentProtectionContainerProps {
  paymentProtectionActive: boolean
}
export const PaymentProtectionContainer = ({
  paymentProtectionActive,
}: PaymentProtectionContainerProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!paymentProtectionActive) {
    return null
  }

  const navigateToPaymentProtectionPage = () => {
    navigate(PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION)
  }

  return (
    <SpacedRow>
      <Grid2>
        <StatusBadge
          iconVariant={StatusIconEnum.ACTIVE}
          label={t('Portal.Home.label.summary.paymentProtectionActive')}
        />
      </Grid2>
      <Grid2>
        <PortalLink
          text={t('Portal.Components.label.viewDetails')}
          onClickHandler={navigateToPaymentProtectionPage}
          iconDirection="right"
        />
      </Grid2>
    </SpacedRow>
  )
}

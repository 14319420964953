/**
 * This file is part of the connect a bank account flow.
 * It displays the flinks iframe which is used to retrieve the users
 * banking information.
 */
import Fade from '@mui/material/Fade'
import { styled } from '@mui/material/styles'
import { captureMessage } from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import CheckPayment from 'src/portal/pages/flinks/components/FlinksCheck'
import Error from 'src/portal/pages/flinks/components/FlinksError'
import Success from 'src/portal/pages/flinks/components/FlinksSuccess'
import { ConnectionStepEnum } from 'src/portal/pages/flinks/types'
import { DivRoot } from 'src/portal/pages/reschedule/styles'
import { FeatureFlags, PartnersEnum } from 'src/types'
import { captureExceptionHelper } from 'src/utils'
import { ALTERNA_FLINKS_IFRAME_SRC, FLINKS_IFRAME_SRC, IS_PRODUCTION } from 'src/utils/constants'

const FLINKS_DAYS_365 = 'Days365'
const FLINKS_DAYS_90 = 'Days90'

const IframeStyled = styled('iframe')(({ theme }) => ({
  border: 'none',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 25,
  },
}))

const ALERT_EVENTS = [
  'MAXIMUM_RETRY_REACHED',
  'SESSION_NONEXISTENT',
  'SESSION_EXPIRED',
  'APP_OFFLINE',
  'APP_ONLINE',
]

const REDIRECT_EVENT = 'REDIRECT'
const COMPONENT_CLOSE_SESSION = 'COMPONENT_CLOSE_SESSION'

interface FlinksUrlParams {
  consentEnable: string
  customerName: string
  closeEnable: string
  accountSelectorEnable: string
  accountSelectorCurrency: string
  windowContext: string
  eftEligibleRatio: string
  maximumRetry: string
  backEnable: string
  tag: string
  demo: string
}

const defaultFlinksParams: FlinksUrlParams = {
  consentEnable: 'true',
  customerName: 'Fig Financial Inc.',
  closeEnable: 'true',
  accountSelectorEnable: 'true',
  accountSelectorCurrency: 'cad',
  windowContext: 'parent',
  eftEligibleRatio: '0.8',
  maximumRetry: '3',
  backEnable: 'true',
  tag: '',
  demo: !IS_PRODUCTION + '',
}

export default function ConnectBankAccount() {
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { flinksDaysOfTransaction } = useFlags<FeatureFlags>()
  const [step, setStep] = useState('')
  const [flinksLoginId, setFlinksLoginId] = useState('')

  const lastFlinksEvent = useRef('')

  const { loanDetailData } = usePortalContext()
  const { portalApiFlinksSetAccount, portalApiFlinksTrack } = usePortalApi()
  const borrowerId = loanDetailData?.borrower_id
  const applicationId = loanDetailData?.application_id

  const { mutate: trackFlinksMutation } = useMutation(
    ['trackFlinks', applicationId],
    portalApiFlinksTrack,
    {
      useErrorBoundary: false,
      onError: (e: any) => {
        captureMessage('Error calling flinks track event', { level: 'warning', extra: { e } })
      },
    },
  )

  const { mutate: setFlinksAccountMutation } = useMutation(
    ['setFlinksAccount', applicationId],
    portalApiFlinksSetAccount,
    {
      useErrorBoundary: false,
      onSuccess: () => {
        setStep(ConnectionStepEnum.FLINKS_PAYMENT_CHECK)
      },
      onError: (e: any) => {
        captureExceptionHelper('Error setting flinks account', e)
        setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
      },
    },
  )

  useEffect(() => {
    const listener = function (
      e: MessageEvent<{ step: string; loginId?: string; accountId?: string }>,
    ) {
      const currentEvent = e.data.step
      if (ALERT_EVENTS.includes(currentEvent)) {
        captureMessage(`Flinks event received: ${currentEvent}`, 'warning')
      }
      if (currentEvent && lastFlinksEvent.current !== currentEvent) {
        // ^ remove multiple duplicate messages in a row
        trackFlinksMutation({
          event_name: currentEvent,
          borrower_id: borrowerId!,
          application_id: applicationId!,
        })
      }
      lastFlinksEvent.current = currentEvent

      if (currentEvent === REDIRECT_EVENT) {
        const { loginId, accountId } = e.data
        setFlinksLoginId(loginId!)
        setFlinksAccountMutation({
          login_id: loginId!,
          account_id: accountId!,
          borrower_id: borrowerId!,
          application_id: applicationId!,
        })
      }

      if (currentEvent === COMPONENT_CLOSE_SESSION) {
        setStep(ConnectionStepEnum.PAYMENT_METHOD_SELECT)
      }
    }
    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [])

  // The Flinks iFrame takes longer than the onLoad call to render, this adds
  // an additional delay to help ensure it's rendered before revealing it.
  const iframeLoadDelay = 2000
  const iframeLoaded = () => {
    setTimeout(function () {
      setIsLoading(false)
    }, iframeLoadDelay)
  }

  let iframeSrc: string = FLINKS_IFRAME_SRC

  const daysOfTransactions: string = flinksDaysOfTransaction ? FLINKS_DAYS_365 : FLINKS_DAYS_90
  if (loanDetailData?.partner_name === PartnersEnum.ALTERNA) {
    iframeSrc = ALTERNA_FLINKS_IFRAME_SRC
  }
  const urlParams: string = new URLSearchParams({
    ...defaultFlinksParams,
    tag: applicationId!,
    language: i18n.resolvedLanguage ?? i18n.language,
    daysOfTransactions: daysOfTransactions,
  }).toString()

  const flinksUrl = `${iframeSrc}?${urlParams}`

  if (step === ConnectionStepEnum.FLINKS_PAYMENT_CHECK) {
    return <CheckPayment flinksLoginId={flinksLoginId} setStep={setStep} />
  }

  if (step === ConnectionStepEnum.BANK_CONNECT_ERROR) {
    return <Error setStep={setStep} />
  }

  if (step === ConnectionStepEnum.DECLINED) {
    return <Error setStep={setStep} />
  }

  if (step === ConnectionStepEnum.BANK_SUCCESS) {
    return <Success />
  }

  return (
    <>
      {isLoading && <LoadingAnimation />}
      <Fade in={!isLoading} timeout={500}>
        <DivRoot sx={{ display: isLoading ? 'none' : 'inherit' }}>
          <IframeStyled
            id="purple-iframe"
            title="connect to bank"
            className="flinksconnect"
            height="640"
            width="100%"
            onLoad={iframeLoaded}
            src={flinksUrl}
            style={{
              display: 'block',
            }}
          ></IframeStyled>
        </DivRoot>
      </Fade>
    </>
  )
}

export const ConnectBankAccountModal = () => {
  const { closeAllModals } = useModalContext()

  return <ActionModal content={<ConnectBankAccount />} closeButtonHandler={closeAllModals} />
}

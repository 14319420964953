import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Typography } from '@mui/material'

import PortalTheme from 'src/themes/portal'

interface InfoMessageProps {
  content: string | JSX.Element
}
export const InfoMessage = ({ content }: InfoMessageProps) => {
  return (
    <Alert
      sx={{
        my: '16px',
        backgroundColor: PortalTheme.color.brand5,
      }}
      severity="success"
      icon={<InfoOutlinedIcon sx={{ color: PortalTheme.color.grey10 }} />}
    >
      <Typography variant="body2" color={PortalTheme.color.grey10}>
        {content}
      </Typography>
    </Alert>
  )
}

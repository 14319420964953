import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { captureMessage } from '@sentry/react'

import { DivRoot } from 'src/components/common/DivRoot'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { genericErrorImg, listItem1, listItem2, listItem3 } from 'src/images'
import { StepsEnum } from 'src/types'
import { onfidoPermssionsCopyClicked, parseUrl } from 'src/utils'
import theme from 'src/themes'
import { GridImage } from 'src/components/common/GridImage'
import { useOriginationContext } from 'src/hooks'

const Image = styled('img')({
  width: '80px',
  height: '80px',
})

const ImageGrid = styled(GridImage)({
  marginBottom: '32px',
})

const Title = styled(Typography)({
  ...theme.typography.h1,
  textAlign: 'center',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
})

const Content = styled(Typography)({
  ...theme.typography.body2,
  lineHeight: '24px',
  marginBottom: '32px',
})

const ButtonGrid = styled(Grid)({
  gap: '18px',
  display: 'flex',
  flexDirection: 'column',
})

function ListItemGrid({ img, content }: { content: string; img: string }) {
  return (
    <Grid container my="8px">
      <Grid item xs={1}>
        <Typography>
          <img src={img} alt="" />
        </Typography>
      </Grid>

      <Grid item xs={11}>
        <Typography variant="body2">{content}</Typography>
      </Grid>
    </Grid>
  )
}

export default function PermissionOnfido() {
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation()
  const { jwt, query } = parseUrl()
  const { applicationId, borrowerId, bootstrapInfo } = useOriginationContext()

  useEffect(() => {
    captureMessage('User navigated to the permissions onfido page', {
      level: 'warning',
      extra: {
        applicationId,
        borrowerId,
        partnerId: bootstrapInfo?.partner?.partner_id,
        partnerName: bootstrapInfo?.partner?.name,
      },
    })
  }, [])

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000)
    }
  }, [isCopied])

  const appUrl = `${window.location.origin}/${jwt}/${StepsEnum.LOAN_AMOUNT}${query}`

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12}>
          <ImageGrid item xs={12}>
            <Image src={genericErrorImg} alt={t('GenericError.alt')} />
          </ImageGrid>

          <Grid item xs={12}>
            <Title data-testid="errorTitle">{t('Onfido.permissionsIssue.title')}</Title>
          </Grid>

          <Grid item xs={12}>
            <Content data-testid="errorsubTitle">{t('Onfido.permissionsIssue.subtitle')}</Content>
          </Grid>

          <Grid item xs={12} my="8px">
            <Typography variant="h3">{t('Onfido.permissionsIssue.listTitle')}</Typography>
          </Grid>

          <Grid item xs={12} mt="16px">
            <ListItemGrid img={listItem1} content={t('Onfido.permissionsIssue.first')} />
            <ListItemGrid img={listItem2} content={t('Onfido.permissionsIssue.second')} />
            <ListItemGrid img={listItem3} content={t('Onfido.permissionsIssue.third')} />
          </Grid>
          <ButtonGrid item xs={12} mt="16px">
            <CopyToClipboard
              text={appUrl}
              onCopy={() => {
                onfidoPermssionsCopyClicked(StepsEnum.KYC_PERMISSIONS_ONFIDO_ERROR, appUrl)
                setIsCopied(true)
              }}
            >
              <PrimaryCTA
                buttonText={
                  isCopied
                    ? t('Onfido.permissionsIssue.copyButtonClicked')
                    : t('Onfido.permissionsIssue.copyButton')
                }
                disabled={isCopied}
              />
            </CopyToClipboard>
          </ButtonGrid>
        </Grid>
      </Grid>
    </DivRoot>
  )
}

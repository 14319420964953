import { useState } from 'react'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { LoanStatusEnum } from 'src/portal/common'
import { RoundedBox } from 'src/portal/components/RoundedBox'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ShowDetailsButton } from 'src/portal/pages/home/components/ShowDetailsButton'
import { LoanDetailsContainer } from 'src/portal/pages/home/summary/containers/LoanDetailsContainer'
import { LoanStatusContainer } from 'src/portal/pages/home/summary/containers/LoanStatusContainer'
import { OutstandingPaymentContainer } from 'src/portal/pages/home/summary/containers/OutstandingPaymentContainer'
import { PaymentDetailsContainer } from 'src/portal/pages/home/summary/containers/PaymentDetailsContainer'
import { TotalAmountContainer } from 'src/portal/pages/home/summary/containers/TotalAmountContainer'
import { ClosedLoanMessage } from 'src/portal/pages/payments/components/ClosedLoanMessage'
import { OverdueMessage } from 'src/portal/pages/payments/components/OverdueMessage'

const Summary = () => {
  const { loanDetailData, paymentDetailData } = usePortalContext()
  const [loanDetailsExpanded, setLoanDetailsExpanded] = useState(false)

  if (!loanDetailData || !paymentDetailData) {
    return (
      <>
        <LoadingAnimation />
      </>
    )
  }

  const totalAmount = loanDetailData.loan_pay_off_amount
  const loanStatus = loanDetailData.loan_status as LoanStatusEnum
  const feesAccrued = loanDetailData.fees_accrued_since_last_payment
  const interestAccrued = loanDetailData.interest_accrued_since_last_payment
  const principalRemaining =
    loanDetailData.loan_pay_off_amount -
    loanDetailData.fees_accrued_since_last_payment -
    loanDetailData.interest_accrued_since_last_payment

  const loanName = loanDetailData.name
  const loanAmount = loanDetailData.loan_amount
  const interestRate = loanDetailData.interest_rate
  const loanTerm = loanDetailData.loan_term
  const loanStartDate = loanDetailData.loan_disbursal_date ?? ''
  const loanClosedDate = loanDetailData.loan_closed_date ?? null

  return (
    <>
      {loanStatus === LoanStatusEnum.OVERDUE && <OverdueMessage />}

      <RoundedBox>
        <TotalAmountContainer
          {...{ totalAmount, feesAccrued, interestAccrued, principalRemaining }}
        />
        <LoanStatusContainer loanStatus={loanStatus} />
        {loanStatus === LoanStatusEnum.OVERDUE && (
          <OutstandingPaymentContainer paymentDetailData={paymentDetailData} />
        )}

        <StretchDivider />

        {loanStatus === LoanStatusEnum.CLOSED ? (
          <ClosedLoanMessage />
        ) : (
          <PaymentDetailsContainer loanDetailData={loanDetailData} />
        )}
        {loanDetailsExpanded && (
          <>
            <StretchDivider />
            <LoanDetailsContainer
              {...{ loanName, loanAmount, loanTerm, interestRate, loanStartDate, loanClosedDate }}
            />
          </>
        )}
        <ShowDetailsButton onClickHandler={() => setLoanDetailsExpanded(!loanDetailsExpanded)} />
      </RoundedBox>
    </>
  )
}
export default Summary

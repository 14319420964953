import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Typography } from '@mui/material'
import React from 'react'

import PortalTheme from 'src/themes/portal'

interface LinkProps {
  text: string
  onClickHandler: () => void
  iconDirection?: 'up' | 'right' | 'down'
}

const iconStyle = {
  marginLeft: '4px',
  verticalAlign: 'middle',
  display: 'inline',
  color: PortalTheme.color.brand1,
}

const ArrowIcon: React.FC<{ iconDirection?: 'up' | 'right' | 'down' }> = ({ iconDirection }) => {
  switch (iconDirection) {
    case 'up':
      return <ExpandLess style={iconStyle} />
    case 'down':
      return <ExpandMore style={iconStyle} />
    case 'right':
      return <ChevronRightIcon style={iconStyle} />
    default:
      return null
  }
}

/**
 * A functional component that renders a clickable link with text and an arrow icon.
 *
 * @param {string} text - The text to display inside the link.
 * @param {function} onClickHandler - The function to call when the link is clicked.
 *
 * @returns {JSX.Element} The rendered link component.
 */
export const PortalLink: React.FC<LinkProps> = ({ text, onClickHandler, iconDirection }) => {
  return (
    <Box onClick={onClickHandler} alignItems="center" sx={{ cursor: 'pointer' }}>
      <Typography
        display="inline"
        variant="body3"
        fontWeight={500}
        color={PortalTheme.color.brand1}
      >
        {text}
      </Typography>
      <ArrowIcon iconDirection={iconDirection} />
    </Box>
  )
}

import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { connectionSuccessImg } from 'src/images'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'

const PaymentSuccessModalContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <InfoMessage content={t('Portal.Components.modal.paymentSuccess.infoMessage')} />
    </>
  )
}

export const PaymentSuccessModal = () => {
  const { t } = useTranslation()
  const { closeAllModals } = useModalContext()
  const { paymentAmount } = useModalContext()
  const { currencyFormat } = useLocalizedFormatters()
  return (
    <ActionModal
      title={t('Portal.Components.modal.paymentSuccess.title')}
      description={t('Portal.Components.modal.paymentSuccess.description', {
        amount: currencyFormat(paymentAmount),
      })}
      icon={connectionSuccessImg}
      content={<PaymentSuccessModalContent />}
      buttonText={t('Portal.Components.modal.paymentSuccess.buttonText')}
      closeButtonHandler={closeAllModals}
      nextButtonHandler={closeAllModals}
    />
  )
}

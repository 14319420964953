import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { FlinksPaymentCheckResponseSchema } from 'src/portal/api/api.schemas'
import { ENVIRONMENT } from 'src/utils/constants'
import { ConnectionStepEnum } from 'src/portal/pages/flinks/types'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'

interface FlinksPaymentCheckProps {
  flinksLoginId: string
  setStep: (value: string) => void
}

export default function FlinksPaymentCheck({ flinksLoginId, setStep }: FlinksPaymentCheckProps) {
  const { t } = useTranslation()
  const [failureCount, setFailureCount] = useState(0)
  const { portalApiFlinksPaymentCheck } = usePortalApi()
  const { loanDetailData } = usePortalContext()
  // Poll for 5 minutes to see if the flinks webhook, and q2 payment saving
  // and linking are done
  const MAX_FAILURES = 60 * 5

  useQuery<FlinksPaymentCheckResponseSchema>(
    ['flinksPaymentCheck', flinksLoginId],
    () =>
      portalApiFlinksPaymentCheck({
        login_id: flinksLoginId,
        application_id: loanDetailData?.application_id || '',
      }),
    {
      refetchInterval: failureCount <= MAX_FAILURES ? 1000 : false,
      onSuccess: (result: FlinksPaymentCheckResponseSchema): void => {
        if (result.is_declined) {
          setStep(ConnectionStepEnum.DECLINED)
        } else if (!result.is_pii_match) {
          setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        } else if (!result.is_bank_account_valid && result.is_failed) {
          setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        } else if (result.is_failed) {
          setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        } else if (result.is_complete) {
          setStep(ConnectionStepEnum.BANK_SUCCESS)
        } else {
          if (failureCount < MAX_FAILURES) {
            setFailureCount(failureCount + 1)
          } else {
            Sentry.captureMessage(
              `Flinks bank connect did not complete within 5 minutes ${ENVIRONMENT}`,
              'warning',
            )
            setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
          }
        }
      },
    },
  )

  return <LoadingAnimation subtitle={t('FlinksPaymentCheck.loading')} />
}

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { connectionSuccessImg, lightbulbIconBackground } from 'src/images'
import { PortalModalsEnum } from 'src/portal/common'
import { InfoActionRow } from 'src/portal/components/display/InfoActionRow'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import { StatusIconEnum } from 'src/portal/components/types'
import { useLocale } from 'src/portal/hooks/useLocale'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'
import PortalTheme from 'src/themes/portal'

const AutomaticPaymentsDetailContent = () => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { currencyFormat, dateFormatCustom } = useLocalizedFormatters()
  const { openModal } = useModalContext()
  const { isFrenchLocale } = useLocale()
  if (!loanDetailData) {
    return null
  }

  const automaticPaymentsOn = !!loanDetailData.automated_payment_setup?.id

  const amountDue = currencyFormat(loanDetailData.due_amount)
  const paymentScheduleText = isFrenchLocale
    ? loanDetailData.payment_schedule_french.toLowerCase()
    : loanDetailData.payment_schedule.toLowerCase()

  const rows = [
    {
      title: t(
        automaticPaymentsOn
          ? 'Portal.Home.label.summary.automaticPaymentsOn'
          : 'Portal.Home.label.summary.automaticPaymentsOff',
      ),
      content: (
        <StatusBadge
          iconVariant={automaticPaymentsOn ? StatusIconEnum.ACTIVE : StatusIconEnum.ALERT}
          label={t(
            automaticPaymentsOn
              ? 'Portal.Components.modal.automaticPayments.on.status'
              : 'Portal.Components.modal.automaticPayments.off.status',
          )}
        />
      ),
      linkText: null,
    },
    {
      title: t('Portal.Home.label.summary.upcomingPaymentDate'),
      content: (
        <Typography variant="body2" color={PortalTheme.color.grey7}>
          {dateFormatCustom(new Date(loanDetailData.due_date), t('Portal.common.dateFormat'))}
        </Typography>
      ),
      linkText: t('Portal.Components.modal.automaticPayments.linkText.delay'),
      onClickHandler: () => openModal(PortalModalsEnum.DELAY_NEXT_PAYMENT_MODAL),
    },
    {
      title: t('Portal.Components.modal.automaticPayments.title.paymentAccount'),
      content: (
        <Typography variant="body2" color={PortalTheme.color.grey7}>
          {loanDetailData.payment_method}
        </Typography>
      ),
      linkText: t('Portal.Components.modal.automaticPayments.linkText.change'),
      onClickHandler: () => openModal(PortalModalsEnum.CHANGE_PAYMENT_METHOD_START),
    },
    {
      title: t('Portal.Components.modal.automaticPayments.title.paymentSchedule'),
      content: (
        <Typography variant="body2" color={PortalTheme.color.grey7}>
          {`${amountDue} ${paymentScheduleText}`}
        </Typography>
      ),
      linkText: t('Portal.Components.modal.automaticPayments.linkText.change'),
      onClickHandler: () => openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_START),
    },
  ]

  return (
    <>
      {rows.map(({ title, content, linkText, onClickHandler }) => (
        <InfoActionRow
          key={title}
          title={title}
          content={content}
          linkText={linkText}
          onClickHandler={onClickHandler}
        />
      ))}
    </>
  )
}

export const AutomaticPaymentsDetailModal = () => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { dateFormatCustom } = useLocalizedFormatters()
  const { closeModal } = useModalContext()

  if (!loanDetailData) {
    return null
  }
  const upcomingPaymentDate = loanDetailData.due_date ?? ''

  const date = dateFormatCustom(new Date(upcomingPaymentDate), t('Portal.common.dateFormat'))
  const description = t(
    loanDetailData.automated_payment_setup?.id
      ? 'Portal.Components.modal.automaticPayments.on.description'
      : 'Portal.Components.modal.automaticPayments.off.description',
    {
      date,
    },
  )

  const icon = loanDetailData.automated_payment_setup?.id
    ? connectionSuccessImg
    : lightbulbIconBackground

  return (
    <ActionModal
      icon={icon}
      iconAlt={t('Portal.Components.alt.checkmarkIcon')}
      description={description}
      content={<AutomaticPaymentsDetailContent />}
      closeButtonHandler={closeModal}
    />
  )
}

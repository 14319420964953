import { Checkbox, FormControlLabel, Typography } from '@mui/material'

import PortalTheme from 'src/themes/portal'

interface CheckboxWithLabelProps {
  content: string | JSX.Element
  checkboxChecked: boolean
  checkBoxHandler: () => void
}

export const CheckboxWithLabel = ({
  content,
  checkboxChecked,
  checkBoxHandler,
}: CheckboxWithLabelProps) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checkboxChecked} onChange={checkBoxHandler} />}
      label={
        <Typography pt="16px" variant="body2" color={PortalTheme.color.grey6} display={'inline'}>
          {content}
        </Typography>
      }
    />
  )
}

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { LabelText } from 'src/portal/components/LabelText'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import PortalTheme from 'src/themes/portal'

export const OutstandingAmountContainer = ({
  outstandingAmount,
}: {
  outstandingAmount: number
}) => {
  const { currencyFormat } = useLocalizedFormatters()
  const { t } = useTranslation()

  return (
    <SpacedRow>
      <LabelText text={t('Portal.Home.label.summary.outstandingAmount')} />
      <Typography variant="body2" color={PortalTheme.color.grey9} py="6px">
        {currencyFormat(outstandingAmount)}
      </Typography>
    </SpacedRow>
  )
}

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { RoundedBox } from 'src/portal/components/RoundedBox'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import PortalTheme from 'src/themes/portal'

interface RepaymentEntry {
  amount: number
  date: Date
}

interface NextPaymentsOverviewBoxProps {
  repaymentEntries: RepaymentEntry[]
  finalPaymentDate: Date
}

const FinalPaymentRow = ({ finalPaymentDate }: { finalPaymentDate: Date }) => {
  const { t } = useTranslation()
  const { dateFormatCustom } = useLocalizedFormatters()

  return (
    <>
      <StretchDivider />
      <SpacedRow>
        <Typography variant="body2" color="textSecondary">
          {t('Portal.Components.modal.confirmNewPaymentDate.paymentsOverview.finalPaymentLabel')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {dateFormatCustom(finalPaymentDate, t('Portal.common.dateFormat'))}
        </Typography>
      </SpacedRow>
    </>
  )
}

export const NextPaymentsOverviewBox = ({
  repaymentEntries,
  finalPaymentDate,
}: NextPaymentsOverviewBoxProps) => {
  const { t } = useTranslation()
  const { dateFormatCustom, currencyFormat } = useLocalizedFormatters()

  return (
    <RoundedBox>
      <Typography
        variant="body2"
        fontWeight={500}
        color={PortalTheme.color.grey7}
        sx={{ mb: '16px' }}
      >
        {t('Portal.Components.modal.confirmNewPaymentDate.paymentsOverview.title')}
      </Typography>

      {repaymentEntries.map((entry, index) => (
        <div key={index} style={{ marginBottom: '16px' }}>
          <SpacedRow>
            <Typography variant="body2" color="textSecondary">
              {currencyFormat(entry.amount)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {dateFormatCustom(entry.date, t('Portal.common.dateFormat'))}
            </Typography>
          </SpacedRow>

          {index === repaymentEntries.length - 1 && (
            <FinalPaymentRow finalPaymentDate={finalPaymentDate} />
          )}
        </div>
      ))}
    </RoundedBox>
  )
}

import { Link } from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PaymentDeferralProcessSelectedDateResponseSchema } from 'src/portal/api/api.schemas'
import { PortalModalsEnum } from 'src/portal/common'
import { CheckboxWithLabel } from 'src/portal/components/CheckboxWithLabel'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import { NextPaymentsOverviewBox } from 'src/portal/components/modals/DelayNextPayment/NextPaymentsOverviewBox'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { WarningMessage } from 'src/portal/components/WarningMessage'
import { useLocale } from 'src/portal/hooks/useLocale'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { PaymentDeferralContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import PortalTheme from 'src/themes/portal'
import { captureExceptionHelper } from 'src/utils'

interface ConfirmNewPaymentDateModalContentProps {
  boxChecked: boolean
  setBoxChecked: (checked: boolean) => void
  paymentScheduleData: PaymentDeferralProcessSelectedDateResponseSchema | undefined
  deferralFormData: any
}

const ConfirmNewPaymentDateModalContent = ({
  boxChecked,
  setBoxChecked,
  paymentScheduleData,
  deferralFormData,
}: ConfirmNewPaymentDateModalContentProps) => {
  const { t } = useTranslation()
  const { loanDetailData, loanRepaymentScheduleData } = usePortalContext()
  const { isFrenchLocale } = useLocale()

  if (!loanDetailData || !loanRepaymentScheduleData || !paymentScheduleData) {
    return <LoadingAnimation />
  }
  const contractualDueDateText = isFrenchLocale
    ? loanDetailData.payment_schedule_french
    : loanDetailData.payment_schedule

  const monthlyPaymentAmount = loanDetailData.due_amount
  const lastPayment = loanRepaymentScheduleData[loanRepaymentScheduleData.length - 1]
  const finalPaymentDate = new Date(lastPayment.due_date)

  const isOutCycle = paymentScheduleData.is_in_cycle_or_outcycle === 'outCycle'
  const shouldShowCheckboxAndAgreementLink = isOutCycle && deferralFormData.form_url

  const repaymentEntries = [
    {
      amount: paymentScheduleData.payment_schedule_amount_one,
      date: new Date(paymentScheduleData.payment_schedule_date_one),
    },
    {
      amount: paymentScheduleData.payment_schedule_amount_two,
      date: new Date(paymentScheduleData.payment_schedule_date_two),
    },
    {
      amount: paymentScheduleData.payment_schedule_amount_three,
      date: new Date(paymentScheduleData.payment_schedule_date_three),
    },
  ]

  return (
    <>
      <WarningMessage
        content={
          <Trans
            i18nKey="Portal.Components.modal.confirmNewPaymentDate.warningMessage"
            components={{
              underline: (
                <TooltipContainer
                  title={t('Portal.Components.tooltip.paymentDeferral.title')}
                  linkElement={
                    <span style={{ textDecoration: 'underline' }}>
                      {t('Portal.Components.modal.confirmNewPaymentDate.paymentDeferral')}
                    </span>
                  }
                  content={
                    <PaymentDeferralContent
                      contractualDueDate={contractualDueDateText}
                      monthlyPaymentAmount={monthlyPaymentAmount}
                    />
                  }
                />
              ),
            }}
          />
        }
      />
      <NextPaymentsOverviewBox
        repaymentEntries={repaymentEntries}
        finalPaymentDate={finalPaymentDate}
      />

      <InfoMessage content={t('Portal.Components.modal.confirmNewPaymentDate.infoMessage')} />
      {shouldShowCheckboxAndAgreementLink && (
        <CheckboxWithLabel
          content={
            <Trans
              i18nKey="Portal.Components.modal.confirmNewPaymentDate.checkboxLabel"
              components={{
                linkComponent: (
                  <Link
                    href={deferralFormData.form_url}
                    sx={{ color: PortalTheme.color.brand1 }}
                    color={PortalTheme.color.brand1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    payment deferral form
                  </Link>
                ),
              }}
            />
          }
          checkBoxHandler={() => setBoxChecked(!boxChecked)}
          checkboxChecked={boxChecked}
        />
      )}
    </>
  )
}

export const ConfirmNewPaymentDateModal = () => {
  const { t } = useTranslation()
  const { closeModal, closeAllModals, delayedNextPaymentDate, openModal } = useModalContext()
  const { loanDetailData } = usePortalContext()
  const [boxChecked, setBoxChecked] = useState(false)
  const {
    portalApiPaymentDeferralProcessSelectedDate,
    portalApiPaymentDeferralComplete,
    portalApiPaymentDeferralGetDeferralForm,
  } = usePortalApi()
  const { dateFormatCustom, currencyFormat } = useLocalizedFormatters()

  const { isLoading: isDeferralDataLoading, data: paymentScheduleData } = useQuery(
    ['portalApiPaymentDeferralProcessSelectedDate', loanDetailData?.id, delayedNextPaymentDate],
    async () => {
      if (!delayedNextPaymentDate || !loanDetailData?.id) {
        return Promise.reject('Missing required parameters')
      }
      return await portalApiPaymentDeferralProcessSelectedDate(loanDetailData.id, {
        repayment_start_date: delayedNextPaymentDate.toISOString().split('T')[0], // convert to YYYY-MM-DD
      })
    },
    {
      enabled: !!(loanDetailData?.id && delayedNextPaymentDate),
      useErrorBoundary: false,
      onError: error => {
        captureExceptionHelper(`Error delaying next payment: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
      },
    },
  )

  const { isLoading: isDeferralFormLoading, data: deferralFormData } = useQuery(
    ['portalApiPaymentDeferralGetDeferralForm', loanDetailData?.id],
    async () => {
      if (!loanDetailData?.id) {
        return Promise.reject('Missing required parameters')
      }
      return await portalApiPaymentDeferralGetDeferralForm(loanDetailData.id)
    },
    {
      enabled: !!loanDetailData?.id && paymentScheduleData?.is_in_cycle_or_outcycle === 'outCycle', // a new agreement form is only generated in outcycle cases
      onError: error => {
        captureExceptionHelper(`Error fetching payment deferral form: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
      },
    },
  )

  const { isLoading: isProcessingDeferral, mutate: completeDeferral } = useMutation(
    ['portalApiPaymentDeferralComplete', loanDetailData?.id, delayedNextPaymentDate],
    async () => {
      if (!delayedNextPaymentDate || !loanDetailData?.id) {
        return Promise.reject('Missing required parameters')
      }
      return portalApiPaymentDeferralComplete(loanDetailData.id, {
        selected_payment_date: delayedNextPaymentDate.toISOString().split('T')[0],
      })
    },
    {
      useErrorBoundary: false,
      onSuccess: _ => {
        openModal(PortalModalsEnum.PAYMENT_DEFERRAL_SUCCESS_MODAL)
      },
      onError: error => {
        captureExceptionHelper(`Error delaying next payment: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
      },
    },
  )

  return (
    <ActionModal
      title={t('Portal.Components.modal.confirmNewPaymentDate.title')}
      description={t('Portal.Components.modal.confirmNewPaymentDate.description', {
        nextPaymentAmount: currencyFormat(paymentScheduleData?.next_payment_amount || 0),
        bankAccountName: paymentScheduleData?.loan_payment_mode,
        newPaymentDate: dateFormatCustom(
          paymentScheduleData?.next_working_payment_date
            ? new Date(paymentScheduleData.next_working_payment_date)
            : new Date(),
          t('Portal.common.dateFormat'),
        ),
      })}
      content={
        isDeferralDataLoading || isProcessingDeferral || isDeferralFormLoading ? (
          <LoadingAnimation />
        ) : (
          <ConfirmNewPaymentDateModalContent
            boxChecked={boxChecked}
            setBoxChecked={setBoxChecked}
            paymentScheduleData={paymentScheduleData}
            deferralFormData={deferralFormData}
          />
        )
      }
      buttonText={t('Portal.Components.modal.confirmNewPaymentDate.buttonText')}
      closeButtonHandler={closeAllModals}
      backButtonHandler={closeModal}
      nextButtonHandler={completeDeferral}
      nextButtonEnabled={
        paymentScheduleData?.is_in_cycle_or_outcycle === 'outCycle' ? boxChecked : true
      }
    />
  )
}

import { figLogoSecondaryEn } from 'src/images'
import { EmploymentStatus } from 'src/types'

const translation = {
  init: {
    loading: 'We are gathering your loan application details. Thank you for your patience.',
  },
  common: {
    earlyRepaymentLabel:
      'Interested in early repayment? Fig does not charge any penalties for early repayments',
    continueLabel: 'Continue',
    cancelLabel: 'Cancel',
    backlabel: 'Back',
    helpLabel: 'Visit help centre',
    helpLinkLabel: 'help centre.',
    helpLinkLabelGeneric: 'help centre',
    loading: 'Loading...',
    retryLabel: 'Try again',
    privacyPolicyLabel: 'Privacy Statement',
    privacyPolicyUrl: 'https://www.fig.ca/en/legal/privacy-statement',
    termsAndConditionsLabel: 'Terms and Conditions',
    termsOfServiceLabel: 'Terms of Service',
    termsAndConditionsUrl: 'https://fig.ca/en/legal/terms-and-conditions/',
    consent:
      'By continuing with this loan application, you agree to Fig Financial Inc.\'s  ("Fig") <1/> and understand that Fig will collect, use and share the personal information that you provide (or authorize a third party to provide) in connection with your loan application for the purpose of processing your application and servicing your loan in accordance with Fig\'s <3/>.',
    savings: 'Savings',
    checking: 'Checking',
    chequing: 'Chequing',
    FigDatePicker: {
      placeholder: 'Select a date',
      defaultDateFormat: 'EEEE, MMMM do',
    },
    maintenanceBanner:
      "We're currently experiencing an issue that may prevent you from completing an application. Please continue with your application as your progress will be automatically saved. If the issue persists, please check back in a few hours. Thank you for your patience!",
  },
  ConnectivityToast: {
    Disconnected: {
      title: 'Slow or no connection',
      content: 'Please check your internet connection in order to proceed further.',
    },
    Reconnected: {
      title: 'Connection restored',
      content: 'You have successfully connected to your internet.',
    },
  },
  ApplicationAlreadySettled: {
    title: "You've already got a loan with us!",
    content:
      'Visit your Fig account for details of your loan. Got questions? Visit our help centre to get the answers you need. For other concerns <2/>',
    loginLabel: 'Log in to my Fig account',
    alt: 'Hand with approval checkmark',
  },
  ApplicationDeclined: {
    title: 'Sorry, we aren’t able to offer you a loan right now',
    content: 'To learn more about why you may not have been approved, you can visit our <2/>',
    alt: 'Verification clipboard',
  },
  ApplicationExpired: {
    title: 'Sorry, your loan offer has expired',
    content:
      'Click below to refresh your application and loan offer. If you have any other questions, <2/>',
    startAgainLabel: 'Start again in {{partnerName}}',
    alt: 'Expired clock',
  },
  ApplicationIdNotFound: {
    title: 'Incorrect link',
    content:
      'The link you requested was not found. Please visit Fig help centre or if you have further questions, please <2/>.',
    alt: 'Oops, something is off here',
  },
  ApplicationOrderDeclined: {
    title: 'Sorry, we aren’t able to offer you a loan right now',
    content:
      'We have identified issues with your application, and unfortunately, we are unable to offer you a loan at this time. Fig regularly reviews applications similar to yours, and we may contact you for additional information. If you would like to contact us directly, <2/>',
    alt: 'Verification clipboard',
  },
  BankConnectError: {
    title: 'Something went wrong while connecting your bank account',
    content:
      'We weren’t able to connect to your bank account. Click the ‘Try again’ button, or <2/>',
    alt: 'Oops, something is off here',
  },
  GenericError: {
    title: 'Oops, something’s gone wrong',
    content:
      'We’re working on fixing this as soon as possible. Click the ‘Try again’ button, or <0/>',
    alt: 'Oops, something is off here',
    LocalStorageError: {
      title: 'Sorry, we need your permission to continue',
      content:
        'Please follow the instructions below to enable local storage, and try again after you have updated the settings. <br><br><0/>',
      enableLink: 'How to enable local storage',
      helpLink:
        'https://fig-support.freshdesk.com/a/solutions/articles/151000201125?lang=en&portalId=151000010978',
    },
  },
  PageNotFound: {
    title: 'Incorrect link',
    content:
      'The link you requested was not found. Please return to the loan application or if you have further questions, <2/>',
    restartAppLabel: 'Return to application',
    alt: 'Oops, something is off here',
  },
  MaintenanceMode: {
    title: "We're giving our system a quick\n tune-up",
    content:
      "We'll be back online within an hour, please come back soon to continue your fast & easy application! <1/>Thanks for your understanding.",
    alt: 'Alarm clock with warning',
  },
  PleaseReachOut: {
    contactFigLabel: 'email Fig support',
    liveChatLabel: 'Fig live chat',
    content: 'please reach out to us via <2/> to the bottom right of this page or <4/>.',
  },
  Onfido: {
    crossDeviceTitle: 'Mobile session linked to your Fig loan application',
    crossDeviceInfo1:
      'This link was sent by you - stop if this link is sent to you by someone else.',
    crossDeviceInfo2: 'Your desktop window remains open',
    crossDeviceBtn: 'Continue Fig loan application',
    permissionsIssue: {
      title: 'Oops, please allow camera and microphone permissions to continue',
      subtitle: 'We could not proceed ID verification due to your app’s permission issue.',
      listTitle: 'Solution: Switch to browser',
      first: 'Copy the URL below',
      second: 'Paste and open URL in your browser',
      third: 'Complete your application again',
      copyButton: 'Copy URL',
      copyButtonClicked: 'Copied',
    },
    tokenExpired: {
      title: 'Your session has expired',
      content: 'Please click try again to complete verifying your identity.',
      alt: 'Token Expired',
      button: 'Try again',
    },
  },
  KYCIdFail: {
    title: 'Sorry, we couldn’t verify your identity',
    content:
      'There were some issues verifying your documents. If you think there was a mistake, <2/>',
    liveChatLabel: 'Start Fig live chat',
    alt: 'Verification failed',
  },
  FlinksBankValidationFail: {
    title: 'Sorry, we couldn’t verify your bank account',
    content:
      'We could not verify your bank account. Please validate that you are using your primary personal bank account. If you have further questions, <2/>',
    alt: 'Verification failed',
  },
  FlinksFail: {
    title: 'Sorry, we couldn’t verify your bank account',
    content: '',
    alt: '',
  },
  KYCSuccess: {
    content: 'Success! Your documentation was verified',
    alt: 'Thank you for choosing Fig!',
  },
  KYCVerify: {
    isVerifyingLoadingLabel: 'We are gathering your information. Thank you for your patience.',
  },
  LoanAmount: {
    loanAmountLabel:
      "Hi, {{firstName}}! Let's get you your loan. Choose how much you want to borrow.",
    guaranteedAprLabel: 'The amount you select is guaranteed with a fixed {{apr}} APR.',
    guaranteedAprLabelQC: 'The amount you select is guaranteed with a fixed {{apr}} Credit Rate.',
  },
  PaymentProtection: {
    title: 'Protect your payments against unexpected life events',
    factSheetUrl: 'https://fig.ca/documents/LPPP-Product-Summary-and-Fact-Sheet-QC-Oct-25.pdf',
    subtitle:
      'We’re here to help you and your family stay financially on track when it matters most. The optional Payment Protection Plan underwritten by Trans Global Insurance Company and Trans Global Life Insurance Company protects you against:',
    testimony: {
      content:
        "'When my husband’s health problems kept him out of work for a prolonged period of time, TGI's Payment Protection Plan was there to see us through the hard times. I would most definitely recommend TGI's Payment Protection Plan. '",
      name: '- Mary A',
    },
    ScheduleFrequency: {
      weekly: '/week',
      biweekly: ' every two weeks',
      twiceAMonth: ' twice a month',
      monthly: '/month',
    },
    LinksText: {
      termsLink: 'Terms',
      faqLink: 'FAQs',
      factSheetLink: 'Product Summary and Fact Sheet',
    },
    FirstAccordion: {
      title: 'Involuntary unemployment or disability',
      subtitle: 'Could cover your loan payments up to 12 months',
      pointone:
        'Covers 100% of your regular monthly payments, for up to 12 months and a maximum of $30,000',
      pointtwo:
        'Involuntary unemployment coverage for both full-time employed and self-employed borrowers (working 25+ hours per week)',
      pointthree:
        'Disability coverage for borrowers who become totally and continuously disabled (for more than 30 consecutive days) as the result of accidental bodily injury or sickness, preventing them from working',
    },
    SecondAccordion: {
      title: 'Critical illness or life with dismemberment',
      subtitle: 'Could cover your oustanding balance',
      pointone: 'Covers your outstanding balance up to a maximum of $30,000',
      pointtwo: 'Available to the primary borrower and their spouse',
      pointthree:
        'Critical illnesses covered: cancer (life threatening), heart attack, stroke, coronary artery bypass graft, kidney failure and major organ transplant',
      pointfour: 'Critical Illness coverage ceases at the age of 65',
      pointfive:
        'Dismemberment: irrevocable loss of the entire sight of both eyes or a hand or foot by complete severance through or above the wrist or ankle joint',
    },
    FirstBox: {
      title: 'Recommended',
      subtitle: 'Enroll me now for {{insuranceAmount}}{{frequency}}',
      subtitle1: 'Cancel anytime',
    },
    SecondBox: {
      title: 'I decline protection for my',
      title1: ' {{loanAmt}} ',
      title2: 'loan',
    },
    disclaimer: {
      ROC: 'I acknowledge that I am a Canadian resident, and I am at least 18 years of age and have been made aware of and understand the terms, conditions and exclusions outlined in the Terms and FAQs',
      QC: 'I acknowledge that I am a Canadian resident, and I am at least 18 years of age and have been made aware of and understand the terms, conditions and exclusions outlined in the Terms, FAQs and Product Summary and Fact Sheet',
    },
    FAQ: {
      title:
        'Let’s find out whether this Loan Payment Protection Program(LPPP) will meet your needs',
      subtitle: "FAQ's",
      buttonViewMore: 'View All FAQs',
      buttonViewLess: 'View Less',
      question1: 'What does the LPPP cover?',
      subtitle1_point1:
        ' If you are eligible, the LPPP provides the following coverages in connection with your Fig loan, up to an aggregate maximum benefit of $30,000:',
      subtitle1_point2_title: 'Involuntary Unemployment coverage:',
      subtitle1_point2:
        ' If you lose your job, the coverage could make up to 12  months of payments, towards your outstanding Fig loan balance. The total payments will not exceed the lesser of the outstanding balance or $30,000. This coverage also extends to self-employed individuals.',
      subtitle1_point3_title: 'Disability coverage:',
      subtitle1_point3:
        ' If you become disabled and unable to work, the coverage could make up to  12 months of payments towards your outstanding Fig loan balance.',
      subtitle1_point4_title: 'Critical Illness coverage*:',
      subtitle1_point4:
        ' If you or your spouse (in Quebec, the coverage is available to the primary borrower only) become diagnosed with a critical illness for the first time and survive that first diagnosis for at least 30 days, the coverage will pay Fig, an amount equal to the outstanding Balance, up to a maximum of $30,000. The coverage will be applied by Fig towards your loan. Critical illness coverage ends at the age of 65.',
      subtitle1_point5_title: 'Life with Dismemberment coverage**:',
      subtitle1_point5:
        ' If you or your spouse (in Quebec, the coverage is available to the primary borrower only) die or suffer dismemberment, the coverage will pay Fig, an amount of insurance equal to the outstanding balance up to a maximum of $30,000. If the death or dismemberment of you and your spouse occurs simultaneously, only one benefit will be paid.',
      subtitle1_point6:
        '*The Critical Illness benefit will be paid only once. When you are simultaneously disabled, involuntarily unemployed, or involuntarily unemployed as a self-employed, you are entitled to benefits only under one coverage.',
      subtitle1_point7:
        '**Before the age of 65 (71 in BC), the Loan Payment Protection Program does not require that death arise from accidental circumstances. Beginning from age 65 (71 in BC) and thereafter, the LPPP only provides coverage if death arises from accidental circumstances and death must occur within 90 days of the accident.',
      question2: 'How to qualify for the coverage?',
      subtitle2_point1: 'To qualify for the Involuntary Unemployment Benefit:',
      subtitle2_point2:
        'You must be insured under the Policy and gainfully employed on a permanent basis, working full-time at the Date of your Loss, which means working at least 25 hours each week.',
      subtitle2_point3: 'You must be involuntarily unemployed for more than 30 consecutive days.',
      subtitle2_point4:
        'Prior to your involuntary unemployment, your employer shall have been paying employment insurance premiums to the Canada Revenue Agency (CRA) and/or any of its successor entities, on your behalf.',
      subtitle2_point5: 'You must be insured for at least 30 days after your enrollment date.',
      subtitle2_point6: 'To qualify for the Disability Benefit:',
      subtitle2_point7:
        'You become disabled and as a result are unable to work and shall remain totally disabled for more than 30 consecutive days.',
      subtitle2_point8:
        'You must be regularly attended by a licensed physician or surgeon other than yourself and, in the opinion of the physician or surgeon, be prevented from engaging in any business or employment for which you are reasonably fitted by training, experience or education.',
      subtitle2_point9:
        'You must have been insured under the Policy and gainfully employed or working in a self-employed capacity earning taxable revenue pursuant to the Canada Income Tax Act on a permanent basis, working full-time at the Date of Loss, which means working at least 25 hours each week.',
      subtitle2_point10: 'To qualify for the Critical Illness Benefit:',
      subtitle2_point11:
        'The date of First Diagnosis must occur prior to the individual’s 65th birthday.',
      subtitle2_point12:
        'The critical illnesses covered under this policy are Cancer (life-threatening), Heart Attack, Stroke, Coronary Artery Bypass Graft, Kidney Failure, and Major Organ Transplant. The Critical Illness must not have resulted directly or indirectly from any of the exclusions found under the ',
      subtitle2_point12_bold_point1: '"What are the exclusions and conditions of the coverage"',
      subtitle2_point12_bold_point2: ' section of this FAQ.',
      subtitle2_point13:
        'The Critical Illness must not have existed, or was first diagnosed, prior to the Effective Date or within 90 days after the Effective Date.',
      subtitle2_point14: 'Proof of loss must be submitted within 90 days of First Diagnosis.',
      subtitle2_point15: 'To qualify for the Life with Dismemberment Benefit:',
      subtitle2_point16:
        'Dismemberment must occur through accidental bodily injuries that are sustained directly and independently of all other causes resulting in the total and irrevocable loss of the entire sight of both eyes or a hand or foot by complete severance through or above the wrist or ankle joint.',
      subtitle2_point17:
        'The death must not have resulted from any of the exclusions found under the ',
      subtitle2_point17_bold_point1: '"What are the exclusions and conditions of the coverage"',
      subtitle2_point17_bold_point2:
        ' section of this FAQ; or a pre-existing Condition, if you die within 6 months of the Effective Date from that pre-existing condition.',
      question3: 'How much does the LPPP cost? ',
      subtitle3:
        'The Premium charged for the Loan Payment Protection Program will be the amount calculated by multiplying the Insurer’s Premium Rate of 15% by the customer’s regular principal and interest payments, plus applicable taxes, payable at the same frequency as the customer’s regular loan payments. ',
      question4: 'How do I make a claim?',
      subtitle4_point1:
        'To make a claim, follow these steps to contact Trans Global Insurance Company and Trans Global Life Insurance Company:',
      subtitle4_point2: 'By calling 1-844-930-6022 between 8:00 a.m. and 5:00 p.m. (MST)',
      subtitle4_point3: 'By email clientcare@transglobalinsurance.ca',
      subtitle4_point4:
        'By written notice to TRANS GLOBAL INSURANCE GROUP, 16902-137 Ave. NW, Edmonton, AB T5V 0C8.',
      question5: 'Can I cancel my LPPP? ',
      subtitle5_point1:
        'The Loan Payment Protection Program is optional, and you can cancel it at any time. If you cancel within the first 30 days (60 days in Quebec), the premiums you paid will be refunded to you. If you cancel any time after 30 days (60 days in Quebec), you will not be entitled to any refund of premiums charged.',
      subtitle5_point2: 'Cancellation through Fig:',
      subtitle5_point3:
        'If you wish to cancel your loan payment protection policy through Fig, simply email us at support@fig.ca. Your policy will be canceled upon receipt of a written confirmation from you.',
      subtitle5_point4:
        'Cancellation through Trans Global Life Insurance Company and Trans Global Insurance Company:',
      subtitle5_point5:
        'A cancellation form will need to be completed, signed, and sent to Trans Global Insurance. If you have any questions regarding this Policy or require claim information, please contact:',
      subtitle5_point6:
        'Trans Global Life Insurance Company and Trans Global Insurance Company, 16902-137 Ave. NW, Edmonton, AB T5V 0C8',
      subtitle5_point7: 'Telephone: 1-844-930-6022',
      subtitle5_point8: 'By email clientcare@transglobalinsurance.ca',
      question6: 'What is the Loan Payment Protection Program (LPPP)?',
      subtitle6:
        'The LPPP is a comprehensive optional insurance program for customers with a Fig loan who have opted-in for the coverage, agreed to pay the premium, and continue to pay premiums on a timely basis. The coverage is underwritten by Trans Global Insurance Company and Trans Global Life Insurance Company.',
      question7: 'Who is Eligible for the coverage?',
      subtitle7_point1: 'To be eligible for the coverage, you must be: ',
      subtitle7_point2: 'A Fig Financial Inc. primary loan borrower',
      subtitle7_point3: 'A Canadian resident',
      subtitle7_point4: 'A person at least 18 years of age.',
      question8: 'Who is covered?   ',
      subtitle8_point1:
        'The Life and Dismemberment and Critical Illness coverages are available to the primary borrower and their spouse. In Quebec, both coverages are available to the primary borrower only.',
      subtitle8_point2:
        'The Disability and Involuntary Unemployment coverages are available only to the primary borrower. ',
      subtitle8_point3:
        'The primary borrower is the individual whose name appears on the Fig loan agreement.',
      question9: 'What are the exclusions and conditions of the coverage? ',
      subtitle9_point1: 'General exclusions from the coverage include:',
      subtitle9_point2: 'Intentionally self-inflicted injury;',
      subtitle9_point3: 'Attempted suicide;',
      subtitle9_point4: 'Drug, alcohol, or solvent abuse;',
      subtitle9_point5: 'The commission, or attempted commission, of an illegal act; ',
      subtitle9_point6:
        'Military service, declared or undeclared war, or any nuclear, chemical, or biological contamination resulting from an act of terrorism.',
      subtitle9_point7:
        'There are also additional exclusions and conditions that can be found in the Certificate of Insurance. Some of these exclusions relate to pre-existing conditions.  For example, the insurers will not pay a monthly disability benefit if the disability results from a pre-existing condition, which is any sickness or injury for which you received medical advice, consultation, diagnosis or investigations, or for which treatment was required or recommended by a doctor during the 6 months before the effective date of coverage.  The insurers will also not pay a death benefit if you die from a pre-existing condition within 6 months of the effective date.',
      question10: 'When does the insurance start?  ',
      subtitle10:
        'The effective date of the Loan Payment Protection Program is the date that Trans Global Insurance Company and Trans Global Life Insurance Company receive your application/enrollment for insurance. Fig will notify TGI of the enrollment at the time of the loan booking.',
      question11: 'When does the insurance end? ',
      subtitle11_point1: 'On the sooner of:',
      subtitle11_point2:
        'The date Trans Global Life Insurance Company or Trans Global Insurance Company  or Fig receive your written confirmation to end the insurance coverage, or ',
      subtitle11_point3:
        '31 days from the date Trans Global Life Insurance Company or Trans Global Insurance Company  send you written notice, by first class mail to your last known address, to cancel this insurance, or',
      subtitle11_point4:
        'The date your Fig loan is terminated, on receipt of notice of termination by the insurer, or',
      subtitle11_point5:
        'The date you are more than 31 days late in making any required payment towards your Outstanding Balance, or',
      subtitle11_point6:
        'If you have deferred your loan payment date, the date you are more than 31 days past due from your last contractual loan payment due date.',
      question12: 'What if I already have other insurance? ',
      subtitle12: 'The LPPP is designed to complement any other coverage you may have.',
      question13: 'How do I make a complaint?',
      subtitle13_point1: 'To make a complaint, follow these steps:',
      subtitle13_point2:
        'If for some reason you are not satisfied with the resolution to an inquiry or dissatisfaction, please call 1-844-930-6022 between 8:00 am and 5:00 pm (MT), Monday to Friday.',
      subtitle13_point3:
        'Should you require more information regarding the Insurer’s complaint handling procedure, please visit the Trans Global Insurance website to view the complaint resolution processes which can be found at: https://transglobalinsurance.ca/resolving-complaints/',
      question14: 'Who do I contact if I have questions about the Loan Payment Protection Program?',
      subtitle14:
        'If you have any questions about this insurance coverage, contact Trans Global Insurance Company and Trans Global Life Insurance Company at 1-844-930-6022 or by email at clientcare@transglobalinsurance.ca.',
      question15: 'What are the consequences of any misrepresentation in my application?',
      subtitle15:
        'If you misstate your age to us at the time you provide us with your application, the insurers’ liability is limited to a refund of all premiums you have paid.',
    },
    Terms: {
      FirstTitle: {
        title: 'Terms',
        point1:
          'Insurance is not required as a condition to obtain the loan. The insurance is with Trans Global Insurance Company and Trans Global Life Insurance Company; you are not contracting this insurance with Fig Financial Inc. ',
        point2:
          'Coverage is effective upon enrollment and ceases on the earliest of the dates specified  in the Certificate of Insurance.',
        point3:
          'You will have a 30-day free review period (60 days in Quebec) upon receipt of your  Certificate of Insurance. Should you cancel during this period, the premiums you paid  will be refunded to you. You may also cancel at any time after the free review period by  contacting Fig or the insurer But will not be entitled to a refund of premiums.',
        point4:
          'Fig receives remuneration for performing administrative duties in respect of the Group Policy. This amount is fifty five percent of the premium charged.',
        point5:
          'Benefits are subject to terms and conditions including coverage eligibility, exclusions,  and limitations. Failure to meet eligibility requirements may result in you being ineligible for coverage and potential denial of benefits.',
        point6:
          'For a complete listing of terms and conditions, including information on how to make a  claim, please refer to the Certificate of Insurance that will be provided to you upon enrollment. For Quebec residents, please also review the Summary & Fact Sheet. ',
        point7:
          'Individual circumstances may vary. You may wish to contact the licensed insurer’s  representative or a licensed insurance agent if you need advice about your insurance  needs.',
      },
      SecondTitle: {
        title: 'Insurer Contact Information',
        point1:
          'Coverage is underwritten by Trans Global Insurance Company and Trans Global Life Insurance  Company Company for residents of provinces outside of Quebec, and exclusively by Trans Global Life Insurance Company for Quebec residents. ',
        point2: 'Tel: 1-844-930-6022.  ',
        point3: 'Email: clientcare@transglobalinsurance.ca',
      },
    },
  },
  Occupation: {
    employmentStatus: {
      title: 'Employment status',
      label: 'What is your employment status?',
      placeholder: 'Select your employment status',
    },
    employmentStatuses: {
      employed: EmploymentStatus.EMPLOYED as string,
      selfEmployed: EmploymentStatus.SELF_EMPLOYED as string,
      unemployed: EmploymentStatus.UNEMPLOYED as string,
      retired: EmploymentStatus.RETIRED as string,
      student: EmploymentStatus.STUDENT as string,
      disability: EmploymentStatus.DISABILITY as string,
    },
    occupation: {
      label: 'Occupation',
      placeholder: 'i.e. Tax Accountant',
      add: `Add "{{occupationName}}"`,
    },
    employer: {
      label: 'Employer/Company Name (optional)',
      labelQC: 'Employer/Company Name',
      placeholder: 'i.e. Canadian Tire',
    },
    incomeSource: {
      label: 'Source of Income (optional)',
      labelQC: 'Source of Income',
      placeholder: 'i.e. Rental Income',
    },
    duration: {
      label: 'Start date of employment',
    },
    alt: '',
  },
  OTP: {
    title: 'Enter your phone number',
    subtitle: 'We will use this to verify you.',
    phoneLabel: 'Phone number',
    phoneHelperText: 'We only accept Canadian phone numbers',
    phonePlaceholder: 'Enter phone number',
    callAlertTitle: 'We will be calling you shortly',
    callAlertContent: 'We will give you a call at {{phoneNumber}}',
    textAlertTitle: 'We sent you a text message',
    textAlertContent: 'Your verification code was sent to {{phoneNumber}}',
    verifyTitle: 'Enter the code we sent you',
    verifySubtitle: 'We provided a verification code to {{phoneNumber}}',
    verifyingLabel: 'Verifying',
    verifyErrorMessage: 'Sorry, the code you entered is expired or incorrect.',
    verifyErrorRetry: 'Please try again.',
    sendItAgainQuestion: 'Didn’t get the text?',
    sendItAgainLabel: 'Send it again',
    callLabel: 'Call me instead',
  },
  BankConnectCarousel: {
    why: {
      question: 'Why do I need to connect my bank account?',
      answer:
        'Your bank account information will be used to deposit your loan, withdraw your loan payments and verify your information.',
      alt: 'Shield with check mark',
      tooltip:
        'Your account information and transaction history will be used to validate repayment ability, and assess risk.',
      learnMore: 'Learn More',
      url: 'https://fig-support.freshdesk.com/en/support/solutions/articles/151000185044',
    },
    secure: {
      question: 'Will my bank information be secure?',
      answer:
        'We take security seriously. We have an extremely high standard of data security and privacy to keep your personal information protected.',
    },
    which: {
      question: 'Which bank account should I connect?',
      answer:
        'For effortless payments, please enter the username and password of your primary bank account where you receive your pay.',
      alt: 'Bank icon',
    },
  },
  ConnectBankAccountSuccess: {
    title: 'You have successfully linked your bank account!',
    subtitle: 'Let’s continue with the rest of the loan application. Only a few more steps left!',
  },
  FlinksPaymentCheck: {
    loading: 'We are linking your bank account. This may take a few minutes.',
  },
  SelectExisting: {
    loading: 'We are linking your bank account. This may take a few minutes.',
    title: 'Choose from one of the payment methods you have on file',
    addAccount: 'Add a bank account',
  },
  SelectMethod: {
    title: 'Let’s connect your bank account',
    subtitle: 'You will be asked to provide your bank account information.',
    secureLabel: 'All bank connections are encrypted for security <1/> and privacy.',
    connectLabel: 'Connect your bank account',
    logoSubtext: '+ {{amt}} more',
  },
  PaymentSchedule: {
    RepaymentStartHeading: {
      title: 'When would you like to make payments?',
    },
    EveryWeekScheduleOptions: {
      dateFormat: 'EEEE, MMMM do',
    },
    MonthlyScheduleOptions: {
      first: '1st of the month',
      fifteenth: '15th of the month',
      last: 'Last day of the month',
      other: 'Other',
      firstPaymentDate: 'First payment date',
    },
    PaymentScheduleCalendar: {
      changeScheduleLabel: 'Change payment schedule',
      disclaimer:
        'Please note that your payment withdrawal may vary from 1 to 3 days, depending on weekends or holidays.',
      withdrawalText: 'We will withdraw <1/> {{paymentScheduleText}}',
      tooltip:
        'If you enrolled in the Payment Protection Plan please refer to the final loan details page for the total payment amount.',
      title: 'Your payment schedule',
      startText: 'Starting on {{displayDate}}',
      firstPaymentDateFormat: 'EEEE, MMMM dd, yyyy',
      alt: 'payment schedule calendar',
    },
    scheduleHelpers: {
      everyWeek: 'every {{dayOfWeek}}',
      everyTwoWeeks: 'every 2 weeks',
      twiceAMonth: 'on the 1st and 16th of every month',
      onceAMonth: {
        first: 'on the 1st of every month',
        last: 'on the last day of every month',
        fifteenth: 'on the 15th of every month',
        other: 'on the {{day}} of every month',
      },
    },
    ConfirmPaymentSchedule: {
      title: 'Let’s confirm your payment schedule',
    },
    EditPaymentSchedule: {
      title: 'Change your payment schedule',
      frequencyTitle: 'How often would you like to make payments?',
      rolloverNotification:
        'We will process your first payment on <2>{{firstPaymentDateFormatted}}</2> as your previous selection falls on a weekend or a holiday.',
      rolloverDateFormat: 'EEEE, MMMM do, yyyy',
      frequencyOptions: {
        everyWeek: 'Every week',
        everyTwoWeeks: 'Every 2 weeks',
        twiceAMonth: {
          primary: 'Twice a month -',
          secondary: '1st and 16th of the month',
        },
        onceAMonth: 'Once a month',
      },
      saveScheduleLabel: 'Save schedule',
    },
  },
  ProgressBar: {
    loanAmount: 'Loan Amount',
    otp: 'Phone Verification',
    phone: 'Phone',
    paymentPlan: 'Payment Plan',
    linkBankAccount: 'Link Bank Account',
    idVerification: 'ID Verification',
    occupation: 'Occupation',
    loanPurpose: 'Loan Purpose',
    review: 'Review',
    paymentProtection: 'Payment Protection',
    eligibility: `Eligibility`,
    confirmInformation: `Confirm Information`,
    loanDetails: `Loan Details`,
    repaymentSchedule: 'Repayment Schedule',
    paymentProtectionCR: 'Payment Protection',
  },
  PurposeOfLoan: {
    title: 'What is the purpose of your loan?',
    completeAllFields: 'Please complete all the required fields',
    otherReasonLabel: 'Other reason*',
    displayNames: {
      chooseAnOption: 'Choose an option',
      bills: 'Expenses',
      debt: 'Debt Consolidation',
      emergency: 'Emergency',
      education: 'Education',
      home: 'Home Improvement',
      auto: 'Automobile Financing / Repair',
      personalInvestment: 'Personal Investment',
      other: 'Other',
    },
    investmentScamMessage: `We prioritize your well-being and want to ensure that you make informed financial decisions. Please be mindful of the increased risk of using loan proceeds to invest in stocks, cryptocurrencies and other speculative investments.
    `,
    investmentScamWarningMessage: `Exercise caution when directed by others to take out a loan for investment purposes. `,
    investmentScamLinkMessage: 'Learn more',
    investmentScamArticleLink:
      'https://support.fig.ca/en/support/solutions/articles/151000047885-investment-scam-warning',
  },
  SelectOffer: {
    title: 'Great! Now, pick a payment plan that works for you.',
    offerAmount: '{{offerAmount}}/month',
    termLength: '{{termLength}} months',
    apr: '{{apr}} APR',
    aprQC: '{{apr}} Credit Rate',
    emailConsent:
      'Fig can occasionally send me electronic messages about its products and services (optional)',
    emailConsentDetail: "Don't worry, we don’t like spam either",
  },
  Finish: {
    loading: 'Your loan is being processed. Thank you for your patience.',
    title: 'Congratulations on booking your loan!*',
    disbursalDate: 'You can expect to receive your money by end of day {{disbursalDay}}.*',
    questionsTitle: 'Questions?',
    questionsContent:
      'Visit our help centre for any questions you have about your loan or next steps.',
    helpCentre: {
      label: 'Go to help centre',
      url: 'https://support.fig.ca/en/support/home',
    },
    termsContent: `* We may reach out to you to conduct additional verifications on information which you have provided.  In such cases, your cooperation is required for us to continue to process your loan.`,
    goToPartnerLabel: 'Back to {{partnerName}}',
  },
  ReviewOrder: {
    title: 'Let’s confirm your loan details',
    subtitle:
      'Your application is approved! Please review and accept your loan details and agreement to receive your funds.',
    firstPaymentDate: {
      title: 'First payment date',
      dateFormat: 'MMM d, yyyy',
    },
    change: 'Change',
    paymentProtectionPlan: {
      title: 'Payment protection plan',
      textEnabled: 'Your payments are protected',
      textDisabled: 'Your payments are not protected',
    },
    paymentSchedule: {
      title: 'Payment schedule',
      withdrawAmount: 'You’ll pay <2>{{displayWithdrawAmount}}</2> <4>{{paymentScheduleText}}</4>',
      loanPaymentTitle: 'Loan payment',
      paymentProtectionTitle: 'Payment protection (incl. taxes)',
      totalPaymentTitle: 'Total payment',
      paymentProtectionPremiumTitle: 'Payment protection premium',
      paymentProtectionPremiumTaxTitle: 'Tax on payment protection',
    },
    loanDetails: {
      title: 'Loan details',
      summary:
        'You’re borrowing <2>{{loanAmt}}</2> over <6>{{termLength}} months</6> ({{apr}} APR)',
      summaryQC:
        'You’re borrowing <2>{{loanAmt}}</2> over <6>{{termLength}} months</6> ({{apr}} Credit Rate)',
      loanAmountSubtitle: 'Loan amount',
      interestAmtSubtitle: 'Interest amount',
      interestAmtSubtitleQC: 'Credit charge amount',
      totalRepaymentAmtSubtitle: 'Total repayment amount',
    },
    paymentMethod: {
      title: 'Payment method',
    },
    consents: {
      loanAgreementLabel: 'loan agreement',
      loanAgreementText: 'I agree to Fig’s <2>{{loanAgreementLabel}}</2><3>*</3>',
      loanAgreementFootnote: 'Psst - this one’s important',
      padAgreementLabel: 'pre-authorized debit agreement',
      padAgreementText:
        'Fig can automatically withdraw payments that are due from my account according to the <2>{{padAgreementLabel}}</2><3>*</3>',
      learnMoreLabel: 'Learn more*',
      creditCheckText:
        'I authorize Fig to conduct a one-time hard credit inquiry and, for the entire duration of my active loan, periodic soft credit assessments through TransUnion. These checks are essential for processing my loan application, evaluating my creditworthiness, and effectively managing my loan. <0>{{learnMoreLabel}}</0><1>*</1>',
      marketingText:
        '<0>Fig can occasionally send me electronic messages about its products and services (optional)</0><1>Don’t worry, we don’t like spam either</1>',
    },
    confirmLoanLabel: 'Book my loan',
    loading: 'Your loan is being processed. Thank you for your patience.',
    loadingLoanAgreement: 'Loading your loan agreement...',
    loadingPad: 'Loading your pre-authorized debit agreement...',
    disclaimer:
      'Your loan will start immediately. It may take up to 2 business days before funds will appear in your account.',
    dateUpdateNotification:
      'Our system updated your first payment date from <2>{{prevDate}}</2> <4>to</4> <6>{{currDate}}</6> to match the payment schedule.',
  },
  QCConsents: {
    title: 'Privacy consents',
    viewMore: 'View more',
    viewLess: 'View less',
    biometricConsent: {
      content:
        'I consent to the collection and use of my biometric information in connection with my loan application for the purpose of verifying my identity, detecting and preventing fraud, and meeting Fig Financial Inc.’s (“Fig”) legal and regulatory requirements (including compliance with anti-money laundering rules).',
      detail1:
        'Fig uses an identity verification provider for the purposes of reliably verifying your identity. You will be asked to submit a photo of your identification document and take a selfie. Your images are used to generate alphanumeric templates of your facial measurements, which are then compared in order to verify that the individual in both images is the same.',
      detail2:
        'A copy of the alphanumeric templates of your facial measurements generated from your selfie and the identification document you provide will be stored for up to 12 months in order to detect and prevent fraud and meet our legal and regulatory requirements (including compliance with anti-money laundering rules) . As further described in our Privacy Policy, we and our service providers use reasonable administrative, technical and physical safeguards to protect your information, which may be processed outside of the jurisdiction in which you are located.',
    },
    generalConsent: {
      content:
        'By continuing with this loan application, you agree to Fig’s <0/> and understand that Fig will collect, use and share the personal information that you provide (or authorize a third party to provide) in connection with your loan application for the purpose of processing your application and servicing your loan in accordance with Fig’s <1/>.',
      detailHeading: 'This involves:',
      detailParagraph1:
        'evaluating your application, including determining your eligibility for a loan by obtaining a credit check from a consumer reporting agency with your consent;',
      detailParagraph2:
        'sharing information with third parties (who may be located outside of Quebec), including our affiliates, service providers, credit bureaus and reporting agencies and references you provide in this application;',
      detailParagraph3:
        'detecting and preventing fraud and unauthorized activities, including the use of a third party fraud prevention partner;',
      detailParagraph4: 'collecting outstanding debts in connection with your loan;',
      detailParagraph5: 'communicating with you about your loan; and',
      detailParagraph6: 'meeting legal and regulatory requirements.',
    },
    rightToAccessConsent: {
      content:
        'If you have any questions or wish to contact us regarding your rights to access and rectify, or withdraw your consent to the processing of, your personal information, or the manner in which we and our service providers (including our service providers located outside of your province or Canada) treat your personal information, you can visit our <0/> or contact our Privacy Officer.',
      detail:
        'Privacy Officer<br>Fig Financial Inc.<br>630 Rene Levesque, Suite 1400<br>Montréal QC H3B 4Z9<br>1-844-431-3444<br><0/>',
    },
  },
  CreditRenewals: {
    requestButtonLabel: `Request a loan amount increase`,
    requestLoanIncreaseDescription: `You'll be redirected to your Fig account to check your eligibility and submit your request online`,
    tooltipAlt: 'tooltip',
    Eligibility: {
      title: `Loan Amount Increase Request`,
      subtitle: 'Secured application',
      heading1: 'Eligibility',
      description1:
        'You must be a borrower in good standing and meet certain criteria as defined by Fig. You can request a loan amount increase from $2000 up to the unused amount from your original loan offer.',
      heading2: 'How it works',
      description2:
        'Rest assured the process is simple and no hard credit check is required. You’ll receive the additional funds in your account within 2 business days. Your current loan will be closed and a new loan will be opened reflecting the new total amount. <learnMoreLink>Learn more</learnMoreLink>',
      learnMoreUrl:
        'https://support.fig.ca/en/support/solutions/articles/151000047886-credit-renewals',
      buttonLabel: 'Check my eligibility',
      notice:
        'By clicking ‘Check my eligibility’, a soft credit check may be conducted. It will NOT impact your credit score.',
    },
    Declined: {
      title: 'Sorry, we aren’t able to offer you a loan amount increase right now',
      paymentPendingContent: `Please come back in 2-3 business days as we need some more time to process your pending payment. For other concerns please reach out to us via <0>Fig live chat</0> to the bottom right of this page or <1>email Fig support</1>.`,
      declinedContent:
        'To learn more about why you may not have been approved, you can visit our <helpCentreLink></helpCentreLink>.',
    },
    ConfirmInformation: {
      aboveContainerContent: `🎉 Congratulations! You’re eligible for a loan amount increase up to `,
      title: 'Please confirm your information',
      buttonLabel: 'Confirm and continue',
      nameInput: {
        label: `Name`,
        errorMessage: 'Please contact us to update this info',
      },
      dateOfBirthInput: {
        label: `Date of Birth`,
        errorMessage: `Please contact us to update this info`,
      },
      phoneInput: {
        label: `Phone number`,
        errorMessage: `We only accept Canadian phone numbers`,
      },
      purposeOfLoanInput: {
        label: 'What is the purpose of your loan?',
      },
      addressInput: {
        label: 'Address',
      },
      occupationInput: {
        label: 'What is your occupation?',
      },
    },
    LoanDetails: {
      aboveContainerContent: `🎉 Congratulations! You’re eligible for a loan amount increase up to `,
      title: 'Please choose the additional loan amount you’d like to request:',
      offerTitle: 'Please confirm your repayment schedule.',
      selectorTitle:
        'You are requesting an increase of {{loan_amt}} to bring your total loan amount to {{total_amt}}, with a fixed {{apr}} APR. ',
      tooltipContent:
        'Your new loan amount takes into account the additional loan amount you are requesting and any previous payments you made.\n',
      tooltipLearnMore: `Learn more`,
      tooltipURL:
        'https://support.fig.ca/en/support/solutions/articles/151000199837-how-do-i-increase-my-loan-balance-',
      buttonLabel: 'Continue',
    },
    Review: {
      title: `Let's confirm your loan amount increase details`,
      currentLoanAmount: `Original loan amount`,
      increasedLoanAmount: `Additional amount requested`,
      previousPaymentAmount: `Previous principal payment`,
      interestAmount: `Interest amount`,
      disbursalInfoTitle: `Your disbursal info`,
      disbursalInfoDescriptionPreToolTip: "You'll receive {{disbursalAmount}} ",
      disbursalInfoDescriptionPostToolTip:
        'in your account ending in {{accountNumber}} within 2 business days',
      disbursalInfoLearnMore: `Learn more`,
      disbursalInfoLearnMoreLink: `https://support.fig.ca/en/support/solutions/articles/151000199837-how-do-i-increase-my-loan-balance-`,
      disbursalInfoHoverText: `Your disbursal amount takes into account the additional amount you are requesting and outstanding interest from your prior loan.`,
      disbursalInfoIncreasedLoanAmount: `Increased loan amount`,
      disbursalInfoTotalLoanAmount: `Total loan amount`,
      disbursalInfoOutstandingPrincipalAndInterest: `Amount to be applied to your previous loan for principal and interest `,
      disbursalInfoDisbursalAmount: `Amount to be received in your bank account`,
      disclaimer: `Your loan amount increase will start immediately.`,
      authorizedErrorLabel:
        'Your loan details have been updated. Please click "Change" next to your loan amount and review your new disbursal amount.',
      dateChangeErrorLabel:
        'Your first payment date cannot be in the past. Please change your Payment Schedule above.',
      alt: 'Clipboard review icon',
      submitCTA: `Submit`,
    },
    Finish: {
      title: `Your additional funds are on the way!`,
      disbursalDate: `You can expect to receive your money by end of day {{disbursalDay}}.* Check your inbox for an e-mail with all the details of your loan.`,
      alt: 'Tada success icon',
    },
    PaymentProtection: {
      launchIconAlt: 'launch FAQ sheet icon',
      checkIconAlt: 'Cancel anytime icon',
      recommendedAlt: 'Recommended Icon',
    },
  },
  e2e: {
    Prequalification: {
      loading: 'We are assessing your information. Thank you for your patience.',
      banner: 'Personal loans from {{interest}} APR, up to {{amt}}',
      title: 'Get an instant decision on a loan offer',
      subtitle:
        'Check to see if you qualify for a guaranteed offer by sharing your personal details below. Checking your eligibility will not impact your credit score.',
      getOffer: 'Get my guaranteed offer',
      alert: {
        error: {
          title: 'Error',
        },
      },
      form: {
        personalDetails: {
          title: 'Personal Details',
          firstName: {
            label: 'First Name',
            invalid: 'Invalid First name',
            max: 'First name is too long',
            required: 'First name is required',
          },
          lastName: {
            label: 'Last Name',
            invalid: 'Invalid Last name',
            max: 'Last name is too long',
            min: 'Last name is too short',
            required: 'Last name is required',
          },
          birthDate: {
            label: 'Date of Birth',
            invalid: 'Invalid birth date',
            required: 'Birth date is required',
          },
          phoneNumber: {
            label: 'Phone Number',
            invalid: 'Must be only digits',
            length: 'Must be exactly 10 digits',
            required: 'Phone number is required',
          },
          email: {
            label: 'Email Address',
            invalid: 'Email is not valid',
            required: 'Email is required',
          },
          grossAnnualIncome: {
            label: 'What is your gross annual income?',
            invalid: 'Must be only digits',
            required: 'Income is required',
          },
          socialInsuranceNumber: {
            label: 'Social insurance number (optional)',
            invalid: 'Must be only digits',
            length: 'Must be exactly 9 digits',
          },
        },
        address: {
          title: 'Residential Address',
          address: {
            label: 'Address',
          },
          errors: {
            required: 'Address is required',
            invalid: 'Complete residential address with street name and number required',
          },
          unit: {
            label: 'Unit (optional)',
          },
          postalCode: {
            label: 'Postal Code',
            required: 'Postal code is required',
            invalid: 'Invalid postal code',
          },
          city: {
            label: 'City',
            required: 'City is required',
          },
          homeOwnershipStatus: {
            label: 'Do you rent or own your place?',
            rent: 'Rent',
            own: 'Own',
            other: 'Other',
          },
          monthlyHomePayment: {
            label: 'What is your monthly mortgage/rent payment?',
            invalid: 'Must be only digits',
            required: 'Monthly home payment is required',
          },
          homeMoveInDate: {
            label: 'Since when have you been living at your current address?',
            invalid: 'Invalid move in date',
            required: 'Move in date is required',
          },
        },
      },
      consents: {
        paragraph1:
          '*Subject to standard Fig review and approval. Offer excludes residents of Northwest Territories, Yukon and Nunavut.',
        paragraph2:
          'By clicking “Get my guaranteed offer” you are starting an application with and consenting to Fig Financial Inc. using your personal information above for the purpose of checking your eligibility for a personal loan. This will result in a soft inquiry from TransUnion that will not affect your credit score.',
      },
    },
    Offer: {
      logoUrl: figLogoSecondaryEn,
      congrats: 'Congrats, {{firstName}}!',
      approvedAmt: "You're approved for up to {{amt}}!",
      apr: 'Your guaranteed APR with Fig',
      aprQC: 'Your guaranteed Credit Rate with Fig',
      monthlyPayment: {
        label: 'Monthly payment',
        value: 'From {{amt}}',
      },
      loanType: {
        label: 'Loan type',
        value: 'Unsecured',
      },
      term: {
        label: 'Term',
        value: 'Up to {{maximum_term_years}} years',
      },
      getLoan: 'Get my loan',
      applyLater: {
        buttonLabel: 'Apply later',
        alert: {
          new: {
            title: 'We sent you an email',
            content: 'Use the enclosed link to continue your application.',
          },
          existing: {
            title: 'We already sent an email',
            content: 'An email was sent with a link to resume your application.',
          },
          error: {
            title: 'Error',
            content:
              'An error occurred when sending you an email to apply later. Please try again later.',
          },
        },
      },
      guarantee: 'This offer is guaranteed for 15 days.',
    },
    SecondLookOffer: {
      title: '{{firstName}}, you may be eligible for up to {{amt}}*',
      body1:
        'While Fig isn’t able to offer you a loan right now, you may be eligible for a loan with our parent company, Fairstone.',
      body2:
        'Start with a no-obligation loan quote to find out how much money you qualify for, and what your loan payments might be.',
      button: {
        label: 'Get a loan quote',
        text: 'By clicking "Get a loan quote", I consent to Fig Financial Inc. sharing my personal information with Fairstone Financial Inc. (“FFI”) for the purpose of pre-populating the application for a FFI personal loan. FFI is also a wholly owned subsidiary of Fairstone Bank of Canada.',
      },
      terms: '* Terms and conditions apply. Visit apply.Fairstone.ca for details.',
    },
    Landing: {
      generic: {
        title: 'Get an instant decision on a personal loan offer with rates starting at {{apr}}!',
        subtitle:
          'Receive a guaranteed loan offer up to {{max_amt}} and complete your entire application online in minutes*.',
        figDescription:
          'Fig is an innovative digital lender on a mission to help simplify the borrowing process in Canada.',
        benefits: {
          title: 'Other benefits of borrowing with Fig:',
          convenienceTitle: 'Ultimate convenience',
          convenienceText: '{{pct}} digital experience',
          feesTitle: 'No hidden fees',
          feesText: 'Plus, no early repayment fees or charges!',
          seamlessTitle: 'Quick and seamless experience',
          seamlessText: 'Complete the entire application process in minutes!',
          trustTitle: 'Trusted Lender',
          trustText: `Backed by Fairstone Bank of Canada with almost 100 years of lending experience`,
        },
      },
      cobranded: {
        figDescription:
          '{{partner_name}} has partnered with Fig to offer unsecured personal loans. Fig is an innovative digital lender on a mission to help simplify the borrowing process in Canada.',
      },
      buttonLabel: 'Apply now',
      disclaimer:
        '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.',
      creditKarma: {
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.',
        disclaimer: `*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.`,
      },
      FigPromo: {
        title: 'Get an instant decision on a personal loan offer with rates starting at {{apr}}!',
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*. ',
        disclaimer:
          '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon. \n\n **Get a $50 Amazon eGift card with all new Fig personal loans. Offer valid for loans booked between September 19, 2024 and October 1, 2024 before 12:00 PM ET. Loan must be active and in good standing after 90 days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the eGift card. A limit of one (1) Amazon eGift card will be rewarded per eligible loan. Provided all offer conditions have been met, Amazon eGift card will be sent via email from Amazon on behalf of Fig to the email address of the primary account holder by January 31, 2024. Gift cards received in error including an error on the amount issued are subject to cancellation. Fig has the right to substitute gift card with another of equal value. The gift card is non-transferable and there is no cash alternative. This offer is available while supplies last, and may be withdrawn or changed at any time without notice.',
      },
      creditKarmaPromo: {
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.',
        disclaimer_part1:
          '<br/><br/>**You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.',
        disclaimer_gift_card:
          '<br/>*Get a $50 Amazon eGift card with all new Fig personal loans. Offer valid for loans booked between January 3, 2025  and January 31, 2025 before 12:00 PM ET. Loan must be active and in good standing after 90 days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the eGift card. A limit of one (1) Amazon eGift card will be rewarded per eligible loan. Provided all offer conditions have been met, Amazon eGift card will be sent via email from Amazon on behalf of Fig to the email address of the primary account holder by April 31, 2025. Gift cards received in error including an error on the amount issued are subject to cancellation. Fig has the right to substitute gift card with another of equal value. The gift card is non-transferable and there is no cash alternative. This offer is available while supplies last, and may be withdrawn or changed at any time without notice.',
      },
      theBrick: {
        disclaimer:
          '*You will only receive an offer if you meet our current credit standards. Any offer received is conditional to our standard review which may include, amongst others, your identity, the verification of information you’ve provided in your application and your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months. APRs range from {{min_apr}} to {{max_apr}} and will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.',
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.',
      },
      neo: {
        disclaimer:
          '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.',
      },
      perkopolis: {
        title: `Get a personal loan offer with rates starting at {{apr}}!`,
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.',
        disclaimer:
          '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.**Offer valid for loans booked through Perkopolis between May 1, 2024 and October 1, 2024 before 11:59PM ET. Loan must be active and in good standing after 90 days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the gift card. Customer must be a member of Perkopolis and the account holder of the Fig loan. A limit of one (1) gift card will be rewarded per eligible loan. Provided all offer conditions have been met, your gift card will be sent via email from Amazon on behalf of Fig to the email address of the account holder after 15 days of meeting the gift card conditions. E-gift cards received in error including an error on the amount issued are subject to cancellation. Fig has the right to substitute gift with another of equal value.',
      },
      LFLGroup: {
        title: `Get a personal loan offer with rates starting at {{apr}}!`,
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.',
        disclaimer:
          '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon. \n**Offer valid for loans booked by LFL employees. Loan must be active and in good standing after 90 days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the gift card. Customer must be an LFL employee at the time the loan is taken and the account holder of the Fig loan. A limit of one (1) gift card will be rewarded per eligible loan. Provided all offer conditions have been met, your gift card will be sent via email from Amazon on behalf of Fig to the email address of the account holder after 15 days of meeting the gift card conditions. The gift card cannot be combined with any other offer, is non-transferable and there is no cash alternative. This offer is available while supplies last, and may be withdrawn or changed at any time without notice. Gift cards received in error including an error on the amount issued are subject to cancellation. Gift card offer excludes residents of Northwest Territories, Nunavut and Yukon.',
      },
      fairstone: {
        subtitle:
          'Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.',
        disclaimer: `*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon. \n\n® Fairstone Financial Inc. and Fig Financial Inc. are wholly owned subsidiaries of Fairstone Bank of Canada.`,
        figDescription:
          'Fig is an innovative digital lender on a mission to help simplify the borrowing process in Canada.',
      },
      walmart: {
        rateDisclaimer: `Checking your rate won’t impact your credit score`,
        bannerSection: {
          title: `Rates as low as 8.99%* and up to $100 in Walmart gift cards** with a Fig personal loan.`,
        },
        offerSection: {
          title: `Walmart Special Offer`,
          content: `Borrow up to $35,000 with rates as low as 8.99%. Plus, earn a bonus $50 or $100 Walmart gift card with a Fig loan! Complete your entire application online, anytime!`,
        },
        taglineSection: {
          title: 'Save on personal loans with lower rates exclusively at Walmart',
          content: `Apply for a loan offer from {{min_amt}} to {{max_amt}} and complete your entire loan application online in just 10 minutes.`,
        },
        whySection: {
          title: 'Streamline your monthly payments with a personal loan',
          content: `Debt consolidation offers a way to better manage debt. If you’re someone who is paying off multiple outstanding balances, for example, two credit cards and a personal loan, it may be helpful to combine some or all of your debt into one single payment.`,
        },
        cardSection: {
          title: 'Why Fig?',
          first: {
            title: 'Ultimate convenience',
            content: '100% digital experience. Complete the entire application process in minutes!',
          },
          second: {
            title: 'No fees',
            content: 'No hidden fees. Plus, no early repayment fees or charges!',
          },
          third: {
            title: 'Trusted lender',
            content:
              'Backed by Fairstone Bank of Canada with almost 100 years of lending experience',
          },
        },
        howSection: {
          title: `How it works`,
          first: {
            title: 'See if you qualify for a loan in seconds',
            content: 'Check your loan offer without impacting your credit score*.',
          },
          second: {
            title: 'Complete the loan application in minutes',
            content:
              'No more agent calls or branch visits, everything can be done from the comfort of your home.',
          },
          third: {
            title: 'Get your funds within days',
            content: 'Plus, enjoy flexible repayment schedules and no early repayment fees.',
          },
        },
        transferSection: {
          left: {
            title: 'Transfer your debt and save',
            content:
              'A personal loan can help you consolidate high-interest credit cards and debt, which could mean more money in your pocket. Pay off just one loan balance with a fixed interest rate and monthly payments, plus pay your remaining balance anytime with no repayment fees.',
          },
          right: {
            footer: `<sup>1</sup>With $17,500 in credit card debt, paying your monthly minimum it would take 4 years and 4 months to eliminate your debt, and you’d pay $9,784 in interest charges.<br/><sup>2</sup>Or you could take out a $17,500 loan through Fig, eliminate the debt in 4 years, and spend only $6,681 - saving yourself $3,103!`,
          },
        },
        trustpilotSection: {
          reviewer: {
            text: `"Seamless, easy and professional. Very surprised at how easy this was. Super happy"`,
            name: `- SJ Hanlon`,
          },
        },
        faqSection: {
          title: `FAQ’s`,
          faqList: [
            {
              title: `What’s the difference between a personal loan and a line of credit?`,
              content: `Fig offers unsecured personal loans. When you apply for, and obtain, a Fig personal loan, you will receive the full loan amount up front and are required to follow a predetermined repayment schedule. This is different from a line of credit, where you are given a pre-set amount of money and you can borrow as much of that limit as you’d like, only paying interest on the amount you borrow.`,
            },
            {
              title: 'How do I apply for a Fig loan? ',
              content: `You can access the link to the Fig loan application from the "Apply now" button on this page. To check if you're eligible for a Fig loan, simply provide a few details. If you receive a loan offer, complete your application entirely online in just minutes, and receive your funds within days. It's that easy!`,
            },
            {
              title: 'When will I receive the funds?',
              content: `When you book your loan, we'll send you a confirmation email that includes the date you will receive your funds. Please contact us at support@fig.ca if you don't receive this email! Once your loan is booked and confirmed, you'll receive the funds in your connected bank account within days. The timeline is dependent on your bank.`,
            },
            {
              title: `Can I pay off my loan early?`,
              content: `Yes! You can manually submit additional payments, or pay your loan off in full outside of your regularly scheduled payments, at no additional cost right from your Fig account. As your balance decreases from additional payments, your regular payment amount will remain the same, though your loan term will be shortened and less interest will be paid overall.`,
            },
          ],
        },
        termsAndConditions: {
          title: `Legal`,
          figDisclaimer: `*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which include the following conditions: you do not currently have an active Fig loan, you have not been declined by Fig within the last 30 days, you are currently employed and you are not currently involved in a bankruptcy or insolvency proceeding including a consumer proposal or order payment of debts program. Any offer or approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay.  Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and interest rates between {{min_apr}} to {{max_apr}}. Loan amount and interest rate offered will depend on the assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.`,
          giftCardDisclaimer: `**Receive a $50 digital Walmart Gift Card for Fig personal loans between $2,000 - $10,000 and a $100 digital Walmart Gift Card for Fig personal loans between $10,001 - {{max_amt}}. Loan must be active and in good standing after ninety (90) days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the digital gift card. After the ninetieth (90th) day, provided all offer conditions have been met, a digital Walmart Gift Card will be sent via email from Fig within five (5) business days, to the email address associated with the loan. A limit of one (1) digital Walmart gift card will be rewarded per eligible loan. Fig may, in its sole and absolute discretion, modify or suspend this gift card offer in the event of tampering, unauthorized intervention, a virus, fraud or failure or any cause whatsoever related to the administration of this offer and which is outside of its reasonable control. Digital Walmart Gift Cards received in error including an error on the amount issued are subject to cancellation.`,
          disclaimer4:
            '<sup>3</sup>Example is for illustration purposes only. Figures are based on the assumption of a $17,500 starting credit card balance. Calculations are made using simple interest and assume that you make minimum monthly payments (3%) on your outstanding credit card balance. Actual interest cost and your potential savings depend on outstanding balances, the APR rate you qualify for, payment amounts, timing and other factors. ',
        },
      },
      figFinancialReferral: {
        title: `Welcome to Fig! {{referrer_name}} thinks you might like this Fig personal loan with rates starting at {{apr}}!`,
        subtitle: `Receive a loan offer from {{min_amt}} to {{max_amt}} and complete your entire application in minutes*.`,
        disclaimer: `*Based on average processing times and active application time. You will only receive an offer if you meet Fig’s current credit standards.  Any offer received is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APR) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon. \n**Offer open to Canadian residents (excluding Yukon, Northwest Territories, and Nunavut) at least age of majority in their province of residence. You must not currently have an active Fig loan. A Fig loan must be funded through an application initiated with the enclosed personalized invite link between August 26, 2024 and December 20, 2024 before 12:00 PM ET to qualify for a $50 Amazon eGift card. Loan must be active and in good standing after 90 days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the eGift card. Customer must be the primary account holder of a new Fig loan. Amazon eGift card will be sent via email from Amazon on behalf of Fig to the email address of the primary account holder within 120 days of funding the loan. eGift cards received in error including an error on the amount issued, and eGift cards issued based on loans that are determined by Fig to not be in good standing are subject to cancellation. This offer cannot be combined with any other offer, is non-transferable and there is no cash alternative. This offer may be withdrawn or changed at any time without notice.`,
      },
      cashContestLandingPromo: {
        disclaimer_title: 'Legal',
        disclaimer_part1:
          '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which include the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.',
        disclaimer_part2:
          '<br/><br>**You do not need to obtain a loan in order to qualify. If you do decide to obtain a loan, don’t take on more debt than what you need and don’t borrow more than you can afford. Read all of the terms and conditions of your loan offer and make sure you understand them before accepting.',
        disclaimer_part3:
          '<br/><br/><disclaimerCross/> 10 chances to win $1,000 Cdn. To enter the contest automatically, you must complete and submit the required information for a personal loan offer, obtain a loan offer and view the personal loan offer between noon, 12:00 pm ET on January 15, 2025 and noon, 12:00pm ET, on March 31, 2025 through Fig’s loan application. You may also enter in the contest by sending your full name and the correct answer to the following question: "12 - 3 = ?" to contests@fig.ca. Limit of one entry per person. To be eligible you must be a resident of Canada and be over the age of majority in your province of residence. Contest excludes residents of Northwest Territories, Nunavut and Yukon. See full <pdfLink>contest rules and regulations.</pdfLink>',
        cash_contest_1K_rules_pdf:
          'https://fig.ca/documents/1K-Cash-Contest-Rules-and-Regulations-EN.pdf',
        cash_contest_10K_rules_pdf:
          'https://fig.ca/documents/10K-Cash-Contest-Rules-and-Regulations-EN.pdf',
        creditKarmaPromo: {
          disclaimer_part1:
            '*You will only receive an offer and be approved for a loan if you meet Fig’s current credit standards which includes the following conditions: you do not currently have an active Fig loan and you have not been declined for a loan by Fig within the last 30 days. Any offer and approval you receive is conditional to Fig’s standard review which includes, amongst others, verification of your identity, of information obtained or provided as part of your application and of your ability to pay. Fig loans range from {{min_amt}} to {{max_amt}} with terms from 24 to 60 months and Annual Percentage Rates (APRs) between {{min_apr}} to {{max_apr}}. Loan amount and APR offered will depend on assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon.',
          disclaimer_part2:
            '<br/><br/>**You do not need to obtain a loan in order to qualify. If you do decide to obtain a loan, don’t take on more debt than what you need and don’t borrow more than you can afford. Read all of the terms and conditions of your loan offer and make sure you understand them before accepting.',
          disclaimer_part3:
            '<br/><br/>***Chance to win $12,000 Cdn. To enter the contest automatically, you must complete and submit the required information for a personal loan offer, be approved and view the personal loan offer between noon, 12:00 pm ET on November 1, 2024 and noon, 12:00pm ET, on January 3, 2025 through Fig’s loan application. You may also enter in the contest by sending the correct answer to the following question: "5 + 5 = ?" to contest2@fig.ca. Limit of one entry per person. To be eligible you must be a resident of Canada and be over the age of majority in your province of residence. Contest excludes residents of Northwest Territories, Nunavut and Yukon. See full <pdfLink> contest rules and regulations.<pdfLink>',
          disclaimer_part4:
            '<br/><br/><disclaimerCross/> Chance to win $10,000 Cdn. To enter the contest automatically, you must complete and submit the required information for a personal loan offer, be approved and view the personal loan offer between noon, 12:00 pm ET on October 7, 2024 and noon, 12:00pm ET, on December 16, 2024 through Fig’s loan application. You may also enter in the contest by sending the correct answer to the following question: "7 + 4 = ?" to contests@fig.ca. Limit of one entry per person. To be eligible you must be a resident of Canada and be over the age of majority in your province of residence. Contest excludes residents of Northwest Territories, Nunavut and Yukon.  See full <pdfLink>contest rules and regulations.</pdfLink> ',
          cash_contest_12K_rules_pdf:
            'https://fig.ca/documents/12K-CK-Contest-Rules-and-Regulations-EN.pdf ',
        },
        fairCashContest: {
          disclaimer_part4:
            '<br/><br/><sup>1</sup>Get a $50 Amazon eGift card with all new Fig personal loans. Offer valid for loans booked between November 4, 2024  and December 16, 2024 before 12:00 PM ET. Loan must be active and in good standing after 90 days (i.e. all loan payments must be successfully made for the first 90 days) in order to receive the eGift card. A limit of one (1) Amazon eGift card will be rewarded per eligible loan. Provided all offer conditions have been met, Amazon eGift card will be sent via email from Amazon on behalf of Fig to the email address of the primary account holder by March 31, 2025. Gift cards received in error including an error on the amount issued are subject to cancellation. Fig has the right to substitute gift card with another of equal value. The gift card is non-transferable and there is no cash alternative. This offer is available while supplies last, and may be withdrawn or changed at any time without notice.',
        },
      },
    },
  },
  HelpDesk: {
    hardCutCheckUrl:
      'https://support.fig.ca/en/support/solutions/articles/151000021102-credit-checks-explained',
    errorUrl:
      'https://support.fig.ca/support/solutions/articles/151000047886-i-m-seeing-an-error-when-applying-for-a-loan',
    declinedUrl:
      'https://support.fig.ca/support/solutions/articles/151000045510-why-was-my-application-declined-',
  },
  KYCRetryGeneric: {
    title:
      'Sorry, we couldn’t verify your identity due to an information mismatch. Please confirm your information from your uploaded document below and try again.',
    content:
      'There were some issues verifying your documents. Please note that health cards and foreign passports are not supported. Please try again or visit our <2/> to learn more.',
    verifyButtonLabel: `Verify ID again`,
    alt: 'Verification clipboard',
  },
  KYCPIIRetry: {
    title: 'Please confirm your information and verify your identity again.',
    content:
      'Sorry, we couldn’t verify your identity due to an information mismatch. Please confirm your information from your uploaded document below and try again.',
    button: 'Confirm and verify ID again',
    help: 'If you think there was a mistake, <2/>',
  },
  KYCImageQualityRetry: {
    title: 'Please verify your identity again',
    subtitle:
      'Sorry, we couldn’t verify your identity due to the image quality of your uploaded document.',
    tipsTitle: `Tips`,
    tipsLine1: `If you used a passport, capture only the bottom portion that contains information, or use a driver’s license if applicable`,
    tipsLine2: `Avoid shadows and obstructions (i.e. finger) on your image`,
    tipsLine3: `Choose a well lit environment and mobile device with a capable camera`,
    button: 'Verify ID again',
  },
  KYCLoading: {
    title:
      'We are verifying your information. This may take a few minutes. Thank you for your patience.',
    finalStepTitle: `This rarely happens, please hang on while we're finishing up verifying your identity. It may take 1-2 minutes. Thank you for your patience.`,
  },
  LandingPromoBanner: {
    applyNowLabel: 'Apply Now',
    applyNowGetOfferLabel: 'Get an Offer',
    applyNowLabelCashContest: 'Get a quote',
    CreditKarma: {
      title: 'Limited Time Offer!',
      content:
        'With this Credit Karma exclusive offer, you’ll receive a $75 Amazon Gift Card when you are approved for and receive a Fig personal loan between April 22 - May 10, 2024.*',
      altText: 'Credit Karma exclusive offer promotional banner',
    },
    FigPromo: {
      title: 'Limited time offer! ',
      content:
        'Receive a $50 Amazon eGift card with your next Fig loan. Offer valid between September 19 - October 1, 2024 before 12 PM ET**.  ',
      altText: 'Fig exclusive offer promotional banner',
    },
    creditKarmaPromo: {
      title: 'Limited time offer!',
      getQuoteLabel: 'Get a quote',
      content: `With this Credit Karma exclusive offer you’ll receive a $50 Amazon eGift card with your next Fig loan. Offer valid between January 3 - January 31, 2025 before 12 PM ET*.`,
      altText: 'Credit Karma exclusive offer promotional banner',
    },
    theBrick: {
      title: 'Limited Time Offer!',
      content: 'Receive a bonus $100 Brick gift card with your Fig loan!**',
      altText: 'The Brick exclusive offer promotional banner',
    },
    Perkopolis: {
      title: 'Special Offer!',
      content: 'As a Perkopolis member, get a $75 Amazon gift card with your Fig personal loan!**',
      altText: 'Perkopolis exclusive offer promotional banner',
    },
    LFLGroup: {
      title: 'Special offer!',
      content: `As a LFL employee, get a $100 Amazon gift card with your Fig personal loan!**`,
      altText: `LFL Group exclusive offer promotional banner`,
    },
    fairstone: {
      title: 'Reliable borrowing options from another Fairstone Bank company',
      content:
        'As a preferred  Fairstone customer, we wanted to let you know about a new loan option available via Fig, another Fairstone Bank company. Pay off your financing plan, tackle unexpected expenses and more with a personal loan from Fig.',
      altText: 'Fairstone exclusive offer promotional banner',
    },
    FigFinancialReferral: {
      title: 'Limited Time Bonus Offer!',
      content1: `Eligible customers who fund a Fig personal loan between August 26, 2024 and December 20, 2024 before 12:00 PM ET qualify for a $50 Amazon eGift card when they use a referral link**.`,
      content2: `When a loan is funded in your name based on an application initiated with a referral link, we will notify the referrer that they have earned a $50 eGift card after your loan is funded. We will not provide your name, but please be aware that the referrer may learn that you funded a loan, for example, if they only provided their referral link to one person (i.e., you) or under similar circumstances.`,
      altText: `Fig Financial referral promotional banner`,
    },
    cashContestLandingPromo: {
      title: '10 chances to win $1,000!',
      content:
        'Receive an approved* personal loan offer** and be entered to win 1 of 10 $1,000 cash prizes<disclaimerCross/>. Contest closes at 12:00pm ET on March 31, 2025.',
      altText: 'Fig Financial Cash Contest promotional banner',
      fairCashContest: {
        title: 'Bonus limited time offer:',
        content:
          'Receive a $50 Amazon eGift card with your next Fig loan. Offer valid between November 4 - December 16, 2024 before 12 PM ET<sup>1</sup>',
      },
    },
  },
}

export default translation

import { SignIn, useClerk, useAuth } from '@clerk/clerk-react'
import { Box, GlobalStyles } from '@mui/material'

import { logoImgSvg } from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import theme from 'src/themes'
import PortalTheme from 'src/themes/portal'
import Header from 'src/portal/layout/header/Header'
import Footer from 'src/portal/layout/footer/Footer'

export default function SignComponent() {
  const { signOut } = useClerk()
  const { isSignedIn } = useAuth()

  return (
    <Box sx={{ backgroundColor: PortalTheme.color.white, height: '100%', width: '100%' }}>
      {/* style the freshchat widget for portal layout */}
      <GlobalStyles
        styles={{
          width: '100%',
          [PortalTheme.breakpoints.up('md')]: {
            '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
          },
          [PortalTheme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
          },
        }}
      />
      <Header signOut={() => signOut({ redirectUrl: '/portal' })} isSignedIn={isSignedIn} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 164px)',
        }}
      >
        <SignIn
          path={`/${PortalRoutesEnum.PORTAL_BASE}`}
          appearance={{
            layout: {
              logoImageUrl: logoImgSvg,
            },
            variables: {
              colorPrimary: theme.color.brand1,
            },
          }}
        />
      </Box>

      <Footer />
    </Box>
  )
}

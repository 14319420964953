import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { useState } from 'react'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import usePortalContext from 'src/portal/hooks/usePortalContext'

interface ExtraAmountInputProps {
  setExtraAmount: (value: number) => void
}

export const ExtraAmountInput = ({ setExtraAmount }: ExtraAmountInputProps) => {
  const { paymentDetailData } = usePortalContext()
  const [error, setError] = useState<string | null>(null)
  const { currencyFormat } = useLocalizedFormatters()
  const maxAmount = paymentDetailData?.[0]?.pay_off_amount ?? 0
  const [inputValue, setInputValue] = useState<string>('') // Separate raw input

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    // Allow only numbers and up to one decimal point
    if (/^(\d+(\.\d{0,2})?)?$/.test(rawValue)) {
      setInputValue(rawValue) // Update raw input for display
      const parsedValue = parseFloat(rawValue) || 0
      if (parsedValue > maxAmount) {
        setError(`The amount cannot exceed ${currencyFormat(maxAmount)}`)
      } else {
        setError(null)
      }
      setExtraAmount(parsedValue) // Update numeric value
    }
  }

  return (
    <Box my={'24px'}>
      <Typography variant="label">How much would you like to pay?</Typography>
      <TextField
        value={inputValue} // Display raw input value
        onChange={handleChange}
        fullWidth
        slotProps={{
          input: {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          },
        }}
        error={!!error}
        helperText={error}
      />
    </Box>
  )
}

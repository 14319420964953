import { Link, Grid2, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Trans, useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { IconStyle } from 'src/components/PaymentProtection/styles'
import { eligibilityIcon1, eligibilityIcon2 } from 'src/images'
import { figColors } from 'src/themes'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import PortalTheme from 'src/themes/portal'
import { lockIcon } from 'src/images'

const GridContainer = styled(Grid2)(({ theme }) => ({
  borderColor: theme.color.grey3,
  backgroundColor: theme.color.white,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  width: '100%',
  padding: '48px',
  [theme.breakpoints.up('md')]: {
    padding: '64px',
  },
}))

const GridDivider = styled(Grid2)(({ theme }) => ({
  border: 'none',
  borderTop: `1px solid ${theme.color.grey3}`,
  marginTop: '32px',
  marginBottom: '32px',
}))

export default function CreditRenewalEligibility() {
  const { loanDetailData } = usePortalContext()
  const { t } = useTranslation()

  return (
    <>
      <Grid2 container flexDirection="column">
        <Grid2 pb="24px">
          <Typography variant="h2" color={PortalTheme.color.black} textAlign="center">
            {t('Portal.CreditRenewal.title')}
          </Typography>
          <Typography variant="body2" color={PortalTheme.color.grey7} pt="24px" textAlign="center">
            <img
              src={lockIcon}
              alt="Lock Icon"
              style={{ display: 'inline', verticalAlign: 'middle', marginRight: '8px' }}
              width={'20px'}
              height={'20px'}
            />
            {t('Portal.CreditRenewal.subtitle')}
          </Typography>
        </Grid2>
      </Grid2>

      <GridContainer container>
        <Grid2 size={12}>
          <IconStyle src={eligibilityIcon1} alt={t('KYCSuccess.alt')} />
          <Typography variant="h1" mt={'8px'} mb={'24px'}>
            {t('CreditRenewals.Eligibility.heading1')}
          </Typography>
          <Typography variant="body2" textAlign="left" color={figColors.black}>
            {t('CreditRenewals.Eligibility.description1')}
          </Typography>
        </Grid2>

        <GridDivider size={12} />

        <Grid2 size={12} marginTop={0}>
          <IconStyle src={eligibilityIcon2} alt={t('KYCSuccess.alt')} width={'84px'} />
          <Typography variant="h1" mt={'8px'} mb={'24px'}>
            {t('CreditRenewals.Eligibility.heading2')}
          </Typography>
          <Typography variant="body2" textAlign="left" color={figColors.black}>
            <Trans
              i18nKey="CreditRenewals.Eligibility.description2"
              components={{
                learnMoreLink: (
                  <Link
                    href={t('CreditRenewals.LoanDetails.tooltipURL')}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={t('CreditRenewals.LoanDetails.tooltipURL')}
                  />
                ),
              }}
            />
          </Typography>
        </Grid2>

        <GridDivider size={12} />

        <Grid2 size={12} marginTop={0} textAlign={'center'}>
          <PrimaryCTA
            href={loanDetailData?.credit_renewal_url || ''}
            target="_blank"
            buttonText={t('CreditRenewals.Eligibility.buttonLabel')}
            aria-label={t('CreditRenewals.Eligibility.buttonLabel')}
          />
          <Typography variant="body4">{t('CreditRenewals.Eligibility.notice')}</Typography>
        </Grid2>
      </GridContainer>
    </>
  )
}

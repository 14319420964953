import { Grid2, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import PortalTheme from 'src/themes/portal'

interface PayNowButtonProps {
  onClickHandler: () => void
  buttonText?: string
}
/**
 * PayNowButton component renders a button that triggers an onclick function
 * It adjusts its styling based on the screen size (mobile or desktop).
 * @param {function} onClickHandler - The function to call when the button is clicked.
 * @param {string} buttonText - The text to display on the button. (defaults to Pay now)
 *
 * @returns {JSX.Element} The rendered PayNowButton component.
 */
export const PayNowButton = ({ onClickHandler, buttonText }: PayNowButtonProps) => {
  const isMobile = useMediaQuery(PortalTheme.breakpoints.down('sm'))
  const { t } = useTranslation()

  return (
    <Grid2 onClick={onClickHandler} sx={!isMobile ? { margin: '0 auto', textAlign: 'center' } : {}}>
      <PrimaryCTA
        buttonText={buttonText || t('Portal.Home.label.summary.payNow')}
        style={{ maxHeight: '36px', marginTop: '8px', marginBottom: '36px' }}
        sx={!isMobile ? { px: '24px', py: '8px', maxWidth: '300px' } : {}}
      />
    </Grid2>
  )
}

import { Box, Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { RepaymentScheduleResponseSchema } from 'src/portal/api/api.schemas'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { UpcomingPaymentsContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import PortalTheme from 'src/themes/portal'

interface UpcomingPaymentsRowProps {
  transaction: RepaymentScheduleResponseSchema
}
export const UpcomingPaymentsRow = ({ transaction }: UpcomingPaymentsRowProps) => {
  const {
    balance_amount,
    due_date,
    interest_due,
    payment_amount,
    principal_due,
    nsf_fees_amount,
    payment_protection_premium_amount,
    payment_protection_premium_tax_amount,
  } = transaction
  const { t } = useTranslation()
  const { currencyFormat, dateFormatCustom } = useLocalizedFormatters()

  return (
    <Box mb="8px">
      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey7}>
            {dateFormatCustom(new Date(due_date), t('Portal.common.dateFormat'))}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body2" color={PortalTheme.color.grey10}>
            {t('Portal.Home.label.upcomingPayments.remainingBalance')}
          </Typography>
        </Grid2>
        <Grid2 alignItems="flex-start">
          <Typography
            variant="body1"
            color={PortalTheme.color.grey10}
            style={{ display: 'inline' }}
          >
            {currencyFormat(payment_amount)}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.paymentDetails')}
            content={
              <UpcomingPaymentsContent
                creditCharges={interest_due}
                nsfFees={nsf_fees_amount ?? null}
                paymentProtectionPremium={payment_protection_premium_amount ?? undefined}
                paymentProtectionPremiumTax={payment_protection_premium_tax_amount ?? undefined}
                principal={principal_due}
                totalAmount={payment_amount}
              />
            }
          />
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2 size={8}>
          <Typography variant="body2" color={PortalTheme.color.grey10}>
            {currencyFormat(balance_amount)}
          </Typography>
        </Grid2>
        <StretchDivider />
      </SpacedRow>
    </Box>
  )
}

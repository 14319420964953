import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { helpCenterIcon, menuDocumentsIcon } from 'src/images'
import { handleNavigation, MenuButtonItem } from 'src/portal/layout/menu/common'
import { MenuDivider } from 'src/portal/layout/menu/MenuDivider'
import { MobileMenuItem } from 'src/portal/layout/menu/mobile/MobileMenuItem'
import { fadeIn, fadeOut } from 'src/portal/styles'
import PortalTheme from 'src/themes/portal'

interface MobileMenuModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  optionalItemList: MenuButtonItem[]
}

export const MobileMenuModal = ({ isOpen, setIsOpen, optionalItemList }: MobileMenuModalProps) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true)
    } else {
      setTimeout(() => setIsAnimating(false), 300)
    }
  }, [isOpen])

  if (!isOpen && !isAnimating) {
    return null
  }

  const itemList: MenuButtonItem[] = [
    {
      icon: helpCenterIcon,
      label: t('Portal.Menu.label.helpCenter'),
      iconAlt: t('Portal.Menu.alt.helpCenterIcon'),
      externalLink: t('Portal.Menu.links.helpCenter'),
    },
    {
      icon: menuDocumentsIcon,
      label: t('Portal.Menu.label.terms'),
      iconAlt: t('Portal.Menu.alt.termsIcon'),
      externalLink: t('Portal.Menu.links.terms'),
    },
  ]

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '100px',
        height: 'calc(100vh - 176px)', // note: 176 px is the height of the mobile header + footer
        left: 0,
        right: 0,
        backgroundColor: PortalTheme.color.white,
        boxShadow: 'none',
        padding: '16px',
        borderTop: `1px solid ${PortalTheme.color.grey3}`,
        zIndex: 10,
        animation: `${isOpen ? fadeIn : fadeOut} 0.3s ease-out`,
      }}
    >
      {optionalItemList
        .filter(item => !item.isHidden)
        .map((optionalItem, index) => (
          <MobileMenuItem
            key={index}
            icon={optionalItem.icon}
            label={optionalItem.label}
            iconAlt={optionalItem.iconAlt}
            route={optionalItem.route}
            onClickHandler={() => {
              setIsOpen(false) // close more menu modal
              handleNavigation(optionalItem, navigate)
            }}
          />
        ))}

      {/* show the divider if some optional menu items should be shown */}
      {optionalItemList.some(item => !item.isHidden) && <MenuDivider />}

      {itemList.map((item, index) => (
        <MobileMenuItem
          key={index}
          icon={item.icon}
          iconAlt={item.iconAlt}
          label={item.label}
          onClickHandler={() => {
            setIsOpen(false) // close more menu modal
            handleNavigation(item, navigate)
          }}
        />
      ))}
    </Box>
  )
}

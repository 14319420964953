import { styled } from '@mui/material/styles'
import { Typography, Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { connectionSuccessImg } from 'src/images'
import { DivRoot } from 'src/portal/pages/reschedule/styles'
import useModalContext from 'src/portal/hooks/useModalContext'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'

const CustomImage = styled('img')({
  width: '80px',
  height: '80px',
})

const CustomTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  textAlign: 'center',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
}))

const GridImage = styled(Grid2)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '32px',
})

const CustomImageGrid = styled(GridImage)({
  marginBottom: '32px',
})

export default function PaymentScheduleChangeSuccess() {
  const { t } = useTranslation()

  return (
    <DivRoot>
      <Grid2 container>
        <Grid2 size={12}>
          <CustomImageGrid size={12}>
            <CustomImage src={connectionSuccessImg} alt={t('Portal.Reschedule.Success.title')} />
          </CustomImageGrid>

          <Grid2 size={12}>
            <CustomTitle data-testid="errorTitle">
              {t('Portal.Reschedule.Success.title')}
            </CustomTitle>
          </Grid2>
        </Grid2>
      </Grid2>
    </DivRoot>
  )
}

export const ChangePaymentSuccessModal = () => {
  const { closeAllModals } = useModalContext()
  const { t } = useTranslation()

  return (
    <ActionModal
      content={<PaymentScheduleChangeSuccess />}
      closeButtonHandler={() => closeAllModals()}
      nextButtonHandler={() => closeAllModals()}
      buttonText={t('Portal.Reschedule.Success.buttonConfirm')}
    />
  )
}

import { useContext } from 'react'

import { ModalContext } from 'src/portal/contexts/modal'

/**
 * Custom hook to access the ModalContext.
 *
 * This hook must be used within a component wrapped by a PortalProvider.
 * If the hook is used outside of a PortalProvider, it will throw an error.
 *
 * Usage: const { openModal, closeModal, RenderModal } = useModalContext()
 *  - openModal: Function to open a modal by name.
 * - closeModal: Function to close the most recently opened modal.
 * - RenderModal: React component to render the currently active modal.
 * @returns The current context value for ModalContext.
 * @throws Will throw an error if used outside of a PortalProvider.
 */
export default function useModalContext() {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModalContext must be used within a PortalProvider')
  }
  return context
}

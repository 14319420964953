import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import { ReactNode } from 'react'

import { zIndexAboveActionModal } from 'src/portal/styles'
import PortalTheme from 'src/themes/portal'

interface DesktopTooltipProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  title: string
  content: ReactNode
}

export const DesktopTooltip: React.FC<DesktopTooltipProps> = ({
  isOpen,
  anchorEl,
  onClose,
  title,
  content,
}) => (
  <Popover
    open={isOpen}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    sx={{
      mt: 1.5,
      '& .MuiPaper-root': {
        boxShadow: 'none', // Removes the default Paper shadow in MUI
      },
      zIndex: zIndexAboveActionModal,
    }}
  >
    <Box
      sx={{
        padding: '48px 24px 36px',
        maxWidth: '450px',
        backgroundColor: PortalTheme.color.white,
        border: `1px solid ${PortalTheme.color.grey3}`,
        borderRadius: '8px',
        position: 'relative',
      }}
      data-testid="desktop-tooltip"
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h1"
        color={PortalTheme.color.black}
        sx={{ fontWeight: 'bold', mb: '32px', mt: '24px' }}
        textAlign="center"
      >
        {title}
      </Typography>
      <Box>{content}</Box>
    </Box>
  </Popover>
)

export enum StatusIconEnum {
  ACTIVE = 'active',
  ALERT = 'alert',
  INACTIVE = 'inactive',
}

export interface ActionModalProps {
  icon?: string
  iconAlt?: string
  title?: string
  description?: string | JSX.Element
  content: JSX.Element
  buttonText?: string
  backButtonHandler?: () => void // only nested modals have a back button
  closeButtonHandler?: () => void
  nextButtonHandler?: () => void
  nextButtonEnabled?: boolean
}
